import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
} from '@mui/material';
import { useContext } from 'react';
import { AppContext } from '../../../../hooks/context';
import { TimeTrackingApi } from '../../../../api';
import snackbarUtils from '../../../../utils/snackbar/snackbar-utils';
import { TimeTrackingEntry } from '../../../../types/time-tracking-entry';

interface RejectTimeTrackingEntryDialogProps {
  open: boolean;
  onClose: () => void;
  timeTrackingEntries: TimeTrackingEntry[] | undefined;
  singleMode: boolean;
  onReject: () => void;
}

const RejectTimeTrackingEntryDialog: React.FC<
  RejectTimeTrackingEntryDialogProps
> = ({ open, onClose, timeTrackingEntries, singleMode, onReject }) => {
  const context = useContext(AppContext);
  const [rejectionReason, setRejectionReason] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleReject = async () => {
    if (!timeTrackingEntries || timeTrackingEntries.length === 0) return;

    if (!rejectionReason || rejectionReason.trim() === '') {
      snackbarUtils.error(
        context.i18n['time-tracking-rejection-reason-required'] ||
          'Please provide a rejection reason',
      );
      return;
    }

    setIsSubmitting(true);
    try {
      const entryIds = timeTrackingEntries.map((entry) => entry.id);

      await TimeTrackingApi.entryMultiStatusChange(context.authToken, {
        ids: entryIds,
        status: 'REJECTED',
        rejectionReason: rejectionReason,
      });

      snackbarUtils.success(
        singleMode
          ? context.i18n['time-tracking-rejected-single'] ||
              'Entry has been rejected'
          : context.i18n['time-tracking-rejected-multiple'] ||
              'Entries have been rejected',
      );

      onReject();
      onClose();
    } catch (error) {
      console.error('Error rejecting time tracking entry:', error);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Clear rejection reason when dialog closes
  React.useEffect(() => {
    if (!open) {
      setRejectionReason('');
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {singleMode
          ? context.i18n['reject-time-tracking-entry'] || 'Reject Time Entry'
          : context.i18n['reject-time-tracking-entries'] ||
            'Reject Time Entries'}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 2, mt: 1 }}>
          <Typography variant="body1" gutterBottom>
            {singleMode
              ? context.i18n['reject-time-tracking-entry-message'] ||
                'Please provide a reason for rejecting this time entry.'
              : context.i18n['reject-time-tracking-entries-message'] ||
                'Please provide a reason for rejecting these time entries.'}
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            id="rejection-reason"
            label={context.i18n['rejection-reason'] || 'Rejection Reason'}
            type="text"
            fullWidth
            multiline
            rows={4}
            value={rejectionReason}
            onChange={(e) => setRejectionReason(e.target.value)}
            required
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={isSubmitting}>
          {context.i18n.cancel || 'Cancel'}
        </Button>
        <Button
          onClick={handleReject}
          color="error"
          variant="contained"
          disabled={isSubmitting || !rejectionReason.trim()}
        >
          {context.i18n.reject || 'Reject'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RejectTimeTrackingEntryDialog;
