import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../hooks/context';
import { setDocumentTitle, loadTitle, parseDateString } from '../../utils';
import { MenuWrapper } from '../../components/core';
import { useQuery } from 'react-query';
import { UserApi } from '../../api';
import { Box, Card, CircularProgress, Grid, Typography } from '@mui/material';

const OrganizationMessages: FC = () => {
  const context = useContext(AppContext);
  const [messages, setMessages] = useState<any[]>([]);

  useEffect(() => {
    setDocumentTitle(context.i18n.orgMessages);
    return () => loadTitle();
  }, []);

  const { isLoading } = useQuery({
    queryKey: ['listOrgMessages'],
    queryFn: () => UserApi.getOrgMessages(context.authToken),
    enabled: context.authToken !== undefined,
    onSuccess: (data) => setMessages(data.data),
  });

  return (
    <MenuWrapper
      title={context.i18n.orgMessages}
      selected="org-messages"
      search
      addClient
    >
      <Grid container spacing={2} sx={{ px: 5, py: 3, flexWrap: 'wrap' }}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            {messages && messages.length > 0 ? (
              messages.map((m) => (
                <Grid item xs={12} sm={6} md={4} key={m.id}>
                  <Card sx={{ my: 1, width: '100%' }}>
                    <Box sx={{ p: 1 }}>
                      <Typography sx={{ fontSize: '0.8rem' }}>
                        {parseDateString(m.creationDate)}
                      </Typography>
                      <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                        {m.subject}
                      </Typography>
                      <Typography>{m.message}</Typography>
                    </Box>
                  </Card>
                </Grid>
              ))
            ) : (
              <Typography>{context.i18n.noMessages}</Typography>
            )}
          </>
        )}
      </Grid>
    </MenuWrapper>
  );
};

export default OrganizationMessages;
