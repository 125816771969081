import { FC, useContext } from 'react';
import { ClientTable } from '../../../components/client';
import { MobileNavBar, MobileThemeProvider } from '../../../components/mobile';
import { AppContext } from '../../../hooks/context';
import { Box } from '@mui/material';

const MobileClientList: FC = () => {
  const context = useContext(AppContext);

  return (
    <MobileThemeProvider>
      <Box>
        <MobileNavBar
          title={context.i18n.clients}
          logoutBtn
          timeTrackingBtn={context.features.includes('TIME_TRACKING')}
        />
        <ClientTable archive={false} mobile />
      </Box>
    </MobileThemeProvider>
  );
};

export default MobileClientList;
