import { useContext, useEffect, useState } from 'react';
import TimeTrackingEntryDialog from '../time-tracking-entry-dialog/time-tracking-entry-dialog';
import { GridSlotsComponentsProps } from '@mui/x-data-grid-pro';
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { AppContext } from '../../../../hooks/context';
import moment, { Moment } from 'moment';
import CountUpTimer from '../../../core/count-up-timer/count-up-timer';
import { TimeTrackingApi } from '../../../../api';
import { DateRange, PickersShortcutsItem } from '@mui/x-date-pickers-pro';
import { TimeTrackingCategory } from '../../../../types/time-tracking-category';
import { TimeTrackingEntry } from '../../../../types/time-tracking-entry';
import { TimeTrackingStatus } from '../../../../types/time-tracking-status';
import snackbarUtils from '../../../../utils/snackbar/snackbar-utils';
import { useQueryClient } from 'react-query';

declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides {
    showDayTimer?: boolean;
    showSubTimer?: boolean;
    currentTimeTrackingEntry: TimeTrackingEntry;
    timeTrackingCategories: TimeTrackingCategory[];
    clientFamilyList: any[];
    userList?: any[];
    clientId?: string;
    familyId?: string;
    filterDateRange?: (dateRange: DateRange<Moment>) => void;
    filterStatus?: (status: TimeTrackingStatus[]) => void;
    filterUser?: (userId?: string) => void;
    onTimerStart?: (timeTrackingEntry: TimeTrackingEntry) => void;
    onTimerStop?: (timeTrackingEntry: TimeTrackingEntry) => void;
    selectedTimeTrackingEntryIds?: Array<string | undefined>;
    clearSelection?: (forceRefetch: boolean) => void;
  }
}

export const TimerWithStartStopButton = (props: {
  timer: undefined | Moment;
  onStart: (timer: Moment) => void;
  onStop: (timer: Moment) => void;
  startDisabled?: boolean;
}) => {
  const [timer, setTimer] = useState<undefined | Moment>(props.timer);
  useEffect(() => {
    setTimer(props.timer);
  }, [props.timer]);

  const context = useContext(AppContext);
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={'center'}
      sx={{ width: '100%' }}
    >
      <Typography sx={{ mr: 1, fontWeight: 'bold' }}>
        <CountUpTimer startTime={timer?.unix() ?? 0} />
      </Typography>
      {timer ? (
        <Button
          variant={'outlined'}
          color={'error'}
          fullWidth
          onClick={() => {
            setTimer(undefined);
            props.onStop(moment());
          }}
        >
          <Typography>{context.i18n.timeTrackingButtonStop}</Typography>
        </Button>
      ) : (
        <Button
          variant={'contained'}
          color={'success'}
          disabled={props.startDisabled}
          fullWidth
          onClick={() => {
            const curr = moment();
            setTimer(curr);
            props.onStart(curr);
          }}
        >
          <Typography>{context.i18n.timeTrackingButtonStart}</Typography>
        </Button>
      )}
    </Box>
  );
};

const TimeTrackingListToolbar = (
  props: NonNullable<GridSlotsComponentsProps['toolbar']>,
) => {
  const context = useContext(AppContext);
  const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false);

  const shortcutsItems: PickersShortcutsItem<DateRange<Moment>>[] = [
    {
      label: context.i18n.timeTrackingThisWeek,
      getValue: () => {
        const today = moment();
        return [today.clone().startOf('week'), today.clone().endOf('week')];
      },
    },
    {
      label: context.i18n.timeTrackingLastWeek,
      getValue: () => {
        const today = moment();
        const prevWeek = today.subtract(7, 'day');
        return [
          prevWeek.clone().startOf('week'),
          prevWeek.clone().endOf('week'),
        ];
      },
    },
    {
      label: context.i18n.timeTrackingThisMonth,
      getValue: () => {
        const today = moment();
        return [today.clone().startOf('month'), today.clone().endOf('month')];
      },
    },
    {
      label: context.i18n.timeTrackingLastMonth,
      getValue: () => {
        const today = moment();
        const endOfLastMonth = today.subtract(1, 'month');

        return [
          endOfLastMonth.clone().startOf('month'),
          endOfLastMonth.clone().endOf('month'),
        ];
      },
    },
  ];

  const [timeTrackingCategorySelected, setTimeTrackingCategorySelected] =
    useState<TimeTrackingCategory | null>(null);

  const [clientFamilySelected, setClientFamilySelected] = useState<any | null>(
    null,
  );

  const [note, setNote] = useState<string>('');

  const [timeTrackingCategories, setTimeTrackingCategories] = useState<
    TimeTrackingCategory[]
  >([]);
  const [currentTimeTrackingEntry, setCurrentTimeTrackingEntry] =
    useState<TimeTrackingEntry | null>(null);
  const [clientFamilyList, setClientFamilyList] = useState<any[]>([]);

  const [disableStart, setDisableStart] = useState(true);
  const [currentMonth, setCurrentMonth] = useState<Moment>(moment());

  const queryClient = useQueryClient();

  useEffect(() => {
    if (timeTrackingCategorySelected == undefined) setDisableStart(true);
    else if (
      timeTrackingCategorySelected.isClientOrFamilyNeeded &&
      clientFamilySelected == null
    )
      setDisableStart(true);
    else setDisableStart(false);
  }, [timeTrackingCategorySelected, clientFamilySelected]);

  useEffect(() => {
    if (props.currentTimeTrackingEntry) {
      setCurrentTimeTrackingEntry(props.currentTimeTrackingEntry);
      if (props.currentTimeTrackingEntry.category)
        setTimeTrackingCategorySelected(
          props.currentTimeTrackingEntry.category,
        );
      if (
        props.currentTimeTrackingEntry.clientId ||
        props.currentTimeTrackingEntry.familyId
      ) {
        const item = clientFamilyList.find(
          (f) =>
            f.id === props.currentTimeTrackingEntry?.clientId ||
            f.id === props.currentTimeTrackingEntry?.familyId,
        );
        if (item) setClientFamilySelected(item);
      }
      if (props.currentTimeTrackingEntry.note)
        setNote(props.currentTimeTrackingEntry.note);
    }
  }, [props.currentTimeTrackingEntry, clientFamilyList]);

  useEffect(() => {
    if (props.timeTrackingCategories)
      setTimeTrackingCategories(props.timeTrackingCategories);
  }, [props.timeTrackingCategories]);

  useEffect(() => {
    if (props.clientFamilyList) setClientFamilyList(props.clientFamilyList);
  }, [props.clientFamilyList]);

  const resetClientFamilySelect = () => {
    if (props.clientId)
      setClientFamilySelected(
        clientFamilyList.find(
          (ele) => ele.id == props.clientId && !ele.isFamily,
        ),
      );
    if (props.familyId) {
      setClientFamilySelected(
        clientFamilyList.find(
          (ele) => ele.id == props.familyId && ele.isFamily,
        ),
      );
    }
  };

  useEffect(() => {
    resetClientFamilySelect();
  }, [props.clientId, props.familyId, clientFamilyList]);

  return (
    <Box>
      <TimeTrackingEntryDialog
        open={createDialogOpen}
        timeTrackingCategories={timeTrackingCategories}
        clientFamilyList={clientFamilyList}
        onClose={() => {
          setCreateDialogOpen(false);
          if (props.onTimerStart) props.onTimerStart({} as any);
        }}
      />
      <Grid container sx={{ m: 1 }} spacing={2}>
        {props.showSubTimer && (
          <Grid item sm={2}>
            <Autocomplete
              disabled={currentTimeTrackingEntry != undefined}
              sx={{ my: 1 }}
              size="small"
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={context.i18n.timeTrackingCategory}
                />
              )}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => {
                if (value.id) return option.id == value.id;
                else return false;
              }}
              options={timeTrackingCategories}
              groupBy={(option: any) => option.parent.name}
              value={timeTrackingCategorySelected}
              onChange={(e, val) => {
                setTimeTrackingCategorySelected(null);
                if (val) setTimeTrackingCategorySelected(val);
              }}
            />

            {timeTrackingCategorySelected?.isClientOrFamilyNeeded && (
              <Autocomplete
                disabled={currentTimeTrackingEntry != undefined}
                size="small"
                fullWidth
                sx={{ my: 1 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      context.i18n.timeTrackingCategoryIsClientOrFamilyNeeded
                    }
                  />
                )}
                groupBy={(option: any) => {
                  if (option.isFamily) return context.i18n.family;
                  return context.i18n.client;
                }}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => {
                  if (value.id) return option.id == value.id;
                  else return false;
                }}
                onChange={(e, val) => {
                  setClientFamilySelected(null);
                  if (val) setClientFamilySelected(val);
                }}
                value={clientFamilySelected}
                options={clientFamilyList.sort((x) => (x.isFamily ? -1 : 1))}
              />
            )}
            <TextField
              disabled={currentTimeTrackingEntry != undefined}
              value={note}
              onChange={(e) => setNote(e.target.value)}
              size="small"
              sx={{ my: 1 }}
              placeholder={context.i18n.timeTrackingNote}
              fullWidth
            />
            <TimerWithStartStopButton
              startDisabled={disableStart}
              timer={
                currentTimeTrackingEntry?.startRealTime
                  ? moment(currentTimeTrackingEntry.startRealTime)
                  : undefined
              }
              onStart={async (timer) => {
                if (timeTrackingCategorySelected) {
                  const sendData = Object.assign(
                    {
                      start: timer.utc(),
                      categoryId: timeTrackingCategorySelected.id,
                    },
                    timeTrackingCategorySelected.isClientOrFamilyNeeded &&
                      (clientFamilySelected && clientFamilySelected.isFamily
                        ? { familyId: clientFamilySelected.id }
                        : { clientId: clientFamilySelected.id }),
                    note && {
                      note,
                    },
                  );

                  const res = await TimeTrackingApi.createEntry(
                    context.authToken,
                    sendData,
                  );
                  if (res.data) {
                    setCurrentTimeTrackingEntry(res.data);
                    if (props.onTimerStart) props.onTimerStart(res.data);
                  }
                }
              }}
              onStop={async (timer) => {
                if (currentTimeTrackingEntry && currentTimeTrackingEntry.id) {
                  const sendData = {
                    end: timer.utc(),
                    // Entry source is managed on backend
                  };
                  const res = await TimeTrackingApi.editEntry(
                    context.authToken,
                    currentTimeTrackingEntry.id,
                    sendData,
                  );
                  if (res) {
                    setCurrentTimeTrackingEntry(null);
                    setTimeTrackingCategorySelected(null);
                    resetClientFamilySelect();
                    setNote('');
                    if (props.onTimerStop) props.onTimerStop(res.data);
                  }
                }
              }}
            />
          </Grid>
        )}
      </Grid>
      <Grid container sx={{ m: 1 }} spacing={2}>
        {props.filterDateRange && (
          <Grid item sm={12} md={4}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Button
                variant="outlined"
                onClick={() => {
                  const previousMonth = currentMonth
                    .clone()
                    .subtract(1, 'month');
                  setCurrentMonth(previousMonth);
                  props.filterDateRange &&
                    props.filterDateRange([
                      previousMonth.clone().startOf('month'),
                      previousMonth.clone().endOf('month'),
                    ]);
                }}
              >
                {context.i18n.previousMonth || 'Previous Month'}
              </Button>

              <Typography variant="h6" id="current-month-display">
                {currentMonth.format('MMMM YYYY')}
              </Typography>

              <Button
                variant="outlined"
                onClick={() => {
                  const nextMonth = currentMonth.clone().add(1, 'month');

                  // Only allow going to next month if it's not in the future
                  if (
                    nextMonth.month() <= moment().month() ||
                    nextMonth.year() < moment().year()
                  ) {
                    setCurrentMonth(nextMonth);
                    props.filterDateRange &&
                      props.filterDateRange([
                        nextMonth.clone().startOf('month'),
                        nextMonth.clone().endOf('month'),
                      ]);
                  }
                }}
                disabled={
                  currentMonth.month() === moment().month() &&
                  currentMonth.year() === moment().year()
                }
              >
                {context.i18n.nextMonth || 'Next Month'}
              </Button>
            </Box>
          </Grid>
        )}
        {/* Status filter removed from user view */}
        {props.filterUser && props.userList && (
          <Grid item sm={12} md={2}>
            <Autocomplete
              fullWidth
              renderInput={(params) => (
                <TextField {...params} label={context.i18n.timeTrackingUser} />
              )}
              isOptionEqualToValue={(option, value) => option.id == value.id}
              getOptionLabel={(option) => option.name}
              options={props.userList}
              onChange={(e, val) => {
                if (props.filterUser) {
                  if (val) props.filterUser(val.id);
                  else props.filterUser(undefined);
                }
              }}
            />
          </Grid>
        )}
        {props.selectedTimeTrackingEntryIds &&
          props.selectedTimeTrackingEntryIds.length > 0 && (
            <Grid item sm={12} md={2}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={async () => {
                  try {
                    const ids = props.selectedTimeTrackingEntryIds;
                    if (ids) {
                      const sendData = {
                        ids,
                        status: TimeTrackingStatus.SUBMITTED,
                      };
                      const res = await TimeTrackingApi.entryMultiStatusChange(
                        context.authToken,
                        sendData,
                      );
                      if (res.message) {
                        snackbarUtils.success(res.message);
                        if (props.clearSelection) props.clearSelection(true);
                      }
                    }
                  } catch (e) {
                    console.error(e);
                    snackbarUtils.error(context.i18n.errorTryAgainLater);
                  }
                }}
              >
                <Typography>{context.i18n.timeTrackingSubmit}</Typography>
              </Button>
            </Grid>
          )}
      </Grid>
      {/* Removed GridToolbar component that was causing the error */}
    </Box>
  );
};

export default TimeTrackingListToolbar;
