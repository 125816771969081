import { FamilyDataType } from '@casecare/types';
import { faFloppyDisk, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import FamilyApi from '../../../api/family/family';
import { AppContext } from '../../../hooks/context';
import { queryClient } from '../../../utils';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { UserSelect } from '../../client';

interface AddFamilyDialogProps {
  open: boolean;
  onClose: () => void;
}

const AddFamilyDialog: FC<AddFamilyDialogProps> = (props) => {
  const context = useContext(AppContext);

  const [open, setOpen] = useState(props.open);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [street, setStreet] = useState('');
  const [streetError, setStreetError] = useState(false);
  const [zipcode, setZipcode] = useState('');
  const [zipcodeError, setZipcodeError] = useState(false);
  const [city, setCity] = useState('');
  const [cityError, setCityError] = useState(false);
  const [supervisorIds, setSupervisorIds] = useState([context.userId]);
  const [supervisorError, setSupervisorError] = useState(false);

  const familyFields = [
    {
      name: context.i18n.street,
      required: true,
      type: FamilyDataType.STREET,
      value: street,
      setValue: (value: string) => setStreet(value),
      error: streetError,
      disableError: () => setStreetError(false),
    },
    {
      name: context.i18n.zipcode,
      required: true,
      type: FamilyDataType.ZIPCODE,
      value: zipcode,
      setValue: (value: string) => setZipcode(value),
      error: zipcodeError,
      disableError: () => setZipcodeError(false),
    },
    {
      name: context.i18n.city,
      required: true,
      type: FamilyDataType.CITY,
      value: city,
      setValue: (value: string) => setCity(value),
      error: cityError,
      disableError: () => setCityError(false),
    },
  ];

  useEffect(() => {
    setOpen(props.open);
    setStreet('');
    setZipcode('');
    setCity('');
    setName('');
  }, [props.open]);

  const onSave = async () => {
    setNameError(name === '');
    setStreetError(street === '');
    setZipcodeError(zipcode === '');
    setCityError(city === '');
    setSupervisorError(supervisorIds.length === 0);

    if (
      name !== '' &&
      street !== '' &&
      zipcode !== '' &&
      city !== '' &&
      supervisorIds.length !== 0
    ) {
      const data: Record<FamilyDataType, any> = {
        CITY: city,
        STREET: street,
        ZIPCODE: zipcode,
        SUPERVISOR: supervisorIds,
        FAMILY_NUMBER: '',
        STREET_NUMBER: '',
      };

      const body = {
        name,
        data,
      };

      try {
        await FamilyApi.addFamily(context.authToken, body);
        queryClient.invalidateQueries('families'), props.onClose();
      } catch (e) {
        console.error(e);
        snackbarUtils.error(context.i18n.errorTryAgainLater);
      }
    }
  };

  return (
    <Dialog open={open} onClose={props.onClose}>
      <DialogTitle>{context.i18n.addFamily}</DialogTitle>
      <DialogContent>
        <Stack spacing={2} mt={2} width="22rem">
          <TextField
            color="secondary"
            label={context.i18n.name}
            size="medium"
            type={'text'}
            value={name}
            data-id="family-name-input"
            error={nameError}
            onChange={(e) => {
              setNameError(false);
              setName(e.currentTarget.value);
            }}
          />
          {!context.simpleModeActive && (
            <UserSelect
              title={context.i18n.supervisor}
              error={supervisorError}
              multiple
              userIds={supervisorIds}
              setId={(id) => {
                setSupervisorIds(id);
                setSupervisorError(false);
              }}
            />
          )}
          {familyFields.map((field) => (
            <TextField
              key={field.type}
              color="secondary"
              label={field.name}
              size="medium"
              type={'text'}
              value={field.value}
              error={field.error}
              onChange={(e) => {
                field.disableError();
                field.setValue(e.currentTarget.value);
              }}
            />
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.saveBtnTooltip}>
          <IconButton onClick={onSave} disableRipple color="primary">
            <FontAwesomeIcon icon={faFloppyDisk} />
          </IconButton>
        </Tooltip>
        <Tooltip title={context.i18n.close}>
          <IconButton onClick={props.onClose} disableRipple>
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default AddFamilyDialog;
