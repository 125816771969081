import { faTrash, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../hooks/context';
import { TimeTrackingEntry } from '../../../../types/time-tracking-entry';
import snackbarUtils from '../../../../utils/snackbar/snackbar-utils';
import { TimeTrackingApi } from '../../../../api';

interface DeleteTimeTrackingEntryDialogProps {
  open: boolean;
  onClose: () => void;
  timeTrackingEntry: TimeTrackingEntry | undefined;
}

const DeleteTimeTrackingEntryDialog: FC<DeleteTimeTrackingEntryDialogProps> = (
  props,
) => {
  const context = useContext(AppContext);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const onDelete = async () => {
    try {
      if (props.timeTrackingEntry && props.timeTrackingEntry.id) {
        const res = await TimeTrackingApi.deleteEntry(
          context.authToken,
          props.timeTrackingEntry.id,
        );
        if (res.message) {
          snackbarUtils.success(res.message);
          props.onClose();
        }
      }
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  return (
    <Dialog open={open} onClose={props.onClose}>
      <DialogTitle>
        {context.i18n.timeTrackingEntryDelete || 'Delete Time Entry'}
      </DialogTitle>
      <DialogContent>
        <Typography>{context.i18n.areYouSure}</Typography>
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.delete}>
          <IconButton onClick={onDelete} color="error">
            <FontAwesomeIcon icon={faTrash} />
          </IconButton>
        </Tooltip>
        <Tooltip title={context.i18n.cancelBtnTooltip}>
          <IconButton onClick={props.onClose} color="primary">
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteTimeTrackingEntryDialog;
