import { FC, useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { AppContext } from '../../../../hooks/context';
import { parseDurationNumber } from '../../../../utils/date/date';
import { parse } from 'path';

interface TimeTrackingListFooterProps {
  duration: number;
}

const TimeTrackingListFooter: FC<TimeTrackingListFooterProps> = ({
  duration = 0,
}) => {
  const context = useContext(AppContext);

  // Format for display
  const durationText = parseDurationNumber(duration, false);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        py: 1,
      }}
    >
      <Typography variant="subtitle1" fontWeight="bold">
        {context.i18n.timeTrackingTotalDuration || 'Total Duration'}:
      </Typography>
      <Typography variant="subtitle1" sx={{ ml: 1 }}>
        {durationText}
      </Typography>
    </Box>
  );
};

export default TimeTrackingListFooter;
