import { axiosInstance } from '../../utils';
import { Feature } from '../abo/abo';

export interface Organization {
  id: string;
  name: string;
  subdomain: string;
  createdAt: string;
  features: Feature[];
}

export interface FeatureUpdateRequest {
  id?: string;
  featureType:
    | 'FAMILY'
    | 'RISK'
    | 'GENOGRAMM'
    | 'AVATAR'
    | 'BILLING'
    | 'TIME_TRACKING'
    | 'ECO_MAP'
    | 'SIMPLE_MODE';
  status: 'ACTIVE' | 'INACTIVE' | 'PENDING';
  expirationDate?: string;
  billingProvider: 'MANUAL';
}

export interface SubscriptionUpdateRequest {
  id?: string;
  status: 'ACTIVE' | 'INACTIVE' | 'PAST_DUE' | 'CANCELED';
  expirationDate?: string;
  provider?: 'MANUAL' | 'STRIPE';
}

/**
 * API for CaseCare global admin operations
 * These endpoints are only accessible to CaseCare staff members
 */
export default class GlobalAdminApi {
  /**
   * List all organizations in the system
   */
  static listOrganizations(token: string | undefined): Promise<Organization[]> {
    return axiosInstance
      .get('global-admin/organizations', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        console.log('API Response:', res.data);
        return res.data.data || res.data.result || [];
      });
  }

  /**
   * Get details for a specific organization
   */
  static getOrganization(
    token: string | undefined,
    organizationId: string,
  ): Promise<Organization> {
    return axiosInstance
      .get(`global-admin/organizations/${organizationId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data.result;
      });
  }

  /**
   * Update or create a feature for an organization
   */
  static updateOrganizationFeature(
    token: string | undefined,
    organizationId: string,
    feature: FeatureUpdateRequest,
  ): Promise<Feature> {
    return axiosInstance
      .post(`global-admin/organizations/${organizationId}/features`, feature, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data.result;
      });
  }

  /**
   * Update or create multiple features for an organization at once
   */
  static updateMultipleFeatures(
    token: string | undefined,
    organizationId: string,
    features: FeatureUpdateRequest[],
  ): Promise<Feature[]> {
    return axiosInstance
      .post(
        `global-admin/organizations/${organizationId}/features`,
        {
          isMultiFeature: true,
          features: features,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((res) => {
        return res.data.result;
      });
  }

  /**
   * Delete a feature from an organization
   */
  static deleteOrganizationFeature(
    token: string | undefined,
    organizationId: string,
    featureId: string,
  ): Promise<void> {
    return axiosInstance
      .delete(
        `global-admin/organizations/${organizationId}/features/${featureId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then(() => {
        return;
      });
  }

  /**
   * Update organization subscription
   */
  static updateOrganizationSubscription(
    token: string | undefined,
    organizationId: string,
    subscription: SubscriptionUpdateRequest,
  ): Promise<any> {
    return axiosInstance
      .post(
        `global-admin/organizations/${organizationId}/subscription`,
        subscription,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((res) => {
        return res.data.result;
      });
  }

  /**
   * Delete organization subscription
   */
  static deleteOrganizationSubscription(
    token: string | undefined,
    organizationId: string,
    subscriptionId: string,
  ): Promise<any> {
    return axiosInstance
      .delete(
        `global-admin/organizations/${organizationId}/subscription/${subscriptionId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((res) => {
        return res.data.result;
      });
  }
}
