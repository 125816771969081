import {
  ClientDataType,
  FormFieldTextType,
  FormFieldType,
} from '@casecare/types';
import { Divider, Grid, Paper, Typography } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../hooks/context';
import { TextSize } from '../../../../types/form-text-props';
import FormText from '../../forms/form-elements/form-text/form-text';
import {
  CategoryElement,
  ClientDataFieldType,
  FormDateType,
} from '../../../../types';
import { ClientApi } from '../../../../api';
import { queryClient } from '../../../../utils';
import snackbarUtils from '../../../../utils/snackbar/snackbar-utils';
import FormCheckBox from '../../forms/form-elements/form-checkbox/form-checkbox';
import { FormDate, FormGroup } from '../..';
import ClientDataField from '../client-data-field/client-data-field';

interface ClientDataExtendedProps {
  saved: boolean;
  clientData: Record<ClientDataType, any>;
  care?: boolean;
}

const ClientDataExtended: FC<ClientDataExtendedProps> = (props) => {
  const context = useContext(AppContext);

  const [clientData, setClientData] = useState<
    Partial<Record<ClientDataType, any>>
  >(props.clientData);
  const [fieldChanges, setFieldChanges] = useState<
    Partial<Record<ClientDataType, boolean>>
  >({});
  const [fieldValues, setFieldValues] = useState<
    Partial<Record<ClientDataType, any>>
  >({});
  const [saved, setSaved] = useState(false);
  const [clientBilling, setClientBilling] = useState(clientData.CLIENT_BILLING);

  const clientDataBillingFields: ClientDataFieldType[] = [
    {
      required: false,
      type: ClientDataType.CLIENT_BILLING,
      fieldType: FormFieldType.CHECKBOX,
      name: context.i18n.useClientDataTitle,
      selected: clientBilling,
      onClick: () => setClientBilling(!clientBilling),
      onCancle: () => setClientBilling(props.clientData.CLIENT_BILLING),
      options: [
        {
          label: context.i18n.useClientData,
          key: 'clientBilling',
        },
      ],
    },
  ];

  // const clientDataHelpFields: ClientDataFieldType[] = [
  //   {
  //     name: context.i18n.startOfHelp,
  //     type: ClientDataType.START_OF_HELP,
  //     fieldType: FormFieldType.DATE,
  //   },
  //   {
  //     name: context.i18n.applicantOfHelpForm,
  //     type: ClientDataType.APPLICANT_OF_HELP_FORM,
  //   },
  //   {
  //     name: context.i18n.contactDataRelatives,
  //     type: ClientDataType.CONTACT_DATA_RELATIVES,
  //     fieldType: FormFieldType.LIST_MULTI_DATA,
  //     groupValues: [
  //       {
  //         id: ClientDataType.CONTACT_RELATIVES_STREET,
  //         seq: 0,
  //         required: false,
  //         type: FormFieldType.TEXT,
  //         value: clientData[ClientDataType.CONTACT_RELATIVES_STREET],
  //         label: context.i18n.street,
  //         isListTitle: true,
  //       },
  //       {
  //         id: ClientDataType.CONTACT_RELATIVES_ZIPCODE,
  //         seq: 1,
  //         required: false,
  //         type: FormFieldType.TEXT,
  //         value: clientData[ClientDataType.CONTACT_RELATIVES_ZIPCODE],
  //         label: context.i18n.zipcode,
  //       },
  //       {
  //         id: ClientDataType.CONTACT_RELATIVES_CITY,
  //         seq: 2,
  //         required: false,
  //         type: FormFieldType.TEXT,
  //         value: clientData[ClientDataType.CONTACT_RELATIVES_CITY],
  //         label: context.i18n.city,
  //         isListSubtitle: true,
  //       },
  //       {
  //         id: ClientDataType.CONTACT_RELATIVES_PHONE,
  //         seq: 3,
  //         required: false,
  //         type: FormFieldType.TEXT,
  //         value: clientData[ClientDataType.CONTACT_RELATIVES_PHONE],
  //         label: context.i18n.phone,
  //       },
  //       {
  //         id: ClientDataType.CONTACT_RELATIVES_EMAIL,
  //         seq: 4,
  //         required: false,
  //         type: FormFieldType.TEXT,
  //         value: clientData[ClientDataType.CONTACT_RELATIVES_EMAIL],
  //         label: context.i18n.email,
  //       },
  //     ],
  //   },
  //   {
  //     name: context.i18n.contactDataApplicant,
  //     type: ClientDataType.CONTACT_OF_APPLICANT,
  //     fieldType: FormFieldType.FORM_GROUP,
  //     groupValues: [
  //       {
  //         id: ClientDataType.CONTACT_APPLICANT_STREET,
  //         seq: 0,
  //         required: false,
  //         type: FormFieldType.TEXT,
  //         value: clientData[ClientDataType.CONTACT_APPLICANT_STREET],
  //         label: context.i18n.street,
  //       },
  //       {
  //         id: ClientDataType.CONTACT_APPLICANT_ZIPCODE,
  //         seq: 1,
  //         required: false,
  //         type: FormFieldType.TEXT,
  //         value: clientData[ClientDataType.CONTACT_APPLICANT_ZIPCODE],
  //         label: context.i18n.zipcode,
  //       },
  //       {
  //         id: ClientDataType.CONTACT_APPLICANT_CITY,
  //         seq: 2,
  //         required: false,
  //         type: FormFieldType.TEXT,
  //         value: clientData[ClientDataType.CONTACT_APPLICANT_CITY],
  //         label: context.i18n.city,
  //       },
  //       {
  //         id: ClientDataType.CONTACT_APPLICANT_PHONE,
  //         seq: 3,
  //         required: false,
  //         type: FormFieldType.TEXT,
  //         value: clientData[ClientDataType.CONTACT_APPLICANT_PHONE],
  //         label: context.i18n.street,
  //       },
  //       {
  //         id: ClientDataType.CONTACT_APPLICANT_EMAIL,
  //         seq: 4,
  //         required: false,
  //         type: FormFieldType.TEXT,
  //         value: clientData[ClientDataType.CONTACT_APPLICANT_EMAIL],
  //         label: context.i18n.email,
  //       },
  //     ],
  //   },
  //   {
  //     name: context.i18n.currentMeasures,
  //     type: ClientDataType.CURRENT_MEASURES,
  //     fieldType: FormFieldType.CHECKBOX,
  //     options: [
  //       {
  //         label: context.i18n.psychotherapy,
  //         key: 'psychotherapy',
  //       },
  //       {
  //         label: context.i18n.ergotherapy,
  //         key: 'ergotherapy',
  //       },
  //       {
  //         label: context.i18n.speechTherapy,
  //         key: 'speechTherapy',
  //       },
  //       {
  //         label: context.i18n.psychologicalInvestigations,
  //         key: 'psychologicalInvestigations',
  //       },
  //       {
  //         label: context.i18n.psychiatricInvestigations,
  //         key: 'psychiatricInvestigations',
  //       },
  //       {
  //         label: context.i18n.inpatientStays,
  //         key: 'inpatientStays',
  //       },
  //       {
  //         label: context.i18n.educationalAdvice,
  //         key: 'educationalAdvice',
  //       },
  //       {
  //         label: context.i18n.supportChildWelfare,
  //         key: 'supportChildWelfare',
  //       },
  //       {
  //         label: context.i18n.miscellaneous,
  //         key: 'miscellaneous',
  //       },
  //     ],
  //   },
  //   {
  //     name: context.i18n.previouslyUsedHelp,
  //     type: ClientDataType.PREVIOUSLY_USED_HELP,
  //     fieldType: FormFieldType.CHECKBOX,
  //     options: [
  //       {
  //         label: context.i18n.psychotherapy,
  //         key: 'psychotherapy',
  //       },
  //       {
  //         label: context.i18n.ergotherapy,
  //         key: 'ergotherapy',
  //       },
  //       {
  //         label: context.i18n.speechTherapy,
  //         key: 'speechTherapy',
  //       },
  //       {
  //         label: context.i18n.psychologicalInvestigations,
  //         key: 'psychologicalInvestigations',
  //       },
  //       {
  //         label: context.i18n.psychiatricInvestigations,
  //         key: 'psychiatricInvestigations',
  //       },
  //       {
  //         label: context.i18n.inpatientStays,
  //         key: 'inpatientStays',
  //       },
  //       {
  //         label: context.i18n.educationalAdvice,
  //         key: 'educationalAdvice',
  //       },
  //       {
  //         label: context.i18n.supportChildWelfare,
  //         key: 'supportChildWelfare',
  //       },
  //       {
  //         label: context.i18n.miscellaneous,
  //         key: 'miscellaneous',
  //       },
  //     ],
  //   },
  //   {
  //     name: context.i18n.formOfHelp,
  //     type: ClientDataType.FORM_OF_HELP,
  //     fieldType: FormFieldType.CHECKBOX,
  //     options: [
  //       {
  //         label: context.i18n.familyIntesiveCare,
  //         key: 'familyIntesiveCare',
  //       },
  //       {
  //         label: context.i18n.outpatientCare,
  //         key: 'outpatientCare',
  //       },
  //       {
  //         label: context.i18n.familyCouncil,
  //         key: 'familyCouncil',
  //       },
  //       {
  //         label: context.i18n.psychotherapy,
  //         key: 'psychotherapy',
  //       },
  //       {
  //         label: context.i18n.psychosocialCounseling,
  //         key: 'psychosocialCounseling',
  //       },
  //       {
  //         label: context.i18n.coaching,
  //         key: 'coaching',
  //       },
  //       {
  //         label: context.i18n.miscellaneous,
  //         key: 'miscellaneous',
  //       },
  //     ],
  //   },
  // ];

  const educationClientDataFields: ClientDataFieldType[] = [
    {
      name: context.i18n.highestDegree,
      type: ClientDataType.HIGHEST_DEGREE,
    },
  ];

  const custodyClientDataFields: ClientDataFieldType[] = [
    {
      name: context.i18n.clientCustody,
      type: ClientDataType.CUSTODY,
    },
    {
      name: context.i18n.contactLawRegulation,
      type: ClientDataType.CONTACT_LAW_REGULATION,
    },
    {
      name: context.i18n.contactLawCourtRegulated,
      type: ClientDataType.CONTACT_LAW_COURT_REGULATED,
      fieldType: FormFieldType.RADIO,
      options: [
        {
          label: context.i18n.yes,
          key: 'yes',
        },
        {
          label: context.i18n.no,
          key: 'no',
        },
      ],
    },
    {
      name: context.i18n.contactLawRegulationNotes,
      type: ClientDataType.CONTACT_LAW_REGULATION_NOTES,
    },
  ];

  const professionClientDataFields: ClientDataFieldType[] = [
    {
      name: context.i18n.professionAndEmployer,
      type: ClientDataType.PROFESSION_AND_EMPLOYER,
    },
    {
      name: context.i18n.professionalPosition,
      type: ClientDataType.PROFESSIONAL_POSITION,
    },
    {
      name: context.i18n.scopeWorkWeek,
      type: ClientDataType.SCOPE_WORK_WEEK,
    },
  ];

  const lifeSituationClientDataFields: ClientDataFieldType[] = [
    {
      name: context.i18n.lifeSituationClient,
      type: ClientDataType.CLIENT_LIFE_SITUATION,
      fieldType: FormFieldType.FORM_GROUP,
      groupValues: [
        {
          id: ClientDataType.CLIENT_LIVES_ALONE,
          seq: 0,
          required: false,
          type: FormFieldType.RADIO,
          fieldOptions: [
            {
              label: context.i18n.yes,
              key: 'yes',
            },
            {
              label: context.i18n.no,
              key: 'no',
            },
          ],
          value: clientData[ClientDataType.CLIENT_LIVES_ALONE],
          label: context.i18n.clientLivesAlone,
        },
        {
          id: ClientDataType.CLIENT_FUTHER_HELP_NEEDED,
          seq: 1,
          required: false,
          type: FormFieldType.RADIO,
          fieldOptions: [
            {
              label: context.i18n.yes,
              key: 'yes',
            },
            {
              label: context.i18n.no,
              key: 'no',
            },
          ],
          value: clientData[ClientDataType.CLIENT_FUTHER_HELP_NEEDED],
          label: context.i18n.clientFurtherHelpNeeded,
        },
        {
          id: ClientDataType.CLIENT_FUTHER_HELP_NEEDED_WICH,
          seq: 2,
          required: false,
          type: FormFieldType.TEXT,
          value: clientData[ClientDataType.CLIENT_FUTHER_HELP_NEEDED_WICH],
          label: context.i18n.clientFurtherHelpNeededWich,
        },
      ],
    },
    {
      name: context.i18n.lifeSituationParents,
      type: ClientDataType.PARENT_LIFE_SITUATION,
      fieldType: FormFieldType.FORM_GROUP,
      groupValues: [
        {
          id: ClientDataType.PARENT_CONTACT_CLIENT,
          seq: 0,
          required: false,
          type: FormFieldType.RADIO,
          fieldOptions: [
            {
              label: context.i18n.yes,
              key: 'yes',
            },
            {
              label: context.i18n.no,
              key: 'no',
            },
          ],
          value: clientData[ClientDataType.PARENT_CONTACT_CLIENT],
          label: context.i18n.contactParent,
        },
        {
          id: ClientDataType.PARENT_CONTACT_CLIENT_COUNT,
          seq: 1,
          required: false,
          type: FormFieldType.TEXT,
          value: clientData[ClientDataType.PARENT_CONTACT_CLIENT_COUNT],
          label: context.i18n.ifYesHowOften,
        },
        {
          id: ClientDataType.PARENT_CONTACT_PARENT,
          seq: 2,
          required: false,
          type: FormFieldType.RADIO,
          fieldOptions: [
            {
              label: context.i18n.yes,
              key: 'yes',
            },
            {
              label: context.i18n.no,
              key: 'no',
            },
          ],
          value: clientData[ClientDataType.PARENT_CONTACT_PARENT],
          label: context.i18n.parentContactParent,
        },
        {
          id: ClientDataType.PARENT_CONTACT_PARENT_COUNT,
          seq: 3,
          required: false,
          type: FormFieldType.TEXT,
          value: clientData[ClientDataType.PARENT_CONTACT_PARENT_COUNT],
          label: context.i18n.ifYesHowOften,
        },
        {
          id: ClientDataType.PARENT_COMMUNICATION,
          seq: 4,
          required: false,
          type: FormFieldType.TEXT,
          value: clientData[ClientDataType.PARENT_COMMUNICATION],
          label: context.i18n.communicationBetweenParents,
        },
        {
          id: ClientDataType.PARENT_MOTHER_SATISFACTION,
          seq: 5,
          required: false,
          type: FormFieldType.RADIO,
          fieldOptions: [
            {
              label: '1',
              key: '1',
            },
            {
              label: '2',
              key: '2',
            },
            {
              label: '3',
              key: '3',
            },
            {
              label: '4',
              key: '4',
            },
            {
              label: '5',
              key: '5',
            },
          ],
          value: clientData[ClientDataType.PARENT_MOTHER_SATISFACTION],
          label: context.i18n.satisfactionSituationMother,
        },
        {
          id: ClientDataType.PARENT_FATHER_SATISFACTION,
          seq: 6,
          required: false,
          type: FormFieldType.RADIO,
          fieldOptions: [
            {
              label: '1',
              key: '1',
            },
            {
              label: '2',
              key: '2',
            },
            {
              label: '3',
              key: '3',
            },
            {
              label: '4',
              key: '4',
            },
            {
              label: '5',
              key: '5',
            },
          ],
          value: clientData[ClientDataType.PARENT_FATHER_SATISFACTION],
          label: context.i18n.satisfactionSituationFather,
        },
        {
          id: ClientDataType.PARENT_CLIENT_SATISFACTION,
          seq: 7,
          required: false,
          type: FormFieldType.RADIO,
          fieldOptions: [
            {
              label: '1',
              key: '1',
            },
            {
              label: '2',
              key: '2',
            },
            {
              label: '3',
              key: '3',
            },
            {
              label: '4',
              key: '4',
            },
            {
              label: '5',
              key: '5',
            },
          ],
          value: clientData[ClientDataType.PARENT_CLIENT_SATISFACTION],
          label: context.i18n.satisfactionSituationClient,
        },
      ],
    },
  ];

  useEffect(() => {
    setClientData(props.clientData);
  }, [props.clientData]);

  useEffect(() => {
    setSaved(props.saved);
  }, [props.saved]);

  useEffect(() => {
    if (
      saved &&
      Object.entries(fieldValues).length === Object.entries(fieldChanges).length
    ) {
      saveFields(fieldValues);
    }
  }, [saved, fieldValues]);

  const saveFields = async (fields: Partial<Record<ClientDataType, any>>) => {
    const res = await ClientApi.updateClientData(
      context.authToken,
      context.clientId,
      {
        data: fields,
      },
    );

    if (res.message) snackbarUtils.success(res.message);

    setFieldValues({});
    setFieldChanges({});
    queryClient.invalidateQueries('clientData');
    queryClient.invalidateQueries('clients');
  };

  const onSaveField = async (type: ClientDataType, value: any) => {
    if (fieldChanges[type]) {
      const data: any = fieldValues;
      if (type === ClientDataType.CLIENT_BILLING) {
        data[type] = clientBilling;
      } else {
        data[type] = value;
      }
      if (!saved) {
        try {
          onChange(type, false);
          setFieldValues({});
          const res = await ClientApi.updateClientData(
            context.authToken,
            context.clientId,
            {
              data,
            },
          );

          if (res.message) snackbarUtils.success(res.message);

          if (clientData) {
            clientData[type] = value;
            setClientData(clientData);
          }
        } catch (e) {
          snackbarUtils.error(context.i18n.error);
        }
      }
      setFieldValues(data);
    }
  };

  const onChange = async (
    type: ClientDataType | undefined,
    changes: boolean,
  ) => {
    if (clientData) {
      let unsavedChanges = changes;
      const tmpFieldChanges: any = fieldChanges;

      if (type) {
        tmpFieldChanges[type] = changes;
      }

      for (const key in fieldChanges) {
        if (fieldChanges[key as keyof typeof ClientDataType]) {
          unsavedChanges = true;
        }
      }
      setFieldChanges(tmpFieldChanges);
      context.setUnsavedChanges(unsavedChanges);
    }
  };

  return (
    <Paper
      sx={{
        m: 3,
        mt: 0,
        flexGrow: 1,
        borderRadius: 0,
      }}
    >
      <Grid container>
        {!props.care && !context.simpleModeActive && (
          <>
            <Grid item xs={12} m={1}>
              <Typography
                variant="h5"
                color="primary"
                fontWeight="bold"
                textTransform="uppercase"
              >
                {context.i18n.billing}
              </Typography>
              <Divider />
            </Grid>
            {clientDataBillingFields.map((field) => (
              <Grid key={field.type} item xs={12} md={6}>
                <ClientDataField
                  {...field}
                  onSaveFields={saveFields}
                  clientData={clientData}
                  onChange={onChange}
                  onSaveField={onSaveField}
                  saved={saved}
                />
              </Grid>
            ))}
          </>
        )}

        {/* {!props.care && (
          <>
            <Grid item xs={12} m={1}>
              <Typography
                variant="h5"
                color="primary"
                fontWeight="bold"
                textTransform="uppercase"
              >
                {context.i18n.careForm}
              </Typography>
              <Divider />
            </Grid>
            {clientDataHelpFields.map((field) => (
              <Grid key={field.type} item xs={12} md={6}>
                <ClientDataField
                  {...field}
                  onSaveFields={saveFields}
                  clientData={clientData}
                  onChange={onChange}
                  onSaveField={onSaveField}
                  saved={saved}
                />
              </Grid>
            ))}
          </>
        )} */}
        {!props.care && (
          <>
            <Grid item xs={12} m={1}>
              <Typography
                variant="h5"
                color="primary"
                fontWeight="bold"
                textTransform="uppercase"
              >
                {context.i18n.education}
              </Typography>
              <Divider />
            </Grid>
            {educationClientDataFields.map((field) => (
              <Grid key={field.type} item xs={12} md={6}>
                <ClientDataField
                  {...field}
                  onSaveFields={saveFields}
                  clientData={clientData}
                  onChange={onChange}
                  onSaveField={onSaveField}
                  saved={saved}
                />
              </Grid>
            ))}
          </>
        )}
        {!props.care && (
          <>
            <Grid item xs={12} m={1}>
              <Typography
                variant="h5"
                color="primary"
                fontWeight="bold"
                textTransform="uppercase"
              >
                {context.i18n.profession}
              </Typography>
              <Divider />
            </Grid>
            {professionClientDataFields.map((field) => (
              <Grid key={field.type} item xs={12} md={6}>
                <ClientDataField
                  {...field}
                  onSaveFields={saveFields}
                  clientData={clientData}
                  onChange={onChange}
                  onSaveField={onSaveField}
                  saved={saved}
                />
              </Grid>
            ))}
          </>
        )}
        {props.care && (
          <>
            <Grid item xs={12} m={1}>
              <Typography
                variant="h5"
                color="primary"
                fontWeight="bold"
                textTransform="uppercase"
              >
                {context.i18n.lifeSituation}
              </Typography>
              <Divider />
            </Grid>
            {lifeSituationClientDataFields.map((field) => (
              <Grid key={field.type} item xs={12} md={6}>
                <ClientDataField
                  {...field}
                  onSaveFields={saveFields}
                  clientData={clientData}
                  onChange={onChange}
                  onSaveField={onSaveField}
                  saved={saved}
                />
              </Grid>
            ))}
          </>
        )}
        {props.care && (
          <>
            <Grid item xs={12} m={1}>
              <Typography
                variant="h5"
                color="primary"
                fontWeight="bold"
                textTransform="uppercase"
              >
                {context.i18n.clientCare}
              </Typography>
              <Divider />
            </Grid>
            {custodyClientDataFields.map((field) => (
              <Grid key={field.type} item xs={12} md={6}>
                <ClientDataField
                  {...field}
                  onSaveFields={saveFields}
                  clientData={clientData}
                  onChange={onChange}
                  onSaveField={onSaveField}
                  saved={saved}
                />
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </Paper>
  );
};

export default ClientDataExtended;
