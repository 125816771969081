import {
  Box,
  Typography,
  Paper,
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Switch,
  FormControlLabel,
  Divider,
  Checkbox,
} from '@mui/material';
import { FC, useContext, useState, useEffect } from 'react';
import { AppContext } from '../../../hooks/context';
import { MenuWrapper } from '../../../components/core';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { Feature } from '../../../api/abo/abo';

// This is a placeholder. We need to create this API
import { GlobalAdminApi } from '../../../api';

interface Organization {
  id: string;
  name: string;
  subdomain: string;
  createdAt: string;
  features: Feature[];
  subscription?: {
    id: string;
    status: 'ACTIVE' | 'INACTIVE' | 'PAST_DUE' | 'CANCELED';
    expirationDate?: string;
  };
}

interface EditingFeature {
  orgId: string;
  featureId?: string;
  featureType:
    | 'FAMILY'
    | 'RISK'
    | 'GENOGRAMM'
    | 'AVATAR'
    | 'BILLING'
    | 'TIME_TRACKING'
    | 'ECO_MAP'
    | 'SIMPLE_MODE';
  status: 'ACTIVE' | 'INACTIVE' | 'PENDING';
  expirationDate?: string;
}

interface EditingSubscription {
  orgId: string;
  subscriptionId?: string;
  status: 'ACTIVE' | 'INACTIVE' | 'PAST_DUE' | 'CANCELED';
  expirationDate?: string;
}

/**
 * Admin dashboard for CaseCare staff to manage organizations and their features
 */
const OrganizationFeatureManager: FC = () => {
  const context = useContext(AppContext);
  const queryClient = useQueryClient();
  const [searchTerm, setSearchTerm] = useState('');
  const [editingFeature, setEditingFeature] = useState<EditingFeature | null>(
    null,
  );
  const [editingSubscription, setEditingSubscription] =
    useState<EditingSubscription | null>(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<{
    type: 'feature' | 'subscription';
    id: string;
    orgId: string;
  } | null>(null);

  // Query to fetch all organizations
  const {
    data: organizationsResponse,
    isLoading,
    error,
    refetch,
  } = useQuery<Organization[]>(
    ['organizations'],
    () => GlobalAdminApi.listOrganizations(context.authToken),
    {
      enabled: context.isCaseCareStaff && !!context.authToken,
      onError: (err: any) => {
        snackbarUtils.error(
          err.message || context.i18n.errorLoadingOrganizations,
        );
      },
    },
  );

  // Extract organizations from the response
  const organizations = organizationsResponse || [];

  // Add debugging log
  useEffect(() => {
    if (organizationsResponse) {
      console.log('Organizations loaded:', organizationsResponse);
    }
  }, [organizationsResponse]);

  // Function to filter organizations based on search term
  const filteredOrganizations =
    organizations.filter(
      (org) =>
        org.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        org.subdomain.toLowerCase().includes(searchTerm.toLowerCase()),
    ) || [];

  // Mutation to update features
  const updateFeatureMutation = useMutation(
    (feature: EditingFeature) =>
      GlobalAdminApi.updateOrganizationFeature(
        context.authToken,
        feature.orgId,
        {
          id: feature.featureId,
          featureType: feature.featureType,
          status: feature.status,
          expirationDate: feature.expirationDate,
          billingProvider: 'MANUAL',
        },
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['organizations']);
        snackbarUtils.success(
          context.i18n.featureUpdatedSuccessfully ||
            'Feature updated successfully',
        );
        setEditingFeature(null);
      },
      onError: (err: any) => {
        snackbarUtils.error(
          err.message ||
            context.i18n.errorUpdatingFeature ||
            'Error updating feature',
        );
      },
    },
  );

  // Mutation to update subscription
  const updateSubscriptionMutation = useMutation(
    (subscription: EditingSubscription) =>
      GlobalAdminApi.updateOrganizationSubscription(
        context.authToken,
        subscription.orgId,
        {
          id: subscription.subscriptionId,
          status: subscription.status,
          expirationDate: subscription.expirationDate,
          provider: 'MANUAL',
        },
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['organizations']);
        snackbarUtils.success(
          context.i18n.subscriptionUpdatedSuccessfully ||
            'Subscription updated successfully',
        );
        setEditingSubscription(null);
      },
      onError: (err: any) => {
        snackbarUtils.error(
          err.message ||
            context.i18n.errorUpdatingSubscription ||
            'Error updating subscription',
        );
      },
    },
  );

  // Mutation to delete feature
  const deleteFeatureMutation = useMutation(
    (params: { orgId: string; featureId: string }) =>
      GlobalAdminApi.deleteOrganizationFeature(
        context.authToken,
        params.orgId,
        params.featureId,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['organizations']);
        snackbarUtils.success(
          context.i18n.featureDeletedSuccessfully ||
            'Feature deleted successfully',
        );
        setDeleteConfirmOpen(false);
        setItemToDelete(null);
      },
      onError: (err: any) => {
        snackbarUtils.error(
          err.message ||
            context.i18n.errorDeletingFeature ||
            'Error deleting feature',
        );
        setDeleteConfirmOpen(false);
      },
    },
  );

  // Mutation to delete subscription
  const deleteSubscriptionMutation = useMutation(
    (params: { orgId: string; subscriptionId: string }) =>
      GlobalAdminApi.deleteOrganizationSubscription(
        context.authToken,
        params.orgId,
        params.subscriptionId,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['organizations']);
        snackbarUtils.success(
          context.i18n.subscriptionDeletedSuccessfully ||
            'Subscription deleted successfully',
        );
        setDeleteConfirmOpen(false);
        setItemToDelete(null);
      },
      onError: (err: any) => {
        snackbarUtils.error(
          err.message ||
            context.i18n.errorDeletingSubscription ||
            'Error deleting subscription',
        );
        setDeleteConfirmOpen(false);
      },
    },
  );

  // State for multi-feature selection
  const [selectedFeatures, setSelectedFeatures] = useState<
    Record<string, boolean>
  >({
    FAMILY: false,
    RISK: false,
    GENOGRAMM: false,
    AVATAR: false,
    BILLING: false,
    TIME_TRACKING: false,
    ECO_MAP: false,
    SIMPLE_MODE: false,
  });

  const [isMultiFeatureMode, setIsMultiFeatureMode] = useState(false);

  // Function to start editing a feature
  const startEditing = (org: Organization, feature?: Feature) => {
    // Reset multi-feature selections when opening the form
    setSelectedFeatures({
      FAMILY: false,
      RISK: false,
      GENOGRAMM: false,
      AVATAR: false,
      BILLING: false,
      TIME_TRACKING: false,
      ECO_MAP: false,
      SIMPLE_MODE: false,
    });

    setIsMultiFeatureMode(false);

    setEditingFeature({
      orgId: org.id,
      featureId: feature?.id,
      featureType: feature?.featureType || 'FAMILY',
      status: feature?.status || 'ACTIVE', // Default to ACTIVE
      expirationDate: feature?.expirationDate,
    });
  };

  // Mutation to update multiple features at once
  const updateMultiFeaturesMutation = useMutation(
    (params: { orgId: string; features: any[] }) =>
      GlobalAdminApi.updateMultipleFeatures(
        context.authToken,
        params.orgId,
        params.features,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['organizations']);
        snackbarUtils.success('Features updated successfully');
        setEditingFeature(null);
        setIsMultiFeatureMode(false);
      },
      onError: (err: any) => {
        snackbarUtils.error(err.message || 'Error updating features');
      },
    },
  );

  // Function to save feature changes
  const saveFeatureChanges = () => {
    if (!editingFeature) return;

    if (isMultiFeatureMode) {
      // Get all selected features
      const featuresToAdd = Object.entries(selectedFeatures)
        .filter(([_, isSelected]) => isSelected)
        .map(([featureType, _]) => ({
          featureType,
          status: editingFeature.status || 'ACTIVE',
          expirationDate: editingFeature.expirationDate,
          billingProvider: 'MANUAL',
        }));

      if (featuresToAdd.length === 0) {
        snackbarUtils.error('Please select at least one feature');
        return;
      }

      updateMultiFeaturesMutation.mutate({
        orgId: editingFeature.orgId,
        features: featuresToAdd,
      });
    } else {
      // Single feature update
      updateFeatureMutation.mutate(editingFeature);
    }
  };

  // Function to cancel editing
  const cancelEditing = () => {
    setEditingFeature(null);
    setIsMultiFeatureMode(false);
  };

  // Function to start editing subscription
  const startEditingSubscription = (org: Organization) => {
    const subscription = org.subscription;
    setEditingSubscription({
      orgId: org.id,
      subscriptionId: subscription?.id,
      status: subscription?.status || 'ACTIVE',
      expirationDate: subscription?.expirationDate,
    });
  };

  // Function to save subscription changes
  const saveSubscriptionChanges = () => {
    if (editingSubscription) {
      updateSubscriptionMutation.mutate(editingSubscription);
    }
  };

  // Function to cancel subscription editing
  const cancelEditingSubscription = () => {
    setEditingSubscription(null);
  };

  // Function to handle delete confirmation
  const handleDelete = () => {
    if (itemToDelete) {
      if (itemToDelete.type === 'feature') {
        deleteFeatureMutation.mutate({
          orgId: itemToDelete.orgId,
          featureId: itemToDelete.id,
        });
      } else if (itemToDelete.type === 'subscription') {
        deleteSubscriptionMutation.mutate({
          orgId: itemToDelete.orgId,
          subscriptionId: itemToDelete.id,
        });
      }
    }
  };

  // Function to open delete confirmation
  const openDeleteConfirm = (
    type: 'feature' | 'subscription',
    id: string,
    orgId: string,
  ) => {
    setItemToDelete({ type, id, orgId });
    setDeleteConfirmOpen(true);
  };

  // Function to render status chips with appropriate colors
  const renderStatusChip = (status: string) => {
    switch (status) {
      case 'ACTIVE':
        return <Chip label={status} color="success" size="small" />;
      case 'PENDING':
        return <Chip label={status} color="warning" size="small" />;
      case 'INACTIVE':
        return <Chip label={status} color="error" size="small" />;
      default:
        return <Chip label={status} size="small" />;
    }
  };

  return (
    <MenuWrapper
      search={false}
      addClient={false}
      selected="casecare-admin"
      title={
        context.i18n.organizationFeatureManager ||
        'Organization Feature Manager'
      }
    >
      <Box p={3} width="100%">
        <Paper elevation={2} sx={{ p: 3, mb: 3 }}>
          <Typography variant="h5" gutterBottom>
            {context.i18n.organizationFeatureManagerDescription ||
              'Manage Organization Features'}
          </Typography>
          <Typography variant="body2" color="text.secondary" paragraph>
            {context.i18n.organizationFeatureManagerSubtitle ||
              'This dashboard allows CaseCare administrators to manage feature access for client organizations.'}
          </Typography>

          <Grid container spacing={2} alignItems="center" sx={{ mt: 2 }}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                variant="outlined"
                label={
                  context.i18n.searchOrganizations || 'Search Organizations'
                }
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: <SearchIcon color="action" sx={{ mr: 1 }} />,
                }}
              />
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                startIcon={<RefreshIcon />}
                onClick={() => refetch()}
              >
                {context.i18n.refresh || 'Refresh'}
              </Button>
            </Grid>
          </Grid>
        </Paper>

        {error ? (
          <Paper elevation={2} sx={{ p: 3, mb: 3, bgcolor: '#ffebee' }}>
            <Typography variant="h6" color="error" gutterBottom>
              {context.i18n.errorLoadingData || 'Error loading data'}
            </Typography>
            <Typography variant="body2" color="error">
              {(error as any)?.message ||
                'An unexpected error occurred. Please try refreshing the page.'}
            </Typography>
          </Paper>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {context.i18n.organizationName || 'Organization Name'}
                  </TableCell>
                  <TableCell>{context.i18n.subdomain || 'Subdomain'}</TableCell>
                  <TableCell>{context.i18n.features || 'Features'}</TableCell>
                  <TableCell>{context.i18n.actions || 'Actions'}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      {context.i18n.loading || 'Loading organizations...'}
                    </TableCell>
                  </TableRow>
                ) : filteredOrganizations.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      {searchTerm
                        ? context.i18n.noMatchingOrganizations ||
                          'No matching organizations found'
                        : context.i18n.noOrganizations ||
                          'No organizations available'}
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredOrganizations.map((org) => (
                    <TableRow key={org.id}>
                      <TableCell>{org.name}</TableCell>
                      <TableCell>{org.subdomain}</TableCell>
                      <TableCell>
                        <Box sx={{ mb: 2 }}>
                          <Typography variant="subtitle2" gutterBottom>
                            {context.i18n.subscription || 'Subscription'}:
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1,
                            }}
                          >
                            {org.subscription ? (
                              <>
                                {renderStatusChip(org.subscription.status)}
                                {org.subscription.expirationDate && (
                                  <Typography variant="body2">
                                    Expires:{' '}
                                    {new Date(
                                      org.subscription.expirationDate,
                                    ).toLocaleDateString()}
                                  </Typography>
                                )}
                                <IconButton
                                  size="small"
                                  onClick={() => startEditingSubscription(org)}
                                >
                                  <EditIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    org.subscription &&
                                    openDeleteConfirm(
                                      'subscription',
                                      org.subscription.id,
                                      org.id,
                                    )
                                  }
                                  color="error"
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </>
                            ) : (
                              <Button
                                variant="outlined"
                                size="small"
                                onClick={() => startEditingSubscription(org)}
                              >
                                {context.i18n.addSubscription ||
                                  'Add Subscription'}
                              </Button>
                            )}
                          </Box>
                        </Box>

                        <Divider sx={{ my: 2 }} />

                        <Typography variant="subtitle2" gutterBottom>
                          {context.i18n.features || 'Features'}:
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                          }}
                        >
                          {org.features.length === 0 ? (
                            <Typography variant="body2" color="text.secondary">
                              {context.i18n.noFeatures || 'No features'}
                            </Typography>
                          ) : (
                            org.features.map((feature) => (
                              <Box
                                key={feature.id}
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 1,
                                }}
                              >
                                <Typography variant="body2">
                                  {feature.featureType}:
                                </Typography>
                                {renderStatusChip(feature.status)}
                                {feature.expirationDate && (
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    fontSize="0.8rem"
                                  >
                                    (exp:{' '}
                                    {new Date(
                                      feature.expirationDate,
                                    ).toLocaleDateString()}
                                    )
                                  </Typography>
                                )}
                                <IconButton
                                  size="small"
                                  onClick={() => startEditing(org, feature)}
                                >
                                  <EditIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    openDeleteConfirm(
                                      'feature',
                                      feature.id,
                                      org.id,
                                    )
                                  }
                                  color="error"
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </Box>
                            ))
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => startEditing(org)}
                          sx={{ mb: 1 }}
                          disabled={
                            !org.subscription ||
                            org.subscription.status !== 'ACTIVE'
                          }
                        >
                          {context.i18n.addFeature || 'Add Feature'}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {/* Feature Edit Dialog */}
        {editingFeature && (
          <Paper elevation={3} sx={{ mt: 3, p: 3 }}>
            <Typography variant="h6" gutterBottom>
              {editingFeature.featureId
                ? context.i18n.editFeature || 'Edit Feature'
                : context.i18n.addFeature || 'Add Feature'}
            </Typography>

            {/* Add multi-feature mode toggle switch */}
            <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isMultiFeatureMode}
                      onChange={(e) => setIsMultiFeatureMode(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Add multiple features at once"
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              {isMultiFeatureMode ? (
                // Multi-feature selection UI
                <>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" gutterBottom>
                      Select features to add:
                    </Typography>
                    <Grid container spacing={1}>
                      {Object.entries({
                        FAMILY: 'Family',
                        RISK: 'Risk',
                        GENOGRAMM: 'Genogramm',
                        AVATAR: 'Avatar',
                        BILLING: 'Billing',
                        TIME_TRACKING: 'Time Tracking',
                        ECO_MAP: 'Eco Map',
                        SIMPLE_MODE: 'Simple Mode',
                      }).map(([value, label]) => (
                        <Grid item xs={12} sm={6} md={4} key={value}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedFeatures[value]}
                                onChange={(e) =>
                                  setSelectedFeatures({
                                    ...selectedFeatures,
                                    [value]: e.target.checked,
                                  })
                                }
                              />
                            }
                            label={label}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </>
              ) : (
                // Single feature selection UI
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="feature-type-label">
                      {context.i18n.featureType || 'Feature Type'}
                    </InputLabel>
                    <Select
                      labelId="feature-type-label"
                      value={editingFeature.featureType}
                      label={context.i18n.featureType || 'Feature Type'}
                      onChange={(e) =>
                        setEditingFeature({
                          ...editingFeature,
                          featureType: e.target.value as any,
                        })
                      }
                    >
                      <MenuItem value="FAMILY">
                        {context.i18n.familyModule || 'Family'}
                      </MenuItem>
                      <MenuItem value="RISK">
                        {context.i18n.riskModule || 'Risk'}
                      </MenuItem>
                      <MenuItem value="GENOGRAMM">
                        {context.i18n.genoGrammModule || 'Genogramm'}
                      </MenuItem>
                      <MenuItem value="AVATAR">
                        {context.i18n.avatarModule || 'Avatar'}
                      </MenuItem>
                      <MenuItem value="BILLING">
                        {context.i18n.billingModule || 'Billing'}
                      </MenuItem>
                      <MenuItem value="TIME_TRACKING">
                        {context.i18n.timeTrackingModule || 'Time Tracking'}
                      </MenuItem>
                      <MenuItem value="ECO_MAP">
                        {context.i18n.ecoMapModule || 'Eco Map'}
                      </MenuItem>
                      <MenuItem value="SIMPLE_MODE">
                        {context.i18n.simpleModeModule || 'Simple Mode'}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}

              {/* Common fields for both single and multi-feature modes */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="status-label">
                    {context.i18n.status || 'Status'}
                  </InputLabel>
                  <Select
                    labelId="status-label"
                    value={editingFeature.status || 'ACTIVE'}
                    label={context.i18n.status || 'Status'}
                    onChange={(e) =>
                      setEditingFeature({
                        ...editingFeature,
                        status: e.target.value as any,
                      })
                    }
                  >
                    <MenuItem value="ACTIVE">
                      {context.i18n.active || 'Active'}
                    </MenuItem>
                    <MenuItem value="INACTIVE">
                      {context.i18n.inactive || 'Inactive'}
                    </MenuItem>
                    <MenuItem value="PENDING">
                      {context.i18n.pending || 'Pending'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={
                    context.i18n.expirationDate || 'Expiration Date (optional)'
                  }
                  type="date"
                  value={editingFeature.expirationDate?.split('T')[0] || ''}
                  onChange={(e) =>
                    setEditingFeature({
                      ...editingFeature,
                      expirationDate: e.target.value
                        ? `${e.target.value}T00:00:00.000Z`
                        : undefined,
                    })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}
              >
                <Button
                  variant="outlined"
                  startIcon={<CancelIcon />}
                  onClick={cancelEditing}
                >
                  {context.i18n.cancel || 'Cancel'}
                </Button>
                <Button
                  variant="contained"
                  startIcon={<SaveIcon />}
                  onClick={saveFeatureChanges}
                  disabled={
                    updateFeatureMutation.isLoading ||
                    updateMultiFeaturesMutation.isLoading
                  }
                >
                  {updateFeatureMutation.isLoading ||
                  updateMultiFeaturesMutation.isLoading
                    ? context.i18n.saving || 'Saving...'
                    : context.i18n.save || 'Save'}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        )}

        {/* Subscription Edit Dialog */}
        {editingSubscription && (
          <Paper elevation={3} sx={{ mt: 3, p: 3 }}>
            <Typography variant="h6" gutterBottom>
              {editingSubscription.subscriptionId
                ? context.i18n.editSubscription || 'Edit Subscription'
                : context.i18n.addSubscription || 'Add Subscription'}
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="subscription-status-label">
                    {context.i18n.status || 'Status'}
                  </InputLabel>
                  <Select
                    labelId="subscription-status-label"
                    value={editingSubscription.status}
                    label={context.i18n.status || 'Status'}
                    onChange={(e) =>
                      setEditingSubscription({
                        ...editingSubscription,
                        status: e.target.value as any,
                      })
                    }
                  >
                    <MenuItem value="ACTIVE">
                      {context.i18n.active || 'Active'}
                    </MenuItem>
                    <MenuItem value="INACTIVE">
                      {context.i18n.inactive || 'Inactive'}
                    </MenuItem>
                    <MenuItem value="PAST_DUE">
                      {context.i18n.pastDue || 'Past Due'}
                    </MenuItem>
                    <MenuItem value="CANCELED">
                      {context.i18n.canceled || 'Canceled'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label={
                    context.i18n.expirationDate || 'Expiration Date (optional)'
                  }
                  type="date"
                  value={
                    editingSubscription.expirationDate?.split('T')[0] || ''
                  }
                  onChange={(e) =>
                    setEditingSubscription({
                      ...editingSubscription,
                      expirationDate: e.target.value
                        ? `${e.target.value}T00:00:00.000Z`
                        : undefined,
                    })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}
              >
                <Button
                  variant="outlined"
                  startIcon={<CancelIcon />}
                  onClick={cancelEditingSubscription}
                >
                  {context.i18n.cancel || 'Cancel'}
                </Button>
                <Button
                  variant="contained"
                  startIcon={<SaveIcon />}
                  onClick={saveSubscriptionChanges}
                  disabled={updateSubscriptionMutation.isLoading}
                >
                  {updateSubscriptionMutation.isLoading
                    ? context.i18n.saving || 'Saving...'
                    : context.i18n.save || 'Save'}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        )}

        {/* Delete Confirmation Dialog */}
        <Dialog
          open={deleteConfirmOpen}
          onClose={() => setDeleteConfirmOpen(false)}
        >
          <DialogTitle>
            {context.i18n.confirmDelete || 'Confirm Delete'}
          </DialogTitle>
          <DialogContent>
            <Typography>
              {itemToDelete?.type === 'feature'
                ? context.i18n.confirmDeleteFeature ||
                  'Are you sure you want to delete this feature?'
                : context.i18n.confirmDeleteSubscription ||
                  'Are you sure you want to delete this subscription?'}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteConfirmOpen(false)}>
              {context.i18n.cancel || 'Cancel'}
            </Button>
            <Button
              onClick={handleDelete}
              color="error"
              disabled={
                deleteFeatureMutation.isLoading ||
                deleteSubscriptionMutation.isLoading
              }
            >
              {deleteFeatureMutation.isLoading ||
              deleteSubscriptionMutation.isLoading
                ? context.i18n.deleting || 'Deleting...'
                : context.i18n.delete || 'Delete'}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </MenuWrapper>
  );
};

export default OrganizationFeatureManager;
