import {
  faPlus,
  faFloppyDisk,
  faPenToSquare,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Tabs,
  Tab,
  Typography,
  Dialog,
  Menu,
  ListItemText,
  MenuItem,
  Box,
  tabsClasses,
} from '@mui/material';
import { FC, useState, useContext, useEffect } from 'react';
import { AppContext } from '../../../hooks/context';
import { CategoryData, ClientTabType } from '../../../types';
import { TabStyle } from '../../../utils/styling/styling';
import { AddCategoryDialog, DiscardDialog } from '../../dialogs';
import TabLink from '../../core/tab-link/tab-link';

interface ClientTabsProps {
  categoryTabs: CategoryData[];
  selected?: string;
  setId?: (id: string) => void;
  showRiskAssessment: boolean;
  onSave: () => void;
  hideTabs?: boolean;
}

const ClientTabs: FC<ClientTabsProps> = (props) => {
  const context = useContext(AppContext);
  function filterShown() {
    return props.categoryTabs.filter(
      (categoryTab) =>
        categoryTab.hasData ||
        categoryTab.default ||
        props.selected === categoryTab.id,
    );
  }
  function filterHidden() {
    return props.categoryTabs.filter(
      (categoryTab) => !categoryTab.hasData && !categoryTab.default,
    );
  }
  const [futureSelected, setFutureSelected] = useState('');
  const [hiddenCategoryTabs, setHiddenCategoryTabs] = useState<CategoryData[]>(
    filterHidden(),
  );
  const [shownCategoryTabs, setShownCategoryTabs] = useState<CategoryData[]>(
    filterShown(),
  );
  const [openAddCategory, setOpenAddCategory] = useState(false);
  const [openSave, setOpenSave] = useState(false);
  const [showRiskAssessment, setShowRiskAssessment] = useState(
    props.showRiskAssessment,
  );
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchor);

  // Reset selected to initialId if tabs change
  useEffect(() => {
    setHiddenCategoryTabs(filterHidden());
    setShownCategoryTabs(filterShown());
  }, [props.categoryTabs]);

  const handleChange = (newValue: string) => {
    if (newValue === ClientTabType.SAVE) {
      props.onSave();
      return;
    }

    if (context.unsavedChanges) {
      setOpenSave(true);
      setFutureSelected(newValue);
      return;
    }

    switch (newValue) {
      case ClientTabType.ADD:
        return;
      default:
        props.setId && props.setId(newValue);
        break;
    }
  };

  const onAddCategory = (newTab: CategoryData) => {
    shownCategoryTabs.push(newTab);
    setShownCategoryTabs(shownCategoryTabs);
    setHiddenCategoryTabs(
      hiddenCategoryTabs.filter((tab) => tab.id !== newTab.id),
    );
    handleChange(newTab.id);
  };

  const onAddRisk = () => {
    setShowRiskAssessment(true);
    handleChange(ClientTabType.RISK);
  };

  const onDiscard = () => {
    context.discardChanges();
    setOpenSave(false);
    handleChange(futureSelected);
  };

  const onSave = () => {
    context.saveChanges();
    setOpenSave(false);
    handleChange(futureSelected);
  };

  return (
    <Box>
      <Tabs
        value={props.selected}
        onChange={(e, value) => handleChange(value)}
        TabIndicatorProps={{
          style: {
            display: 'none',
          },
        }}
        sx={{
          m: 0,
          ml: 3,
          mr: 3,
          height: 58,
          alignItems: 'end',
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { display: 'none' },
            bgcolor: 'secondary.main',
            height: 48,
            transition: 'all .5s ease-in-out',
            boxShadow: '2px 3px 6px #00000029;',
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
          },
          '& .MuiTabScrollButton-root:first-child': {
            mr: 1,
          },
          '& .MuiTabScrollButton-root:last-child': {
            ml: 1,
          },
        }}
        variant="scrollable"
      >
        <TabLink
          href={`/clients/${context.clientId}/todo`}
          sx={TabStyle}
          value={ClientTabType.TODO}
          label={
            <Typography color="white" textTransform="none">
              {context.i18n.clientTabTodo}
            </Typography>
          }
        />
        {/* <TabLink
          href={`/clients/${context.clientId}/timeTracking`}
          sx={TabStyle}
          value={ClientTabType.TIME_TRACKING}
          label={
            <Typography color="white" textTransform="none">
              {context.i18n.clientTabTimeTracking}
            </Typography>
          }
        /> */}
        <TabLink
          href={`/clients/${context.clientId}/clientData`}
          sx={TabStyle}
          value={ClientTabType.DATA}
          label={
            <Typography color="white" textTransform="none">
              {context.i18n.clientTabClientData}
            </Typography>
          }
        />
        <TabLink
          href={`/clients/${context.clientId}/social`}
          sx={TabStyle}
          value={ClientTabType.SOCIAL_ENVIRONMENT}
          label={
            <Typography color="white" textTransform="none">
              {context.i18n.clientTabSocial}
            </Typography>
          }
        />
        <TabLink
          href={`/clients/${context.clientId}/social-diagnostic`}
          sx={TabStyle}
          value={ClientTabType.SOCIAL_DIAGNOSTIC}
          label={
            <Typography color="white" textTransform="none">
              {context.i18n.clientTabSocialdiagnostic}
            </Typography>
          }
        />
        {!props.hideTabs &&
          shownCategoryTabs.map((tab) =>
            tab ? (
              <TabLink
                href={`/clients/${context.clientId}/${tab.id}`}
                key={tab.id}
                value={tab.id}
                id={tab.id}
                label={
                  <Typography color="white" textTransform="none">
                    {tab.label}
                  </Typography>
                }
                sx={TabStyle}
              />
            ) : null,
          )}
        <TabLink
          href={`/clients/${context.clientId}/documentation`}
          sx={TabStyle}
          value={ClientTabType.DOCUMENTATION}
          label={
            <Typography color="white" textTransform="none">
              {context.i18n.clientTabDocumentation}
            </Typography>
          }
        />
        <TabLink
          href={`/clients/${context.clientId}/storage`}
          sx={TabStyle}
          value={ClientTabType.STORAGE}
          label={
            <Typography color="white" textTransform="none">
              {context.i18n.clientTabStorage}
            </Typography>
          }
        />
        {/* Hide permissions tab in SIMPLE_MODE */}
        {!context.simpleModeActive && (
          <TabLink
            href={`/clients/${context.clientId}/permissions`}
            sx={TabStyle}
            value={ClientTabType.PERMISSIONS}
            label={
              <Typography color="white" textTransform="none">
                {context.i18n.clientTabPermissions}
              </Typography>
            }
          />
        )}
        <TabLink
          href={`/clients/${context.clientId}/notes`}
          sx={TabStyle}
          value={ClientTabType.NOTES}
          label={
            <Typography color="white" textTransform="none">
              {context.i18n.clientTabNotes}
            </Typography>
          }
        />
        {showRiskAssessment && !props.hideTabs && (
          <TabLink
            href={`/clients/${context.clientId}/risk`}
            value={ClientTabType.RISK}
            id="risk-assessment-tab"
            label={
              <Typography color="white" textTransform="none">
                {context.i18n.clientTabRisk}
              </Typography>
            }
            sx={TabStyle}
          />
        )}
        {!props.hideTabs && (
          <Tab
            value={ClientTabType.ADD}
            id="add-category-tab"
            icon={<FontAwesomeIcon icon={faPlus} color="white" />}
            onClick={(event) => setMenuAnchor(event.currentTarget)}
            sx={{
              bgcolor: 'secondary.main',
              boxShadow: '2px 3px 6px #00000029;',
              height: 48,
              opacity: 0.5,
              alignSelf: 'end',
              display:
                hiddenCategoryTabs.length === 0 && showRiskAssessment
                  ? 'none'
                  : 'default',
            }}
          />
        )}
        <Tab
          value={ClientTabType.SAVE}
          icon={<FontAwesomeIcon icon={faFloppyDisk} color="white" />}
          sx={{
            bgcolor: 'success.light',
            boxShadow: '2px 3px 6px #00000029;',
            height: 48,
            alignSelf: 'end',
            display: context.unsavedChanges ? 'default' : 'none',
          }}
        />
      </Tabs>
      <Menu
        anchorEl={menuAnchor}
        open={menuOpen}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transitionDuration={0}
      >
        {hiddenCategoryTabs.length !== 0 && (
          <MenuItem
            id="add-form-category"
            onClick={() => {
              setOpenAddCategory(true);
              setMenuAnchor(null);
            }}
          >
            <ListItemText>{context.i18n.addCategory}</ListItemText>
          </MenuItem>
        )}
        {!showRiskAssessment && (
          <MenuItem
            id="add-risk-assessment"
            onClick={() => {
              onAddRisk();
              setMenuAnchor(null);
            }}
          >
            <ListItemText>{context.i18n.addRiskAssessment}</ListItemText>
          </MenuItem>
        )}
      </Menu>
      <Dialog open={openAddCategory} onClose={() => setOpenAddCategory(false)}>
        <AddCategoryDialog
          categories={hiddenCategoryTabs}
          onAdd={onAddCategory.bind(this)}
          onClose={() => setOpenAddCategory(false)}
        />
      </Dialog>
      {/* <Dialog open={openSave} onClose={() => setOpenSave(false)}>
        <DiscardDialog
          onDiscard={onDiscard}
          onSave={onSave}
          onClose={() => setOpenSave(false)}
        />
      </Dialog> */}
    </Box>
  );
};

export default ClientTabs;
