import { Navigate, Outlet } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../hooks/context';

// Prevents access to admin billing routes if simple mode is active
const AdminBillingRoute = () => {
  const { simpleModeActive } = useContext(AppContext);

  if (simpleModeActive) {
    // If simple mode is active, redirect to admin area
    return <Navigate to="/admin/users" />;
  }

  return <Outlet />;
};

export default AdminBillingRoute;
