import {
  faArrowRightFromBracket,
  faCaretLeft,
  faClock,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Toolbar, IconButton, Box } from '@mui/material';
import { context } from 'msw';
import { FC, useContext } from 'react';
import { Header } from '../../core';
import { AppContext } from '../../../hooks/context';
import { useNavigate } from 'react-router-dom';
interface MobileNavBarProps {
  title: string;
  subTitle?: string;
  back?: boolean;
  onBack?: () => void;
  add?: boolean;
  onAdd?: () => void;
  logoutBtn?: boolean;
  timeTrackingBtn?: boolean;
}

const MobileNavBar: FC<MobileNavBarProps> = (props) => {
  const { logout } = useContext(AppContext);
  const navigate = useNavigate();

  return (
    <>
      <AppBar color="inherit" sx={{ pb: 2 }}>
        <Toolbar>
          {props.logoutBtn && !props.timeTrackingBtn && (
            <IconButton size="large" color="secondary" aria-label="menu">
              <></>
            </IconButton>
          )}
          {props.timeTrackingBtn && (
            <IconButton
              size="large"
              color="secondary"
              aria-label="menu"
              onClick={() => navigate('/mobile/time-tracking')}
            >
              <FontAwesomeIcon icon={faClock} />
            </IconButton>
          )}
          {props.back && (
            <IconButton
              size="large"
              color="secondary"
              aria-label="menu"
              onClick={(event) => props.onBack && props.onBack()}
            >
              <FontAwesomeIcon icon={faCaretLeft} />
            </IconButton>
          )}
          <Box
            sx={{
              flexGrow: 1,
              textAlign: 'center',
              textTransform: 'uppercase',
              mt: 2,
            }}
          >
            <Header title={props.title} subTitle={props.subTitle} />
          </Box>
          {props.add && (
            <IconButton
              size="large"
              color="secondary"
              aria-label="menu"
              onClick={(event) => props.onAdd && props.onAdd()}
            >
              <FontAwesomeIcon icon={faPlus} />
            </IconButton>
          )}
          {props.logoutBtn && (
            <IconButton
              size="large"
              color="secondary"
              aria-label="menu"
              onClick={(event) => logout()}
            >
              <FontAwesomeIcon icon={faArrowRightFromBracket} />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar
        sx={{
          mb: 4,
        }}
      />
    </>
  );
};

export default MobileNavBar;
