import { FC, useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { AppContext } from '../../../hooks/context';
import { TimeTrackingApi } from '../../../api';
import {
  DateRange,
  DateRangePicker,
  PickersShortcutsItem,
  SingleInputDateRangeField,
} from '@mui/x-date-pickers-pro';
import moment, { Moment } from 'moment';
import { parseDurationNumber } from '../../../utils/date/date';
import { TimeTrackingStatus } from '../../../types/time-tracking-status';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport, faEye } from '@fortawesome/free-solid-svg-icons';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';

interface UserSummaryProps {
  dateRange: DateRange<Moment>;
  onUserSelect: (userId: string, userName: string) => void;
  onDateRangeChange: (dateRange: DateRange<Moment>) => void;
}

interface UserSummaryData {
  userId: string;
  userName: string;
  totalTrackedTime: number;
  totalSubmittedTime: number;
  totalCompletedTime: number;
  statusCounts: {
    [key in TimeTrackingStatus]?: number;
  };
}

export const TimeTrackingUserSummary: FC<UserSummaryProps> = ({
  dateRange,
  onUserSelect,
  onDateRangeChange,
}) => {
  const context = useContext(AppContext);
  const [summaryData, setSummaryData] = useState<UserSummaryData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [loadingExportUserId, setLoadingExportUserId] = useState<string | null>(
    null,
  );

  const fetchUserSummary = async (initialLoad = false) => {
    // Use tableLoading for filter changes, isLoading only for initial load
    if (initialLoad) {
      setIsLoading(true);
    } else {
      setTableLoading(true);
    }

    try {
      // Convert date range to UTC format for the API
      let startDate = dateRange[0];
      let endDate = dateRange[1];

      if (startDate) {
        startDate = startDate.clone().startOf('day');
      }

      if (endDate) {
        endDate = endDate.clone().endOf('day');
      }

      const data = {
        start: startDate?.utc().format(),
        end: endDate?.utc().format(),
      };

      const response = await TimeTrackingApi.getUserSummary(
        context.authToken,
        data,
      );

      // Check multiple response formats to handle API inconsistencies
      if (response.data) {
        if (Array.isArray(response.data)) {
          setSummaryData(response.data);
        } else if (
          response.data.result &&
          Array.isArray(response.data.result)
        ) {
          setSummaryData(response.data.result);
        } else if (response.data.data && Array.isArray(response.data.data)) {
          setSummaryData(response.data.data);
        } else {
          // Try to handle a single object response
          const item =
            response.data.result || response.data.data || response.data;
          if (item && typeof item === 'object') {
            setSummaryData([item]);
          }
        }
      }
    } catch (error) {
      console.error('Error fetching user summary data:', error);
    } finally {
      if (initialLoad) {
        setIsLoading(false);
      } else {
        setTableLoading(false);
      }
    }
  };

  // Initial load
  useEffect(() => {
    if (context.authToken && dateRange[0] && dateRange[1]) {
      fetchUserSummary(true);
    }
  }, [context.authToken]);

  // Handle date range changes
  useEffect(() => {
    if (context.authToken && dateRange[0] && dateRange[1] && !isLoading) {
      fetchUserSummary(false);
    }
  }, [dateRange]);

  // Handler for exporting completed entries for a specific user
  const handleExportCompletedEntries = async (
    userId: string,
    userName: string,
  ) => {
    // Keep track of which user's export is currently loading
    setLoadingExportUserId(userId);
    try {
      // Convert date range to UTC format for the API
      let startDate = dateRange[0];
      let endDate = dateRange[1];

      if (startDate) {
        startDate = startDate.clone().startOf('day');
      }

      if (endDate) {
        endDate = endDate.clone().endOf('day');
      }

      const data = {
        userId: userId,
        start: startDate?.utc().format(),
        end: endDate?.utc().format(),
        status: [TimeTrackingStatus.COMPLETED], // Only COMPLETED entries
      };

      const response = await TimeTrackingApi.exportCompletedEntries(
        context.authToken,
        data,
      );

      // Get the filename from the Content-Disposition header if available
      let fileName = 'time-tracking-export.xlsx';

      // Extract filename from Content-Disposition header
      const contentDisposition = response.headers['content-disposition'];
      console.log('Content-Disposition header:', contentDisposition);

      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(
          /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
        );
        if (filenameMatch && filenameMatch[1]) {
          fileName = filenameMatch[1].replace(/['"]/g, '');
          console.log('Extracted filename from header:', fileName);
        }
      }

      // Create a download link for the Excel file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      console.log('Using filename for download:', fileName);

      document.body.appendChild(link);
      link.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error exporting completed entries:', error);
      snackbarUtils.error(
        context.i18n.timeTrackingExportError ||
          'Error exporting completed entries',
      );
    } finally {
      setLoadingExportUserId(null);
    }
  };

  // Only show full loading on initial component load when no data exists
  if (isLoading && summaryData.length === 0) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="300px"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        {context.i18n.timeTrackingUserSummary}
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
        <Button
          variant="outlined"
          onClick={() => {
            const currentStart = moment(dateRange[0]);
            const previousMonth = currentStart.clone().subtract(1, 'month');
            onDateRangeChange([
              previousMonth.clone().startOf('month'),
              previousMonth.clone().endOf('month'),
            ]);
          }}
        >
          {context.i18n.previousMonth || 'Previous Month'}
        </Button>

        <Typography variant="h6">
          {dateRange[0]?.format('MMMM YYYY')}
        </Typography>

        <Button
          variant="outlined"
          onClick={() => {
            const currentStart = moment(dateRange[0]);
            const nextMonth = currentStart.clone().add(1, 'month');
            onDateRangeChange([
              nextMonth.clone().startOf('month'),
              nextMonth.clone().endOf('month'),
            ]);
          }}
          disabled={
            dateRange[0]?.month() === moment().month() &&
            dateRange[0]?.year() === moment().year()
          }
        >
          {context.i18n.nextMonth || 'Next Month'}
        </Button>
      </Box>

      {summaryData && summaryData.length > 0 ? (
        <Box position="relative">
          {/* Table loading overlay */}
          {tableLoading && (
            <Box
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
              top={0}
              left={0}
              right={0}
              bottom={0}
              bgcolor="rgba(255, 255, 255, 0.7)"
              zIndex={10}
              sx={{ mt: 2, borderRadius: 1 }}
            >
              <CircularProgress />
            </Box>
          )}

          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{context.i18n.timeTrackingUser}</TableCell>
                  <TableCell align="right">
                    {context.i18n.timeTrackingTotalTracked}
                  </TableCell>
                  <TableCell align="right">
                    {context.i18n.timeTrackingTotalSubmitted}
                  </TableCell>
                  <TableCell align="right">
                    {context.i18n.timeTrackingTotalCompleted}
                  </TableCell>
                  <TableCell align="center">
                    {context.i18n.timeTrackingStatus}
                  </TableCell>
                  <TableCell align="center" width="120px"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {summaryData.map((user) => (
                  <TableRow key={user.userId || 'unknown'}>
                    <TableCell component="th" scope="row">
                      {user.userName || 'Unknown User'}
                    </TableCell>
                    <TableCell align="right">
                      {user.totalTrackedTime !== undefined
                        ? parseDurationNumber(user.totalTrackedTime, true)
                        : '0:00'}
                    </TableCell>
                    <TableCell align="right">
                      {user.totalSubmittedTime !== undefined
                        ? parseDurationNumber(user.totalSubmittedTime, true)
                        : '0:00'}
                    </TableCell>
                    <TableCell align="right">
                      {user.totalCompletedTime !== undefined
                        ? parseDurationNumber(user.totalCompletedTime, true)
                        : '0:00'}
                    </TableCell>
                    <TableCell align="center">
                      <Grid container spacing={1}>
                        {user.statusCounts &&
                          Object.entries(user.statusCounts).map(
                            ([status, count]) => (
                              <Grid item xs={6} key={status}>
                                <Card
                                  variant="outlined"
                                  sx={{
                                    backgroundColor:
                                      status === TimeTrackingStatus.REJECTED
                                        ? 'error.light'
                                        : status ===
                                          TimeTrackingStatus.COMPLETED
                                        ? 'success.light'
                                        : status ===
                                          TimeTrackingStatus.SUBMITTED
                                        ? 'background.paper' // Changed: now uses standard background for SUBMITTED
                                        : '#f5f5f5', // Changed: now uses a muted color for OPEN status
                                  }}
                                >
                                  <CardContent
                                    sx={{
                                      py: 1,
                                      px: 1,
                                      '&:last-child': { pb: 1 },
                                    }}
                                  >
                                    <Typography variant="body2" align="center">
                                      {context.i18n[
                                        `timeTrackingStatus_${status}`
                                      ] || status}
                                      : {count}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </Grid>
                            ),
                          )}
                      </Grid>
                    </TableCell>
                    <TableCell align="center" width="120px">
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Tooltip
                          title={
                            context.i18n.timeTrackingViewDetails ||
                            'View Details'
                          }
                        >
                          <IconButton
                            color="primary"
                            onClick={() =>
                              onUserSelect(user.userId, user.userName)
                            }
                            sx={{ height: 39 }}
                            disableRipple
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </IconButton>
                        </Tooltip>

                        <Tooltip
                          title={
                            context.i18n.timeTrackingExportCompleted || 'Export'
                          }
                        >
                          <IconButton
                            color="primary"
                            onClick={() =>
                              handleExportCompletedEntries(
                                user.userId,
                                user.userName,
                              )
                            }
                            disabled={loadingExportUserId === user.userId}
                            sx={{ height: 39 }}
                            disableRipple
                          >
                            {loadingExportUserId === user.userId ? (
                              <CircularProgress size={16} color="inherit" />
                            ) : (
                              <FontAwesomeIcon icon={faFileExport} />
                            )}
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="200px"
          sx={{ backgroundColor: 'background.paper', borderRadius: 1, mt: 2 }}
        >
          <Typography variant="body1" color="text.secondary">
            {context.i18n.noData ||
              'No data available for the selected time period'}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
