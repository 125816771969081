import { createTheme, SortDirection } from '@mui/material';
import { titleSuffix } from '../constants';
import { i18n } from '../i18n/i18n';
import { deDE } from '@mui/material/locale';
import { EnumType, SortedArray } from 'typescript';
import { object } from 'underscore';
import { LocalizedStrings } from 'react-localization';

const breakpoints = createTheme();

export const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#32436E',
      },
      secondary: {
        main: '#91A3D9',
      },
    },
    typography: {
      fontFamily: 'Roboto',
      body1: {
        fontSize: '1rem',
        [breakpoints.breakpoints.down('xl')]: {
          fontSize: '0.95rem',
        },
        [breakpoints.breakpoints.down('lg')]: {
          fontSize: '0.9rem',
        },
        [breakpoints.breakpoints.down('md')]: {
          fontSize: '0.85rem',
        },
        [breakpoints.breakpoints.down('sm')]: {
          fontSize: '0.8rem',
        },
      },
      body2: {
        fontSize: '0.875rem',
        [breakpoints.breakpoints.down('sm')]: {
          fontSize: '0.8rem',
        },
      },
      button: {
        fontSize: '1rem',
        [breakpoints.breakpoints.down('xl')]: {
          fontSize: '0.95rem',
        },
        [breakpoints.breakpoints.down('lg')]: {
          fontSize: '0.9rem',
        },
        [breakpoints.breakpoints.down('md')]: {
          fontSize: '0.85rem',
        },
        [breakpoints.breakpoints.down('sm')]: {
          fontSize: '0.85rem',
        },
        textTransform: 'none',
      },
      h1: {
        fontSize: '2.2rem',
        [breakpoints.breakpoints.down('xl')]: {
          fontSize: '2rem',
        },
        [breakpoints.breakpoints.down('lg')]: {
          fontSize: '1.8rem',
        },
        [breakpoints.breakpoints.down('md')]: {
          fontSize: '1.6rem',
        },
        [breakpoints.breakpoints.down('sm')]: {
          fontSize: '1.4rem',
        },
      },
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontSize: '1rem',
            [breakpoints.breakpoints.down('sm')]: {
              fontSize: '0.85rem',
            },
          },
        },
      },
    },
  },
  i18n.getLanguage() !== 'en' ? deDE : {},
);

export const setDocumentTitle = (title?: string) => {
  if (!title) {
    document.title = titleSuffix;
    return;
  }
  document.title = `${title} | ${titleSuffix}`;
};

export const loadTitle = () => {
  document.title = `... | ${titleSuffix}`;
};

export const getSanitizedFileName = (input: string | undefined) => {
  if (!input) return '';
  return input
    .replaceAll(' ', '_')
    .replaceAll('Ä', 'Ae')
    .replaceAll('ä', 'ae')
    .replaceAll('Ö', 'Oe')
    .replaceAll('ö', 'oe')
    .replaceAll('Ü', 'Ue')
    .replaceAll('ü', 'ue')
    .replaceAll('ß', 'ss');
};

export const sortEnumWithLanguage = (
  enumObj: object,
  orderASC = true,
): { key: any; label: string }[] => {
  const langArray = Object.keys(enumObj).map((key: any) => {
    return { key, label: i18n[key as keyof typeof i18n] as string };
  });
  langArray.sort(function (a, b) {
    const labelA = a.label.toLowerCase(),
      labelB = b.label.toLowerCase();
    if (orderASC) return labelA < labelB ? -1 : 1;
    else return labelA > labelB ? -1 : 1;
  });

  return langArray;
};
