import {
  faFileExport,
  faFloppyDisk,
  faLink,
  faX,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  IconButton,
  CircularProgress,
  Box,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  Collapse,
} from '@mui/material';
import { FC, useContext, useState, useEffect } from 'react';
import { AppContext } from '../../../hooks/context';
import { Protocol } from '../../../types';
import { ProtocolFields } from '../../client/documentation';
import * as moment from 'moment';
import { ProtocolApi } from '../../../api';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';

interface EditProtocolDialogProps {
  open: boolean;
  onSave?: () => void;
  onClose: () => void;
  protocol?: Protocol;
  familyId?: string;
  clientId?: string;
  fullScreen?: boolean;
  isEdit?: boolean;
  loading?: boolean;
  showReleaseButton?: boolean;
  onRelease?: () => void;
  submitOnSaveEnabled?: boolean;
  adminView?: boolean;
  released?: boolean;
  startDate?: moment.Moment;
  endDate?: moment.Moment;
  timeTrackingId?: string;
  onLinkProtocol?: (protocolId: string) => void;
}

const EditProtocolDialog: FC<EditProtocolDialogProps> = (props) => {
  const context = useContext(AppContext);

  const { loading, showReleaseButton } = props;

  const [open, setOpen] = useState(props.open);
  const [save, setSave] = useState(false);
  const [submitOnSave, setSubmitOnSave] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const [nearbyProtocols, setNearbyProtocols] = useState<Protocol[]>([]);
  const [loadingNearbyProtocols, setLoadingNearbyProtocols] = useState(false);
  const [showNearbyProtocolsList, setShowNearbyProtocolsList] = useState(false);

  // Function to fetch nearby protocols
  const fetchNearbyProtocols = async () => {
    if (
      !props.startDate ||
      (!props.clientId && !props.familyId) ||
      props.protocol?.id
    ) {
      return;
    }

    try {
      setLoadingNearbyProtocols(true);

      // Calculate the time range (±1 hour from start time)
      const startTime = props.startDate.clone().subtract(1, 'hour').format();
      const endTime = props.startDate.clone().add(1, 'hour').format();

      // Make API call to fetch protocols within the time range
      const response = await ProtocolApi.searchNearbyProtocols(
        context.authToken,
        {
          clientId: props.clientId,
          familyId: props.familyId,
          startTimeFrom: startTime,
          startTimeTo: endTime,
        },
      );

      if (response && response.data) {
        // Filter out any protocols that are already linked to the current time tracking entry
        const filteredProtocols = response.data.filter(
          (protocol: Protocol & { timeTracking?: any[] }) =>
            !props.timeTrackingId ||
            !protocol.timeTracking ||
            !protocol.timeTracking.some(
              (tt: any) => tt.id === props.timeTrackingId,
            ),
        );

        setNearbyProtocols(filteredProtocols);
      }
    } catch (error) {
      console.error('Error fetching nearby protocols:', error);
    } finally {
      setLoadingNearbyProtocols(false);
    }
  };

  // Handle linking a protocol
  const handleLinkProtocol = (protocolId: string) => {
    if (props.onLinkProtocol) {
      props.onLinkProtocol(protocolId);
      setShowNearbyProtocolsList(false);
      props.onClose();
    }
  };

  useEffect(() => {
    if (save) {
      setSave(false);
    }
  }, [save]);

  useEffect(() => {
    if (submitOnSave) {
      setSubmitOnSave(false);
    }
  }, [submitOnSave]);

  useEffect(() => {
    setOpen(props.open);

    // When dialog opens, check for nearby protocols
    if (props.open && !props.protocol?.id) {
      fetchNearbyProtocols();
    }
  }, [
    props.open,
    props.clientId,
    props.familyId,
    props.startDate,
    props.protocol,
  ]);

  return (
    <Dialog fullScreen={props.fullScreen} fullWidth open={open}>
      <DialogTitle>{context.i18n.editProtocol}</DialogTitle>

      {/* Nearby protocols section */}
      {nearbyProtocols.length > 0 && !props.protocol?.id && !loading && (
        <Box
          sx={{
            m: 2,
            p: 2,
            border: '1px solid',
            borderColor: 'primary.light',
            borderRadius: 1,
            bgcolor: 'background.paper',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
              }}
              onClick={() =>
                setShowNearbyProtocolsList(!showNearbyProtocolsList)
              }
            >
              <FontAwesomeIcon
                icon={faLink}
                color="primary"
                style={{ marginRight: '8px' }}
              />
              <Typography color="primary" fontWeight="medium">
                {context.i18n.nearbyProtocolsFound || 'Nearby protocols found'}{' '}
                ({nearbyProtocols.length})
              </Typography>
              <Button
                size="small"
                sx={{ ml: 'auto' }}
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowNearbyProtocolsList(!showNearbyProtocolsList);
                }}
              >
                {showNearbyProtocolsList
                  ? context.i18n.hide || 'Hide'
                  : context.i18n.show || 'Show'}
              </Button>
            </Box>
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ alignSelf: 'flex-start', mt: 0.5, fontStyle: 'italic' }}
            >
              {context.i18n.protocolLinkingNote ||
                'Note: Linking does not change the tracked time'}
            </Typography>
          </Box>

          <Collapse in={showNearbyProtocolsList}>
            <List sx={{ mt: 1 }}>
              {nearbyProtocols.map((protocol) => (
                <ListItem
                  key={protocol.id}
                  secondaryAction={
                    <Button
                      size="small"
                      variant="outlined"
                      color="primary"
                      onClick={() => handleLinkProtocol(protocol.id)}
                    >
                      {context.i18n.link || 'Link'}
                    </Button>
                  }
                >
                  <ListItemText
                    primary={`${
                      protocol.startDate
                        ? moment(protocol.startDate).format('DD.MM.YYYY HH:mm')
                        : ''
                    } - ${
                      protocol.endDate
                        ? moment(protocol.endDate).format('HH:mm')
                        : ''
                    }`}
                    secondary={`${protocol.place || ''} - ${
                      protocol.personsPresent || ''
                    }`}
                  />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </Box>
      )}

      <DialogContent>
        {loading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <ProtocolFields
            disabled={
              (props.protocol?.inReview && !props.adminView) ||
              (props.released && !props.adminView)
            }
            save={save}
            onDisableSave={setDisableSave}
            submitOnSave={submitOnSave}
            {...props}
          />
        )}
      </DialogContent>
      <DialogActions>
        {showReleaseButton &&
          (props.submitOnSaveEnabled || props.onRelease) &&
          !props.protocol?.isReleased && (
            <Tooltip
              title={
                props.submitOnSaveEnabled
                  ? context.i18n.saveAndSubmitBtnTooltip
                  : context.i18n.saveAndReleaseBtnTooltip
              }
            >
              <>
                <IconButton
                  onClick={() => {
                    if (props.submitOnSaveEnabled) {
                      setSubmitOnSave(true);
                    } else {
                      setSave(true);
                      if (props.onRelease) props.onRelease();
                    }
                  }}
                  disableRipple
                  disabled={
                    (props.protocol?.inReview && !props.adminView) ||
                    (props.protocol?.isReleased && !props.adminView) ||
                    disableSave ||
                    loading
                  }
                  color="primary"
                >
                  <FontAwesomeIcon icon={faFileExport} />
                </IconButton>
              </>
            </Tooltip>
          )}
        <Tooltip title={context.i18n.saveBtnTooltip}>
          <>
            <IconButton
              onClick={() => {
                setSave(true);
              }}
              disableRipple
              disabled={
                (props.protocol?.inReview && !props.adminView) ||
                (props.protocol?.isReleased && !props.adminView) ||
                disableSave ||
                loading
              }
              color="primary"
            >
              <FontAwesomeIcon icon={faFloppyDisk} />
            </IconButton>
          </>
        </Tooltip>
        <Tooltip title={context.i18n.close}>
          <IconButton onClick={props.onClose} disableRipple>
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default EditProtocolDialog;
