import { FC, useContext } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { AppContext } from '../hooks/context';
import { getCookie } from '../utils';
import { authCookieName } from '../utils/constants';
import NoFeaturesWarning from '../components/core/no-features-warning';

const AuthenticatedRoute: FC = () => {
  const context = useContext(AppContext);
  const location = useLocation();
  const isAuthenticated = getCookie(authCookieName)
    ? context.isAuthenticated
    : false;

  // CaseCare admins should only access the CaseCare admin routes
  if (isAuthenticated && context.isCaseCareStaff) {
    // If not already on a casecare-admin route, redirect there
    if (!location.pathname.startsWith('/casecare-admin')) {
      return <Navigate to="/casecare-admin/organization-features" />;
    }
  }

  // If authenticated but has no active features, show the warning
  // There's no bypass - even admins and superadmins of an organization
  // need to have features activated by the service provider
  if (
    isAuthenticated &&
    !context.hasActiveFeatures &&
    !context.isCaseCareStaff
  ) {
    return <NoFeaturesWarning />;
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default AuthenticatedRoute;
