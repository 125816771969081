import { ReactElement, useContext, useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { HistoryBrowserRouter } from '../utils';
import { appHistory, isLocalhost } from '../utils/constants';
import {
  ClientDetails,
  ClientList,
  FormEdit,
  FormList,
  CreateOrg,
  Links,
  Login,
  ResetPassword,
  FamilyList,
  FamilyDetails,
  Abo,
  OrganizationData,
  AvatarDetails,
  AvatarEditor,
  EcoMapDetails,
  MobileClientList,
  MobileProtocols,
  PlanningDashboard,
  Billing,
  GenoGramm,
  AdminBilling,
  Contacts,
  Products,
  TeamDashboard,
} from '../views';
import ClientArchive from '../views/admin/client-archive/client-archive';
import UserList from '../views/admin/user-list/user-list';
import AdminRoute from './admin-route';
import AuthenticatedRoute from './authenticated-route';
import UnauthenticatedRoute from './unauthenticated-route';
import MobileRoute from './mobile-root';
import NonMobileRoute from './non-mobile-route';
import CaseCareAdminRoute from './casecare-admin-route';
import BillingRoute from './billing-route';
import AdminBillingRoute from './admin-billing-route';
import TodoTeamRoute from './todo-team-route';
import EcoMapPrint from '../views/eco-map/eco-map-print/eco-map-print';
import EcoMapCompare from '../views/eco-map/eco-map-compare/eco-map-compare';
import HelpForm from '../views/admin/help-form/help-form';
import { UserGroups } from '../views/admin/user-groups/user-groups';
import { AppContext } from '../hooks/context';
import { Box } from '@mui/material';
import AddMobileProtocol from '../views/mobile/add-protocol/add-protocol';
import HelpFormOrderer from '../views/admin/help-form-orderer/help-form-orderer';
import StorageList from '../views/storage/storage-list/storage-list';
import { BillingType } from '../types/billing-type';
import markerSDK, { MarkerSdk } from '@marker.io/browser';
import AdminOrganizationMessages from '../views/admin/organization-messages/admin-organization-messages';
import OrgMessageDialog from '../components/core/org-message-dialog.tsx/org-message-dialog';
import TimeTracking from '../views/time-tracking/time-tracking';
import TimeTrackingCategory from '../views/admin/time-tracking-category/time-tracking-category';
import OrganizationMessages from '../views/organization-messages/organization-messages';
import MobileTimeTrackingList from '../views/mobile/time-tracking-list/mobile-time-tracking-list';
import TimeTrackingSummaryView from '../views/admin/time-tracking-summary/time-tracking-summary';
// We'll import the organization feature management view once we create it
import OrganizationFeatureManager from '../views/casecare-admin/organization-features/organization-features';

const App: React.FC = (): ReactElement => {
  const { user, backendVersion, isCaseCareStaff } = useContext(AppContext);
  const baseUrl = isLocalhost ? 'localhost' : 'CC_ENV_BASE_URL';
  const [widget, setWidget] = useState<MarkerSdk | undefined>();

  // If user is a CaseCare admin, redirect them to the admin dashboard
  useEffect(() => {
    if (user && isCaseCareStaff) {
      // Use window.location.pathname instead of location hook
      const currentPath = window.location.pathname;
      if (!currentPath.startsWith('/casecare-admin')) {
        window.location.href = '/casecare-admin/organization-features';
      }
    }
  }, [user, isCaseCareStaff]);

  useEffect(() => {
    if (user) {
      const orgHostname = `${user.subdomain}.${baseUrl}`;
      if (!isLocalhost) {
        if (window.location.hostname !== orgHostname) {
          window.location.replace(
            `${window.location.protocol}//${orgHostname}${window.location.pathname}`,
          );
        }
      }
    }
  }, [user]);

  useEffect(() => {
    if (process.env.REACT_APP_BETA_TEST_BANNER && !widget) {
      const loadWidget = async () => {
        const w = await markerSDK.loadWidget({
          project: '66f50ad3e06e514563f0e825',
        });
        setWidget(w);
      };
      loadWidget();
    }
  }, [widget]);

  useEffect(() => {
    if (widget) {
      widget.setReporter({
        email: user?.email || '',
        fullName: `${user?.firstName} ${user?.lastName}`,
      });
      widget.setCustomData({
        version: `Frontend: ${process.env.REACT_APP_VERSION}, Backend: ${backendVersion}`,
      });
    }
  }, [widget]);

  return (
    <span>
      <HistoryBrowserRouter history={appHistory}>
        <Box
          sx={{ pb: process.env.REACT_APP_BETA_TEST_BANNER ? '40px' : '0px' }}
        >
          <Routes>
            <Route path="*" element={<AuthenticatedRoute />}>
              <Route path="mobile" element={<MobileRoute />}>
                <Route path="clients" element={<MobileClientList />} />
                <Route
                  path="time-tracking"
                  element={<MobileTimeTrackingList />}
                />
                <Route
                  path="protocols/:id/:isFamily"
                  element={<MobileProtocols />}
                />
                <Route
                  path="protocols/a/:id/:isFamily"
                  element={<AddMobileProtocol />}
                />
                <Route path="protocols/a/:id" element={<AddMobileProtocol />} />
                <Route path="protocols/:id" element={<MobileProtocols />} />
                <Route path="*" element={<Navigate to="clients" />} />
              </Route>
              <Route path="*" element={<NonMobileRoute />}>
                <Route path="todo" element={<PlanningDashboard />} />
                <Route path="todo-team" element={<TodoTeamRoute />}>
                  <Route path="" element={<TeamDashboard />} />
                </Route>
                <Route path="time-tracking" element={<TimeTracking />} />
                <Route path="clients">
                  <Route path=":clientId/:tab" element={<ClientDetails />} />
                  <Route
                    path=":clientId/:tab/:subtab"
                    element={<ClientDetails />}
                  />
                  <Route
                    path="referencePerson/:clientId/:tab"
                    element={<ClientDetails isReferencePerson />}
                  />
                  <Route path="*" element={<ClientList />} />
                </Route>
                <Route path="families">
                  <Route path=":familyId/:tab" element={<FamilyDetails />} />
                  <Route
                    path=":familyId/:tab/:subtab"
                    element={<FamilyDetails />}
                  />
                  <Route path="*" element={<Navigate to="../../clients" />} />
                </Route>
                <Route path="geno-gramm/:clientId">
                  <Route path="*" element={<GenoGramm />} />
                </Route>
                <Route path="avatar">
                  <Route path="details">
                    <Route
                      path=":indexId/:clientId"
                      element={<AvatarDetails />}
                    />
                    <Route path=":indexId" element={<AvatarDetails />} />
                  </Route>
                  <Route
                    path="editor/:indexId/:clientId"
                    element={<AvatarEditor />}
                  />
                </Route>
                <Route path="eco-map">
                  <Route
                    path="details/:clientId/:indexId/"
                    element={<EcoMapDetails />}
                  />
                  <Route
                    path="print/:clientId/:indexId/"
                    element={<EcoMapPrint />}
                  />
                  <Route
                    path="compare/:clientId/"
                    element={<EcoMapCompare />}
                  />
                </Route>

                <Route path="billing" element={<BillingRoute />}>
                  <Route
                    path="review"
                    element={<Billing type={BillingType.REVIEW} />}
                  />
                  <Route
                    path="released"
                    element={<Billing type={BillingType.RELEASED} />}
                  />
                  <Route
                    path="rejected"
                    element={<Billing type={BillingType.REJECTED} />}
                  />
                  <Route path="*" element={<Navigate to="review" />} />
                </Route>

                <Route path="admin" element={<AdminRoute />}>
                  <Route path="users" element={<UserList />} />
                  <Route
                    path="organization-messages"
                    element={<AdminOrganizationMessages />}
                  />
                  <Route path="categories">
                    <Route
                      path=":categoryId/:subCategoryId"
                      element={<FormEdit />}
                    />
                    <Route path="*" element={<FormList />} />
                  </Route>
                  <Route path="help-form" element={<HelpForm />} />
                  <Route
                    path="help-form/orderer"
                    element={<HelpFormOrderer />}
                  />
                  <Route path="time-tracking-categories">
                    <Route
                      path=":categoryId"
                      element={<TimeTrackingCategory />}
                    />
                    <Route path="*" element={<TimeTrackingCategory />} />
                  </Route>
                  <Route
                    path="time-tracking-summary"
                    element={<TimeTrackingSummaryView />}
                  />
                  <Route path="groups" element={<UserGroups />} />
                  <Route path="archive" element={<ClientArchive />} />
                  <Route path="billing" element={<AdminBillingRoute />}>
                    <Route path="contacts" element={<Contacts />} />
                    <Route path="products" element={<Products />} />
                    <Route path="*" element={<AdminBilling />} />
                  </Route>
                  <Route path="abo" element={<Abo />} />
                  <Route path="data" element={<OrganizationData />} />

                  <Route path="*" element={<Navigate to="users" />} />
                </Route>

                <Route path="storage">
                  <Route
                    path=":storageItemFolderId"
                    element={<StorageList />}
                  />
                  <Route
                    path=":storageItemIndexId/:storageItemFolderId"
                    element={<StorageList />}
                  />
                  <Route path="*" element={<Navigate to="../../clients" />} />
                </Route>
                <Route
                  path="organization-messages"
                  element={<OrganizationMessages />}
                />
                <Route path="*" element={<Navigate to="clients" />} />
              </Route>

              {/* CaseCare Admin Routes - only accessible to CaseCare staff members */}
              <Route path="casecare-admin" element={<CaseCareAdminRoute />}>
                <Route
                  path="organization-features"
                  element={<OrganizationFeatureManager />}
                />
                <Route
                  path="*"
                  element={<Navigate to="organization-features" />}
                />
              </Route>

              <Route path="*" element={<Navigate to="clients" />} />
            </Route>
            <Route path="*" element={<UnauthenticatedRoute />}>
              <Route path="createOrg" element={<CreateOrg />} />
              <Route path="link/:type/:id" element={<Links />} />
              <Route path="login" element={<Login />} />
              <Route path="login/expired" element={<Login expired />} />
              <Route path="resetPassword" element={<ResetPassword />} />
              <Route path="*" element={<Navigate to="login" />} />
            </Route>
          </Routes>
        </Box>
        <OrgMessageDialog />
      </HistoryBrowserRouter>
    </span>
  );
};

export default App;
