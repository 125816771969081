import {
  faFileExport,
  faFloppyDisk,
  faX,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  IconButton,
  Box,
} from '@mui/material';
import { FC, useContext, useState, useEffect } from 'react';
import { AppContext } from '../../../hooks/context';
import { ProtocolFields } from '../../../components/client/documentation';
import { useNavigate, useParams } from 'react-router-dom';

const AddMobileProtocol: FC = (props) => {
  const context = useContext(AppContext);
  const { id, isFamily } = useParams();

  const [save, setSave] = useState(false);
  const [submitOnSave, setSubmitOnSave] = useState(false);
  const [disableSave, setDisableSave] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (save) {
      setSave(false);
    }
  }, [save]);

  useEffect(() => {
    if (submitOnSave) {
      setSubmitOnSave(false);
    }
  }, [submitOnSave]);

  const goToProtocols = () => {
    isFamily
      ? navigate('/mobile/protocols/' + id + '/family')
      : navigate('/mobile/protocols/' + id);
  };

  return (
    <Box>
      <DialogTitle>{context.i18n.addProtocol}</DialogTitle>
      <DialogContent>
        <ProtocolFields
          save={save}
          open={true}
          onDisableSave={setDisableSave}
          familyId={isFamily ? id : undefined}
          clientId={!isFamily ? id : undefined}
          onSave={goToProtocols}
          submitOnSave={submitOnSave}
          {...props}
        />
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.saveAndSubmitBtnTooltip}>
          <>
            <IconButton
              onClick={() => setSubmitOnSave(true)}
              disableRipple
              disabled={disableSave}
              color="primary"
            >
              <FontAwesomeIcon icon={faFileExport} />
            </IconButton>
          </>
        </Tooltip>
        <Tooltip title={context.i18n.saveBtnTooltip}>
          <>
            <IconButton
              onClick={() => setSave(true)}
              disableRipple
              disabled={disableSave}
              color="primary"
            >
              <FontAwesomeIcon icon={faFloppyDisk} />
            </IconButton>
          </>
        </Tooltip>
        <Tooltip title={context.i18n.close}>
          <IconButton disableRipple onClick={goToProtocols}>
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Box>
  );
};

export default AddMobileProtocol;
