import { FrontendFormImportField } from '@casecare/types';
import {
  Box,
  CircularProgress,
  Dialog,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { AxiosError } from 'axios';
import { FC, useContext, useState, useEffect } from 'react';
import { useQueryClient, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { MenuWrapper } from '../../../components/core';
import { AppContext } from '../../../hooks/context';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { loadTitle, setDocumentTitle } from '../../../utils';
import { AdminApi } from '../../../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faPlus } from '@fortawesome/free-solid-svg-icons';
import TimeTrackingCategoryList from '../../../components/admin/time-tracking-category/time-tracking-category-list';

const TimeTrackingCategory: FC = () => {
  const context = useContext(AppContext);

  // Set document title
  useEffect(() => {
    document.title = context.i18n.siteTitleAdminTimeTrackingCategories;
  }, [context.i18n]);

  return (
    <MenuWrapper
      title={context.i18n.siteTitleAdminTimeTrackingCategories}
      search
      addClient
      selected="admin-time-tracking-categories"
      admin
    >
      <TimeTrackingCategoryList />
    </MenuWrapper>
  );
};

export default TimeTrackingCategory;
