import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { FC, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { OrgApi } from '../../../api';
import Navbar from '../../../components/core/navbar/navbar';
import LoginInput from '../../../components/public/login-input/login-input';
import { AppContext } from '../../../hooks/context';
import { setDocumentTitle, loadTitle } from '../../../utils';
import {
  LoginBackgroundImage,
  LoginBackgroundWave,
} from '../../../utils/background/background';

const CreateOrg: FC = () => {
  const context = useContext(AppContext);
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [orgName, setOrgName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [loading, setLoading] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  useEffect(() => {
    setDocumentTitle(context.i18n.createOrg);
    return () => loadTitle();
  }, []);

  const createOrg = async () => {
    setLoading(true);
    try {
      await OrgApi.create({
        email,
        firstName,
        lastName,
        orgName,
        password,
      });

      setShowSuccessDialog(true);
    } catch (e: any) {
      setLoading(false);
      console.error(e.response);
    }
  };

  const handleCloseSuccessDialog = () => {
    setShowSuccessDialog(false);
    navigate('/login');
  };

  return (
    <Box sx={LoginBackgroundWave}>
      <Navbar hideMenuDrawer title={context.i18n.createOrg} />
      <Box sx={LoginBackgroundImage}>
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LoginInput
            onChange={setEmail.bind(this)}
            label={context.i18n.email}
          />
          <LoginInput
            onChange={setFirstName.bind(this)}
            label={context.i18n.firstName}
          />
          <LoginInput
            onChange={setLastName.bind(this)}
            label={context.i18n.lastName}
          />
          <LoginInput
            onChange={setOrgName.bind(this)}
            label={context.i18n.orgName}
          />
          <LoginInput
            label={context.i18n.inputPassword}
            onChange={setPassword.bind(this)}
            password
            showPasswordCheck
          />

          <LoginInput
            error={
              passwordConfirm && passwordConfirm !== password ? true : false
            }
            helperText={
              passwordConfirm && passwordConfirm !== password
                ? context.i18n.passwordConfirmHelper
                : ''
            }
            label={context.i18n.inputPasswordConfirm}
            onChange={setPasswordConfirm.bind(this)}
            password
          />
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            sx={{ mt: 2 }}
          >
            <Button
              color="primary"
              variant="contained"
              id="loginBtn"
              onClick={createOrg}
              className="formBtn"
              disabled={
                email === '' ||
                firstName === '' ||
                lastName === '' ||
                orgName === '' ||
                password === '' ||
                password !== passwordConfirm
              }
            >
              {loading ? (
                <CircularProgress sx={{ color: 'white' }} />
              ) : (
                context.i18n.createOrg
              )}
            </Button>
            <a
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter') navigate('/login');
              }}
              onClick={() => navigate('/login')}
            >
              <Typography
                variant="subtitle1"
                className="clickable"
                sx={{ fontSize: '0.9rem', textAlign: 'center', mt: 1 }}
              >
                {context.i18n.login}
              </Typography>
            </a>
          </Box>
        </Box>
      </Box>

      <Dialog open={showSuccessDialog} onClose={handleCloseSuccessDialog}>
        <DialogTitle>{context.i18n.orgCreatedSuccessTitle}</DialogTitle>
        <DialogContent>
          <Alert severity="success" sx={{ mb: 2 }}>
            {context.i18n.orgCreatedSuccessMessage}
          </Alert>
          <Typography variant="body1">
            {context.i18n.orgCreatedPendingActivation}
          </Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>
            {context.i18n.orgCreatedEmailConfirmation}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseSuccessDialog}
            variant="contained"
            color="primary"
          >
            {context.i18n.goToLogin}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CreateOrg;
