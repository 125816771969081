import { faFloppyDisk, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../hooks/context';
import snackbarUtils from '../../../../utils/snackbar/snackbar-utils';
import { TimeTrackingEntry } from '../../../../types/time-tracking-entry';
import moment from 'moment';
import { TimeTrackingStatus } from '../../../../types/time-tracking-status';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { TimeTrackingCategory } from '../../../../types/time-tracking-category';
import { TimeTrackingApi, ProtocolApi } from '../../../../api';
import { useQuery } from 'react-query';
import { TimeTrackingCategoryMethod } from '../../../../types/time-tracking-category-method';

interface TimeTrackingEntryDialogProps {
  open: boolean;
  onClose: () => void;
  timeTrackingEntry?: TimeTrackingEntry;
  timeTrackingCategories: TimeTrackingCategory[];
  clientFamilyList: any[];
  userId?: string;
  entrySource?: string;
  onProtocolEdit?: (entry: TimeTrackingEntry) => void;
  fullScreen?: boolean;
}

const TimeTrackingEntryDialog: FC<TimeTrackingEntryDialogProps> = (props) => {
  const context = useContext(AppContext);
  // Set default start time to current time, and end time to current time + 1 hour
  const currentTime = moment();
  const oneHourLater = moment().add(1, 'hour');

  const [defaultValue, setDefaultValue] = useState<TimeTrackingEntry>({
    status: TimeTrackingStatus.OPEN,
    start: currentTime,
    end: oneHourLater,
    categoryId: '',
  });
  const [timeTrackingEntry, setTimeTrackingEntry] = useState<TimeTrackingEntry>(
    { ...defaultValue },
  );
  const [timeTrackingCategories, setTimeTrackingCategories] = useState<
    TimeTrackingCategory[]
  >([]);
  const [clientFamilyList, setClientFamilyList] = useState<any[]>([]);

  // State for unlinked protocols
  const [unlinkedProtocols, setUnlinkedProtocols] = useState<any[]>([]);
  const [selectedProtocol, setSelectedProtocol] = useState<any>(null);
  const [showProtocolSelector, setShowProtocolSelector] =
    useState<boolean>(false);

  // Validation states
  const [errors, setErrors] = useState<{
    categoryId?: string;
    clientFamily?: string;
    startTime?: string;
    endTime?: string;
  }>({});

  useEffect(() => {
    // Reset errors when dialog opens
    setErrors({});

    if (props.timeTrackingEntry) {
      // Ensure categoryId is set when editing an existing entry
      const entry = {
        ...props.timeTrackingEntry,
        categoryId:
          props.timeTrackingEntry.category?.id ||
          props.timeTrackingEntry.categoryId ||
          '',
      };
      setTimeTrackingEntry(entry);
    } else {
      // Reset to defaults when opening the dialog for a new entry
      setTimeTrackingEntry({
        status: TimeTrackingStatus.OPEN,
        start: moment(),
        end: moment().add(1, 'hour'),
        categoryId: '',
      });
    }
  }, [props.timeTrackingEntry, props.open]);

  useEffect(() => {
    if (props.userId) {
      setDefaultValue({ ...defaultValue, userId: props.userId });
      setTimeTrackingEntry({ ...timeTrackingEntry, userId: props.userId });
    }
  }, [props.userId]);

  useEffect(() => {
    if (props.timeTrackingCategories)
      setTimeTrackingCategories(props.timeTrackingCategories);
  }, [props.timeTrackingCategories]);

  useEffect(() => {
    if (props.clientFamilyList) setClientFamilyList(props.clientFamilyList);
  }, [props.clientFamilyList]);

  // Reset related errors when fields change
  useEffect(() => {
    const newErrors = { ...errors };

    // Clear date/time errors when values change
    if (newErrors.startTime || newErrors.endTime) {
      delete newErrors.startTime;
      delete newErrors.endTime;
      setErrors(newErrors);
    }
  }, [timeTrackingEntry.start, timeTrackingEntry.end]);

  const onClose = async () => {
    props.onClose();
  };

  const validateForm = () => {
    const newErrors: {
      categoryId?: string;
      clientFamily?: string;
      startTime?: string;
      endTime?: string;
    } = {};

    // Reset errors
    setErrors({});

    // Validate category (always required)
    if (!timeTrackingEntry.categoryId) {
      newErrors.categoryId =
        context.i18n.timeTrackingCategoryRequired || 'Category is required';
    }

    // Validate client/family if the category requires it
    if (
      timeTrackingEntry.category?.isClientOrFamilyNeeded &&
      !timeTrackingEntry.clientId &&
      !timeTrackingEntry.familyId
    ) {
      newErrors.clientFamily =
        context.i18n.timeTrackingClientFamilyRequired ||
        'Client or family is required';
    }

    // Validate start and end times
    if (!timeTrackingEntry.start) {
      newErrors.startTime =
        context.i18n.timeTrackingStartRequired || 'Start time is required';
    }

    // Check if end time is after start time (only if end time is provided)
    if (
      timeTrackingEntry.end &&
      moment(timeTrackingEntry.start).isAfter(moment(timeTrackingEntry.end))
    ) {
      newErrors.endTime =
        context.i18n.timeTrackingEndBeforeStart ||
        'End time cannot be before start time';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onSave = async () => {
    try {
      // Validate form before submitting
      if (!validateForm()) {
        return;
      }

      let res = null;

      // When editing an existing entry, don't send status in the payload
      // Status changes are handled separately through the status dropdown in the table
      if (timeTrackingEntry.id) {
        // Create a copy of the entry without the status property
        const { status, ...entryWithoutStatus } = timeTrackingEntry;

        // If a user is editing their own rejected entry, change the status to OPEN
        // Only applies for non-admin users
        const entryToSend = {
          ...entryWithoutStatus,
          entrySource: 'ADMIN',
          ...(!context.isAdmin && timeTrackingEntry.status === 'REJECTED'
            ? { status: 'OPEN' }
            : {}),
        };

        res = await TimeTrackingApi.editEntry(
          context.authToken,
          timeTrackingEntry.id,
          entryToSend,
        );
      } else {
        // If a protocol was selected from the unlinked protocols list, include its ID
        // Make sure status is OPEN for new entries
        const entryToCreate = {
          ...timeTrackingEntry,
          status: TimeTrackingStatus.OPEN,
          protocolId: selectedProtocol
            ? selectedProtocol.id
            : timeTrackingEntry.protocolId,
        };

        res = await TimeTrackingApi.createEntry(
          context.authToken,
          entryToCreate,
        );
      }

      if (res.message) {
        snackbarUtils.success(res.message);

        // Check if we need to open the protocol creation flow
        if (
          timeTrackingEntry.category?.method ===
            TimeTrackingCategoryMethod.PROTOCOL &&
          (timeTrackingEntry.clientId || timeTrackingEntry.familyId)
        ) {
          // Close this dialog regardless of next steps
          onClose();

          // Now handle the protocol flow with the created/updated entry
          if (props.onProtocolEdit && res.data) {
            // Protocol flow will be handled by the parent component
            props.onProtocolEdit(res.data);
          }
        } else {
          setTimeTrackingEntry(defaultValue);
          onClose();
        }
      }
    } catch (e) {
      console.error(e);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  return (
    <Dialog
      fullWidth
      open={props.open}
      onClose={onClose}
      fullScreen={props.fullScreen}
    >
      <DialogTitle>
        {props.timeTrackingEntry
          ? context.i18n.timeTrackingEntryEdit
          : context.i18n.timeTrackingEntryCreate}
      </DialogTitle>
      <DialogContent>
        <Grid sx={{ mt: 1 }} container spacing={2}>
          <Grid item md={6} xs={12}>
            <DatePicker
              format="DD.MM.YYYY"
              views={['day', 'year', 'month']}
              label={context.i18n.timeTrackingDate}
              value={moment(timeTrackingEntry.start)}
              onChange={(value: any) =>
                setTimeTrackingEntry({
                  ...timeTrackingEntry,
                  start: value,
                })
              }
              slotProps={{
                textField: {
                  fullWidth: true,
                  error: !!errors.startTime,
                  helperText: errors.startTime,
                },
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TimePicker
              format="HH:mm"
              views={['hours', 'minutes']}
              label={context.i18n.timeTrackingStart}
              value={moment(timeTrackingEntry.start)}
              onChange={(value: any) =>
                setTimeTrackingEntry({
                  ...timeTrackingEntry,
                  start: value,
                })
              }
              ampm={false}
              slotProps={{
                textField: {
                  fullWidth: true,
                  error: !!errors.startTime,
                  helperText: errors.startTime,
                },
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <DatePicker
              format="DD.MM.YYYY"
              views={['day', 'year', 'month']}
              label={context.i18n.timeTrackingEndDate}
              value={
                timeTrackingEntry.end ? moment(timeTrackingEntry.end) : null
              }
              onChange={(value: any) =>
                setTimeTrackingEntry({
                  ...timeTrackingEntry,
                  end: value,
                })
              }
              slotProps={{
                textField: {
                  fullWidth: true,
                  error: !!errors.endTime,
                  helperText: errors.endTime,
                },
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TimePicker
              format="HH:mm"
              views={['hours', 'minutes']}
              label={context.i18n.timeTrackingEnd}
              value={
                timeTrackingEntry.end ? moment(timeTrackingEntry.end) : null
              }
              onChange={(value: any) =>
                setTimeTrackingEntry({
                  ...timeTrackingEntry,
                  end: value,
                })
              }
              ampm={false}
              slotProps={{
                textField: {
                  fullWidth: true,
                  error: !!errors.endTime,
                  helperText: errors.endTime,
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disabled={
                timeTrackingEntry.category &&
                timeTrackingEntry.category.deletionDate
                  ? true
                  : false
              }
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={context.i18n.timeTrackingCategory}
                  error={!!errors.categoryId}
                  helperText={errors.categoryId}
                  required
                />
              )}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => {
                if (value.id) return option.id == value.id;
                else return false;
              }}
              options={timeTrackingCategories}
              groupBy={(option: any) => option.parent.name}
              value={timeTrackingEntry.category ?? null}
              onChange={(e, val) => {
                if (val) {
                  // Reset protocol-related state when changing category
                  setSelectedProtocol(null);
                  setShowProtocolSelector(false);

                  setTimeTrackingEntry({
                    ...timeTrackingEntry,
                    category: val,
                    categoryId: val.id ?? '',
                    // Clear protocolId when changing category
                    protocolId: undefined,
                  });

                  // Clear category error when a value is selected
                  setErrors({ ...errors, categoryId: undefined });
                } else {
                  setTimeTrackingEntry({
                    ...timeTrackingEntry,
                    category: undefined,
                    categoryId: '',
                    protocolId: undefined,
                  });
                }
              }}
            />
          </Grid>

          {timeTrackingEntry.category?.isClientOrFamilyNeeded && (
            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      context.i18n.timeTrackingCategoryIsClientOrFamilyNeeded
                    }
                    error={!!errors.clientFamily}
                    helperText={errors.clientFamily}
                    required
                  />
                )}
                groupBy={(option) => {
                  if (option.isFamily) return context.i18n.family;
                  return context.i18n.client;
                }}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => {
                  if (value.id) return option.id == value.id;
                  else return false;
                }}
                onChange={(e, val) => {
                  if (val) {
                    if (val.isFamily)
                      setTimeTrackingEntry({
                        ...timeTrackingEntry,
                        familyId: val.id,
                        family: val,
                        clientId: undefined,
                        client: undefined,
                      });
                    else
                      setTimeTrackingEntry({
                        ...timeTrackingEntry,
                        clientId: val.id,
                        client: val,
                        familyId: undefined,
                        family: undefined,
                      });

                    // Clear client/family error when a value is selected
                    setErrors({ ...errors, clientFamily: undefined });
                  } else {
                    setTimeTrackingEntry({
                      ...timeTrackingEntry,
                      clientId: undefined,
                      client: undefined,
                      familyId: undefined,
                      family: undefined,
                    });
                  }
                }}
                value={
                  timeTrackingEntry.family ?? timeTrackingEntry.client ?? null
                }
                options={clientFamilyList.sort((x) => (x.isFamily ? -1 : 1))}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={context.i18n.timeTrackingNote}
              value={timeTrackingEntry.note}
              onChange={(e) =>
                setTimeTrackingEntry({
                  ...timeTrackingEntry,
                  note: e.target.value,
                })
              }
            />
          </Grid>
        </Grid>
        {/* Object.entries(Sex).map((sex) => (
                      <MenuItem
                        key={sex[1]}
                        value={sex[1]}
                        onClick={() => {
                          field.setValue(sex[0]);
                          setSexError('');
                        }}
                      >
                        {context.i18n[sex[0]]}
                      </MenuItem>
                    ))} */}
      </DialogContent>
      <DialogActions>
        <Tooltip title={context.i18n.saveBtnTooltip}>
          <span>
            <IconButton onClick={onSave} disableRipple color="primary">
              <FontAwesomeIcon icon={faFloppyDisk} />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={context.i18n.close}>
          <IconButton onClick={onClose} disableRipple>
            <FontAwesomeIcon icon={faX} />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default TimeTrackingEntryDialog;
