import React, { ReactNode } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { theme as baseTheme } from '../../utils/core/misc-utils';

// Create a mobile-specific theme that overrides certain aspects of the base theme
const mobileTheme = createTheme({
  ...baseTheme,
  components: {
    ...baseTheme.components,
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          padding: '8px 16px',
        },
        sizeSmall: {
          fontSize: '0.9rem',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
        input: {
          fontSize: '1rem',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          fontSize: '1rem',
        },
        option: {
          fontSize: '1rem',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          fontSize: '1rem',
        },
        body2: {
          fontSize: '0.9rem',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: '1rem',
        },
      },
    },
  },
});

interface MobileThemeProviderProps {
  children: ReactNode;
}

const MobileThemeProvider: React.FC<MobileThemeProviderProps> = ({
  children,
}) => {
  return <ThemeProvider theme={mobileTheme}>{children}</ThemeProvider>;
};

export default MobileThemeProvider;
