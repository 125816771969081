import { FC, useContext, useEffect, useState, useCallback } from 'react';
import {
  Box,
  Button,
  IconButton,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Checkbox,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Tooltip,
} from '@mui/material';
import { AppContext } from '../../../hooks/context';
import { TimeTrackingApi } from '../../../api';
import { TimeTrackingEntry } from '../../../types/time-tracking-entry';
import { TimeTrackingStatus } from '../../../types/time-tracking-status';
import { parseDurationNumber } from '../../../utils/date/date';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faCheck,
  faEdit,
  faExclamationTriangle,
  faFileExport,
  faPen,
  faTimes,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import {
  DateRange,
  DateRangePicker,
  SingleInputDateRangeField,
} from '@mui/x-date-pickers-pro';
import moment, { Moment } from 'moment';
import TimeTrackingEntryDialog from '../../time-tracking/time-tracking-list/time-tracking-entry-dialog/time-tracking-entry-dialog';
import DeleteTimeTrackingEntryDialog from '../../time-tracking/time-tracking-list/time-tracking-entry-dialog/delete-time-tracking-entry-dialog';
import RejectTimeTrackingEntryDialog from '../../time-tracking/time-tracking-list/time-tracking-entry-dialog/reject-time-tracking-entry-dialog';

interface TimeTrackingUserEntriesProps {
  userId: string;
  userName: string;
  dateRange: DateRange<Moment>;
  statusFilter: TimeTrackingStatus[];
  onBackToSummary: () => void;
  onDateRangeChange: (dateRange: DateRange<Moment>) => void;
}

const TimeTrackingUserEntries: FC<TimeTrackingUserEntriesProps> = ({
  userId,
  userName,
  dateRange,
  statusFilter,
  onBackToSummary,
  onDateRangeChange,
}) => {
  const context = useContext(AppContext);
  const [entries, setEntries] = useState<TimeTrackingEntry[]>([]);
  const [totalDuration, setTotalDuration] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [selectedEntries, setSelectedEntries] = useState<string[]>([]);
  const [newStatus, setNewStatus] = useState<TimeTrackingStatus>(
    TimeTrackingStatus.COMPLETED,
  );
  const [statusUpdateLoading, setStatusUpdateLoading] =
    useState<boolean>(false);
  const [exportLoading, setExportLoading] = useState<boolean>(false);

  // State for dialogs
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [rejectDialogOpen, setRejectDialogOpen] = useState<boolean>(false);
  const [selectedEntry, setSelectedEntry] = useState<
    TimeTrackingEntry | undefined
  >(undefined);
  const [selectedEntriesForRejection, setSelectedEntriesForRejection] =
    useState<TimeTrackingEntry[] | undefined>(undefined);
  const [timeTrackingCategories, setTimeTrackingCategories] = useState<any[]>(
    [],
  );
  const [clientFamilyList, setClientFamilyList] = useState<any[]>([]);

  // Function to fetch user entries - memoized to avoid infinite loops
  const fetchUserEntries = useCallback(async () => {
    // Only show loading for the table, not the whole page
    setTableLoading(true);
    try {
      // Convert date range to UTC format for the API
      let startDate = dateRange[0];
      let endDate = dateRange[1];

      if (startDate) {
        startDate = startDate.clone().startOf('day');
      }

      if (endDate) {
        endDate = endDate.clone().endOf('day');
      }

      const data = {
        userId: userId,
        start: startDate?.utc().format(),
        end: endDate?.utc().format(),
        status: statusFilter.length > 0 ? statusFilter : undefined,
        pageNum: 0,
        pageSize: -1, // Get all entries
      };

      const response = await TimeTrackingApi.getEntryList(
        context.authToken,
        data,
      );

      if (response.data) {
        if (response.data.collection) {
          setEntries(response.data.collection);

          // Set total duration
          if (response.data.overallDuration) {
            setTotalDuration(response.data.overallDuration);
          }
        }
      }
    } catch (error) {
      console.error('Error fetching user entries:', error);
    } finally {
      setTableLoading(false);
    }
  }, [context.authToken, dateRange, statusFilter, userId]);

  // Initial load only
  useEffect(() => {
    if (context.authToken && userId && dateRange[0] && dateRange[1]) {
      // Only show loading animation on first load, not on date range changes
      setIsLoading(true);
      fetchUserEntries().finally(() => setIsLoading(false));

      // Load categories and client/family list for the edit dialog
      const loadCategories = async () => {
        try {
          const response = await TimeTrackingApi.getCategories(
            context.authToken,
          );
          if (response && response.data) {
            setTimeTrackingCategories(response.data);
          }
        } catch (error) {
          console.error('Error loading time tracking categories:', error);
        }
      };

      // TODO: Add client/family list fetch if needed
      // For now, we'll just use an empty array
      // This would normally come from ClientApi and FamilyApi

      loadCategories();
    }
  }, [context.authToken, userId, fetchUserEntries]); // Removed dateRange dependency

  // Handle filter changes without showing full page loading
  useEffect(() => {
    if (
      context.authToken &&
      userId &&
      dateRange[0] &&
      dateRange[1] &&
      !isLoading
    ) {
      // Clear selected entries when filters change
      setSelectedEntries([]);
      fetchUserEntries();
    }
  }, [
    context.authToken,
    userId,
    dateRange,
    statusFilter,
    isLoading,
    fetchUserEntries,
  ]);

  // Handler for bulk status change
  const handleBulkStatusChange = async () => {
    if (selectedEntries.length === 0) return;

    // Check if any of the selected entries are still running
    const selectedEntriesData = entries.filter((entry) =>
      selectedEntries.includes(entry.id as string),
    );
    const unclosedEntries = selectedEntriesData.filter((entry) => !entry.end);

    if (unclosedEntries.length > 0 && newStatus !== TimeTrackingStatus.OPEN) {
      // Show error message - can't change status for entries that are still running
      snackbarUtils.error(
        context.i18n.timeTrackingCannotChangeUnclosedStatus ||
          'Cannot change status of entries with no end time to anything other than OPEN.',
      );
      return;
    }

    // If status is REJECTED, open the rejection dialog
    if (newStatus === TimeTrackingStatus.REJECTED) {
      setSelectedEntriesForRejection(selectedEntriesData);
      setRejectDialogOpen(true);
      return;
    }

    setStatusUpdateLoading(true);
    try {
      const response = await TimeTrackingApi.entryMultiStatusChange(
        context.authToken,
        {
          ids: selectedEntries,
          status: newStatus,
        },
      );

      if (response) {
        // Refresh the data
        fetchUserEntries();
        // Clear selections
        setSelectedEntries([]);
      }
    } catch (error) {
      console.error('Error updating statuses:', error);
    } finally {
      setStatusUpdateLoading(false);
    }
  };

  // Handler for selecting/deselecting all entries
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      // Select all entries that can be edited (based on permissions)
      const selectableEntryIds = entries
        .filter((entry) => entry.id)
        .map((entry) => entry.id as string);
      setSelectedEntries(selectableEntryIds);
    } else {
      // Deselect all
      setSelectedEntries([]);
    }
  };

  // Handler for individual entry selection
  const handleSelectEntry = (entryId: string, checked: boolean) => {
    if (checked) {
      setSelectedEntries((prev) => [...prev, entryId]);
    } else {
      setSelectedEntries((prev) => prev.filter((id) => id !== entryId));
    }
  };

  // Handler for single entry status change
  const handleSingleStatusChange = async (
    entryId: string,
    newStatus: TimeTrackingStatus,
  ) => {
    // Find the entry
    const entry = entries.find((e) => e.id === entryId);

    // Check if entry is still running
    if (entry && !entry.end && newStatus !== TimeTrackingStatus.OPEN) {
      snackbarUtils.error(
        context.i18n.timeTrackingCannotChangeUnclosedStatus ||
          'Cannot change status of entries with no end time to anything other than OPEN.',
      );
      return;
    }

    // If changing to REJECTED status, open the rejection dialog
    if (newStatus === TimeTrackingStatus.REJECTED && entry) {
      setSelectedEntriesForRejection([entry]);
      setRejectDialogOpen(true);
      return;
    }

    try {
      await TimeTrackingApi.editEntry(context.authToken, entryId, {
        status: newStatus,
      });

      // Refresh the data
      fetchUserEntries();
    } catch (error) {
      console.error('Error updating entry status:', error);
    }
  };

  // Handler for approving a single entry
  const handleApproveEntry = async (entryId: string) => {
    // Find the entry
    const entry = entries.find((e) => e.id === entryId);

    // Can only approve entries that are submitted
    if (entry && entry.status !== 'SUBMITTED') {
      snackbarUtils.error(
        context.i18n.timeTrackingCanOnlyApproveSubmitted ||
          `Can only accept entries that are in ${
            context.i18n['timeTrackingStatus_SUBMITTED'] || 'SUBMITTED'
          } status.`,
      );
      return;
    }

    try {
      await TimeTrackingApi.editEntry(context.authToken, entryId, {
        status: 'COMPLETED',
      });

      snackbarUtils.success(
        context.i18n['time-tracking-completed-single'] ||
          'Entry has been accepted',
      );

      // Refresh the data
      fetchUserEntries();
    } catch (error) {
      console.error('Error approving entry:', error);
      snackbarUtils.error(context.i18n.errorTryAgainLater);
    }
  };

  // Handler for rejecting a single entry
  const handleRejectEntry = async (entryId: string) => {
    // Find the entry
    const entry = entries.find((e) => e.id === entryId);

    // Check if entry exists
    if (!entry) {
      snackbarUtils.error('Entry not found');
      return;
    }

    // Can only reject entries that are submitted
    if (entry.status !== 'SUBMITTED') {
      snackbarUtils.error(
        context.i18n.timeTrackingCanOnlyRejectSubmitted ||
          `Can only reject entries that are in ${
            context.i18n['timeTrackingStatus_SUBMITTED'] || 'SUBMITTED'
          } status.`,
      );
      return;
    }

    // Open the rejection dialog with the selected entry
    setSelectedEntriesForRejection([entry]);
    setRejectDialogOpen(true);
  };

  // Handler for exporting completed entries
  const handleExportCompletedEntries = async () => {
    setExportLoading(true);
    try {
      // Convert date range to UTC format for the API
      let startDate = dateRange[0];
      let endDate = dateRange[1];

      if (startDate) {
        startDate = startDate.clone().startOf('day');
      }

      if (endDate) {
        endDate = endDate.clone().endOf('day');
      }

      const data = {
        userId: userId,
        start: startDate?.utc().format(),
        end: endDate?.utc().format(),
        status: [TimeTrackingStatus.COMPLETED], // Only COMPLETED entries
      };

      const response = await TimeTrackingApi.exportCompletedEntries(
        context.authToken,
        data,
      );

      // Get the filename from the Content-Disposition header if available
      let fileName = 'time-tracking-export.xlsx';

      // Extract filename from Content-Disposition header
      const contentDisposition = response.headers['content-disposition'];
      console.log('Content-Disposition header:', contentDisposition);

      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(
          /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
        );
        if (filenameMatch && filenameMatch[1]) {
          fileName = filenameMatch[1].replace(/['"]/g, '');
          console.log('Extracted filename from header:', fileName);
        }
      }

      // Create a download link for the Excel file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      console.log('Using filename for download:', fileName);

      document.body.appendChild(link);
      link.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error exporting completed entries:', error);
      snackbarUtils.error(
        context.i18n.timeTrackingExportError ||
          'Error exporting completed entries',
      );
    } finally {
      setExportLoading(false);
    }
  };

  // Only show the loading indicator on initial component load
  // For any other loading (like date range changes), we use the table overlay
  if (isLoading && entries.length === 0) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="300px"
      >
        <CircularProgress />
      </Box>
    );
  }

  // Handle closing the edit dialog
  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setSelectedEntry(undefined);
    // Refresh the entries list
    fetchUserEntries();
  };

  // Handle closing the delete dialog
  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setSelectedEntry(undefined);
    // Refresh the entries list
    fetchUserEntries();
  };

  return (
    <Box>
      {/* Edit Dialog */}
      <TimeTrackingEntryDialog
        open={editDialogOpen}
        onClose={handleEditDialogClose}
        timeTrackingEntry={selectedEntry}
        timeTrackingCategories={timeTrackingCategories}
        clientFamilyList={clientFamilyList}
      />

      {/* Delete Dialog */}
      <DeleteTimeTrackingEntryDialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        timeTrackingEntry={selectedEntry}
      />

      {/* Reject Dialog */}
      <RejectTimeTrackingEntryDialog
        open={rejectDialogOpen}
        onClose={() => {
          setRejectDialogOpen(false);
          setSelectedEntriesForRejection(undefined);
        }}
        timeTrackingEntries={selectedEntriesForRejection}
        singleMode={selectedEntriesForRejection?.length === 1}
        onReject={fetchUserEntries}
      />

      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <IconButton onClick={onBackToSummary} sx={{ mr: 2 }}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </IconButton>
        <Typography variant="h5">
          {context.i18n.timeTrackingEntriesFor || 'Time Entries for'}:
          <Box component="span" sx={{ fontWeight: 'bold', ml: 1 }}>
            {userName}
          </Box>
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
        <Button
          variant="outlined"
          onClick={() => {
            const currentStart = moment(dateRange[0]);
            const previousMonth = currentStart.clone().subtract(1, 'month');
            onDateRangeChange([
              previousMonth.clone().startOf('month'),
              previousMonth.clone().endOf('month'),
            ]);
          }}
        >
          {context.i18n.previousMonth || 'Previous Month'}
        </Button>

        <Typography variant="h6">
          {dateRange[0]?.format('MMMM YYYY')}
        </Typography>

        <Button
          variant="outlined"
          onClick={() => {
            const currentStart = moment(dateRange[0]);
            const nextMonth = currentStart.clone().add(1, 'month');
            onDateRangeChange([
              nextMonth.clone().startOf('month'),
              nextMonth.clone().endOf('month'),
            ]);
          }}
          disabled={
            dateRange[0]?.month() === moment().month() &&
            dateRange[0]?.year() === moment().year()
          }
        >
          {context.i18n.nextMonth || 'Next Month'}
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={handleExportCompletedEntries}
          disabled={exportLoading}
          startIcon={
            exportLoading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <FontAwesomeIcon icon={faFileExport} />
            )
          }
          sx={{ ml: 2 }}
        >
          {context.i18n.timeTrackingExportCompleted || 'Export Completed'}
        </Button>
      </Box>

      {/* Bulk Status Change Controls */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
        <FormControl sx={{ minWidth: 150 }}>
          <InputLabel id="new-status-select-label">
            {context.i18n.timeTrackingNewStatus || 'New Status'}
          </InputLabel>
          <Select
            labelId="new-status-select-label"
            value={newStatus}
            label={context.i18n.timeTrackingNewStatus || 'New Status'}
            onChange={(event: SelectChangeEvent) => {
              setNewStatus(event.target.value as TimeTrackingStatus);
            }}
          >
            <MenuItem value={TimeTrackingStatus.OPEN}>
              {context.i18n['timeTrackingStatus_' + TimeTrackingStatus.OPEN]}
            </MenuItem>
            <MenuItem value={TimeTrackingStatus.SUBMITTED}>
              {
                context.i18n[
                  'timeTrackingStatus_' + TimeTrackingStatus.SUBMITTED
                ]
              }
            </MenuItem>
            <MenuItem value={TimeTrackingStatus.COMPLETED}>
              {
                context.i18n[
                  'timeTrackingStatus_' + TimeTrackingStatus.COMPLETED
                ]
              }
            </MenuItem>
            <MenuItem value={TimeTrackingStatus.REJECTED}>
              {
                context.i18n[
                  'timeTrackingStatus_' + TimeTrackingStatus.REJECTED
                ]
              }
            </MenuItem>
          </Select>
        </FormControl>

        <Button
          variant="contained"
          color="primary"
          disabled={selectedEntries.length === 0 || statusUpdateLoading}
          onClick={handleBulkStatusChange}
        >
          {statusUpdateLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            context.i18n.timeTrackingUpdateStatus || 'Update Status'
          )}
          ({selectedEntries.length})
        </Button>
      </Box>

      {/* Table with loading overlay */}
      <Box position="relative">
        {tableLoading && (
          <Box
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
            top={0}
            left={0}
            right={0}
            bottom={0}
            bgcolor="rgba(255, 255, 255, 0.7)"
            zIndex={10}
          >
            <CircularProgress />
          </Box>
        )}

        {entries.length > 0 ? (
          <>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        indeterminate={
                          selectedEntries.length > 0 &&
                          selectedEntries.length <
                            entries.filter((e) => e.id).length
                        }
                        checked={
                          entries.length > 0 &&
                          selectedEntries.length ===
                            entries.filter((e) => e.id).length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell>{context.i18n.timeTrackingDate}</TableCell>
                    <TableCell>{context.i18n.timeTrackingStartEnd}</TableCell>
                    <TableCell>{context.i18n.timeTrackingDuration}</TableCell>
                    <TableCell>
                      {context.i18n.timeTrackingClientCategory}
                    </TableCell>
                    <TableCell>
                      {context.i18n.timeTrackingClientFamily}
                    </TableCell>
                    <TableCell>{context.i18n.timeTrackingStatus}</TableCell>
                    <TableCell>{context.i18n.timeTrackingNote}</TableCell>
                    <TableCell>{context.i18n.actions}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {entries.map((entry) => (
                    <TableRow
                      key={entry.id}
                      sx={{
                        backgroundColor:
                          entry.status === TimeTrackingStatus.REJECTED
                            ? '#FFF8F8' // Using the same light pink background for better accessibility
                            : entry.status === TimeTrackingStatus.SUBMITTED
                            ? 'background.paper' // Changed: now uses standard background for SUBMITTED
                            : entry.status === TimeTrackingStatus.COMPLETED
                            ? 'success.light'
                            : '#f5f5f5', // Changed: now uses a muted color for OPEN status
                        opacity:
                          entry.status === TimeTrackingStatus.COMPLETED
                            ? 0.7
                            : 1, // Changed: only completed entries are dimmed
                        height: entry.isMultiDay ? '76px' : '53px', // Set explicit row height
                      }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedEntries.includes(entry.id as string)}
                          onChange={(e) =>
                            handleSelectEntry(
                              entry.id as string,
                              e.target.checked,
                            )
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {entry.isMultiDay ? (
                            <Box sx={{ lineHeight: 1.2 }}>
                              <Typography
                                variant="body2"
                                fontWeight="bold"
                                sx={{ mb: 0.5 }}
                              >
                                {moment.utc(entry.start).format('DD.MM.YYYY')}
                                {' - '}
                                {entry.endDate ||
                                  moment.utc(entry.end).format('DD.MM.YYYY')}
                              </Typography>
                              <Typography
                                variant="caption"
                                color="text.secondary"
                                display="block"
                              >
                                {entry.daysSpanned
                                  ? `${entry.daysSpanned} ${context.i18n.timeTrackingDays}`
                                  : ''}
                              </Typography>
                            </Box>
                          ) : (
                            moment.utc(entry.start).format('DD.MM.YYYY')
                          )}

                          {/* Show an indicator for manual entries */}
                          {entry.entrySource === 'MANUAL' && (
                            <Tooltip
                              title={
                                context.i18n.timeTrackingManualEntry ||
                                'Manual entry'
                              }
                            >
                              <Box
                                component="span"
                                sx={{
                                  ml: 1,
                                  color: 'info.main',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <FontAwesomeIcon icon={faPen} size="sm" />
                              </Box>
                            </Tooltip>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <span>
                            {moment.utc(entry.start).format('HH:mm:ss')} -{' '}
                            {entry.end
                              ? moment.utc(entry.end).format('HH:mm:ss')
                              : ''}
                          </span>
                          {entry.isMultiDay && (
                            <Tooltip
                              title={
                                context.i18n.timeTrackingMultiDayWarning ||
                                'This entry spans multiple days'
                              }
                            >
                              <Box
                                component="span"
                                sx={{
                                  ml: 1,
                                  color: 'warning.main',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faExclamationTriangle}
                                  size="sm"
                                />
                              </Box>
                            </Tooltip>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>
                        {entry.isMultiDay && entry.durationFormatted ? (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography color="primary.main" variant="body2">
                              {entry.duration
                                ? parseDurationNumber(entry.duration, true)
                                : entry.durationFormatted.replace(',', 'h ') +
                                  'm 00s'}
                            </Typography>
                          </Box>
                        ) : entry.end && entry.start ? (
                          parseDurationNumber(
                            moment(entry.end).diff(moment(entry.start)),
                            true,
                          )
                        ) : (
                          ''
                        )}
                      </TableCell>
                      <TableCell>{entry.category?.name || ''}</TableCell>
                      <TableCell>
                        {entry.family?.name || entry.client?.name || ''}
                      </TableCell>
                      <TableCell>
                        <Box>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1,
                            }}
                          >
                            <span>
                              {context.i18n[
                                `timeTrackingStatus_${entry.status}`
                              ] || entry.status}
                            </span>
                            {entry.entrySource === 'MANUAL' && (
                              <Tooltip
                                title={
                                  context.i18n.timeTrackingManualEntryInfo ||
                                  'This entry was manually created or edited'
                                }
                              >
                                <Box
                                  component="span"
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: 'info.main',
                                  }}
                                >
                                  <FontAwesomeIcon icon={faPen} size="sm" />
                                </Box>
                              </Tooltip>
                            )}
                          </Box>

                          {/* Show rejection reason for admins (unless COMPLETED) or when REJECTED for everyone */}
                          {((context.isAdmin &&
                            entry.rejectionReason &&
                            entry.status !== 'COMPLETED') ||
                            (entry.status === 'REJECTED' &&
                              entry.rejectionReason)) && (
                            <Box
                              sx={{
                                p: 2,
                                mt: 0.5,
                                border: '1px solid',
                                borderColor: 'error.main',
                                borderRadius: 1,
                                bgcolor: '#FFF8F8',
                                maxWidth: '250px',
                                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                              }}
                              aria-label="Rejection reason"
                            >
                              <Typography
                                variant="body1"
                                fontWeight="bold"
                                color="error.dark"
                                sx={{ display: 'block', mb: 0.75 }}
                              >
                                {context.i18n['rejection-reason-label'] ||
                                  'Grund der Ablehnung:'}
                              </Typography>
                              <Typography
                                variant="body1"
                                color="text.primary"
                                sx={{
                                  display: 'block',
                                  wordWrap: 'break-word',
                                  fontWeight: 'medium',
                                }}
                              >
                                {entry.rejectionReason}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>{entry.note || ''}</TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {/* First column: Status dropdown with fixed width */}
                          <Box sx={{ width: '140px', mr: 1 }}>
                            <FormControl size="small" sx={{ width: '100%' }}>
                              <Select
                                value={entry.status}
                                onChange={(event) =>
                                  handleSingleStatusChange(
                                    entry.id as string,
                                    event.target.value as TimeTrackingStatus,
                                  )
                                }
                                displayEmpty
                                size="small"
                                disabled={!entry.end}
                              >
                                <MenuItem value={TimeTrackingStatus.OPEN}>
                                  {
                                    context.i18n[
                                      'timeTrackingStatus_' +
                                        TimeTrackingStatus.OPEN
                                    ]
                                  }
                                </MenuItem>
                                <MenuItem
                                  value={TimeTrackingStatus.SUBMITTED}
                                  disabled={!entry.end}
                                >
                                  {
                                    context.i18n[
                                      'timeTrackingStatus_' +
                                        TimeTrackingStatus.SUBMITTED
                                    ]
                                  }
                                </MenuItem>
                                <MenuItem
                                  value={TimeTrackingStatus.COMPLETED}
                                  disabled={!entry.end}
                                >
                                  {
                                    context.i18n[
                                      'timeTrackingStatus_' +
                                        TimeTrackingStatus.COMPLETED
                                    ]
                                  }
                                </MenuItem>
                                <MenuItem
                                  value={TimeTrackingStatus.REJECTED}
                                  disabled={!entry.end}
                                >
                                  {
                                    context.i18n[
                                      'timeTrackingStatus_' +
                                        TimeTrackingStatus.REJECTED
                                    ]
                                  }
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Box>

                          {/* Second column: Action buttons with fixed positions */}
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                              gap: 0.5,
                              minWidth: '180px',
                            }}
                          >
                            {/* Stop timer button (conditionally shown but with fixed space) */}
                            <Box sx={{ width: '64px' }}>
                              {!entry.end && (
                                <Tooltip
                                  title={
                                    context.i18n.timeTrackingStopTimer ||
                                    'Stop timer'
                                  }
                                >
                                  <Button
                                    variant="contained"
                                    color="error"
                                    size="small"
                                    onClick={async () => {
                                      try {
                                        const res =
                                          await TimeTrackingApi.editEntry(
                                            context.authToken,
                                            entry.id as string,
                                            {
                                              end: moment().utc().format(),
                                              entrySource: 'ADMIN', // Mark as edited by admin
                                            },
                                          );
                                        if (res) {
                                          snackbarUtils.success(
                                            context.i18n
                                              .timeTrackingTimerStopped ||
                                              'Timer stopped',
                                          );
                                          fetchUserEntries();
                                        }
                                      } catch (error) {
                                        console.error(
                                          'Error stopping timer:',
                                          error,
                                        );
                                        snackbarUtils.error(
                                          context.i18n.errorTryAgainLater,
                                        );
                                      }
                                    }}
                                  >
                                    {context.i18n.stop || 'Stop'}
                                  </Button>
                                </Tooltip>
                              )}
                            </Box>

                            {/* Always visible action buttons */}
                            <Box sx={{ display: 'flex', gap: 0.5 }}>
                              {/* Edit button */}
                              <Tooltip title={context.i18n.edit || 'Edit'}>
                                <IconButton
                                  size="small"
                                  color="primary"
                                  onClick={() => {
                                    setSelectedEntry(entry);
                                    setEditDialogOpen(true);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faEdit} size="sm" />
                                </IconButton>
                              </Tooltip>

                              {/* Delete button */}
                              <Tooltip title={context.i18n.delete || 'Delete'}>
                                <IconButton
                                  size="small"
                                  color="error"
                                  onClick={() => {
                                    setSelectedEntry(entry);
                                    setDeleteDialogOpen(true);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faTrash} size="sm" />
                                </IconButton>
                              </Tooltip>
                            </Box>

                            {/* Status-dependent action buttons with fixed positions */}
                            <Box
                              sx={{
                                display: 'flex',
                                gap: 0.5,
                                minWidth: '64px',
                              }}
                            >
                              {/* Approve button - only display for SUBMITTED entries */}
                              {entry.status === 'SUBMITTED' ? (
                                <>
                                  <Tooltip
                                    title={
                                      context.i18n[
                                        'time-tracking-btn-accept'
                                      ] || 'Accept'
                                    }
                                  >
                                    <IconButton
                                      size="small"
                                      color="success"
                                      onClick={() =>
                                        handleApproveEntry(entry.id as string)
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={faCheck}
                                        size="sm"
                                      />
                                    </IconButton>
                                  </Tooltip>

                                  <Tooltip
                                    title={
                                      context.i18n[
                                        'time-tracking-btn-reject'
                                      ] || 'Reject'
                                    }
                                  >
                                    <IconButton
                                      size="small"
                                      color="error"
                                      onClick={() =>
                                        handleRejectEntry(entry.id as string)
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={faTimes}
                                        size="sm"
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              ) : (
                                // Empty space holders to maintain layout when buttons are not visible
                                <Box sx={{ width: '64px' }} />
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Typography variant="h6">
                {context.i18n.total || 'Gesamt'}:{' '}
                {parseDurationNumber(totalDuration, true)}
              </Typography>
            </Box>
          </>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="200px"
            sx={{ backgroundColor: 'background.paper', borderRadius: 1 }}
          >
            <Typography variant="body1" color="text.secondary">
              {context.i18n.noData ||
                'No time entries found for the selected period'}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TimeTrackingUserEntries;
