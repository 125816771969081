import { axiosInstance } from '../../utils';

export default class TimeTrackingApi {
  static exportCompletedEntries(
    token: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .post('time-tracking/export-completed', body, {
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'blob',
      })
      .then((res) => {
        return res;
      });
  }
  static createEntry(token: string | undefined, body: any): Promise<any> {
    return axiosInstance
      .post('time-tracking', body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static editEntry(
    token: string | undefined,
    timeTrackingEntryId: string,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .put('time-tracking/' + timeTrackingEntryId, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }
  static deleteEntry(
    token: string | undefined,
    timeTrackingEntryId: string,
  ): Promise<any> {
    return axiosInstance
      .delete('time-tracking/' + timeTrackingEntryId, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getEntryList(token: string | undefined, body: any): Promise<any> {
    return axiosInstance
      .post('time-tracking/list', body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getUserSummary(token: string | undefined, body: any): Promise<any> {
    return axiosInstance
      .post('time-tracking/user-summary', body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getCategories(token: string | undefined): Promise<any> {
    return axiosInstance
      .get('time-tracking/categories/', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getStartedTimeTrackingEntry(token: string | undefined): Promise<any> {
    return axiosInstance
      .get('time-tracking/started-entry/', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getEntry(
    token: string | undefined,
    timeTrackingEntryId: string,
  ): Promise<any> {
    return axiosInstance
      .get(`time-tracking/${timeTrackingEntryId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static entryMultiStatusChange(
    token: string | undefined,
    body: any,
  ): Promise<any> {
    return axiosInstance
      .put('time-tracking/status/multi-change', body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }
}
