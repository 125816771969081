import { FC, useContext } from 'react';
import { MenuWrapper } from '../../../components/core';
import { AppContext } from '../../../hooks/context';
import TimeTrackingSummaryPage from '../../../components/admin/time-tracking-summary/time-tracking-summary-page';

const TimeTrackingSummaryView: FC = () => {
  const context = useContext(AppContext);

  return (
    <MenuWrapper
      title={context.i18n.timeTrackingUserSummary}
      search
      addClient
      selected="admin-time-tracking-summary"
      admin
    >
      <TimeTrackingSummaryPage />
    </MenuWrapper>
  );
};

export default TimeTrackingSummaryView;
