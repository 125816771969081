import moment from 'moment';

export const parseDateTimeString = (
  dateString: string | undefined,
  overrideFormat?: string,
): string => {
  if (!dateString) return '';
  return moment(dateString).format(overrideFormat || 'DD.MM.YYYY | HH:mm');
};

export const parseDateString = (dateString: string | undefined): string => {
  if (!dateString) return '';
  return moment(dateString).format('DD.MM.YYYY');
};

export const parseDurationNumber = (
  value: number | undefined,
  includeSeconds?: boolean,
): string => {
  if (!value) return '';
  const duration = moment.duration(value);
  const totalHours = Math.floor(duration.asHours());
  if (includeSeconds) {
    return `${totalHours}h ${duration.minutes()}m ${duration.seconds()}s`;
  }
  return `${totalHours}h ${duration.minutes()}m`;
};
