import { FC, useContext } from 'react';
import {
  Alert,
  AlertTitle,
  Box,
  Paper,
  Typography,
  Button,
  Link,
} from '@mui/material';
import { AppContext } from '../../hooks/context';
import EmailIcon from '@mui/icons-material/Email';

/**
 * A component that displays a warning when no features are active
 * for the current organization
 */
const NoFeaturesWarning: FC = () => {
  const context = useContext(AppContext);

  // Contact email for CaseCare support
  const supportEmail = 'support@case.care';

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        p: 2,
      }}
    >
      <Paper
        elevation={3}
        sx={{
          p: 4,
          maxWidth: 600,
          width: '100%',
        }}
      >
        <Alert severity="warning" sx={{ mb: 3 }}>
          <AlertTitle>{context.i18n.noFeaturesTitle}</AlertTitle>
          {context.i18n.noFeaturesMessage}
        </Alert>

        <Typography variant="body1" paragraph>
          {context.i18n.noFeaturesDescription}
        </Typography>

        <Typography variant="body2" paragraph color="text.secondary">
          {context.i18n.noFeaturesContactAdmin}
        </Typography>

        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<EmailIcon />}
            component={Link}
            href={`mailto:${supportEmail}?subject=Feature%20Activation%20Request&body=Hello%20CaseCare%20Team,%0A%0AI%20would%20like%20to%20activate%20features%20for%20my%20organization.%0A%0AOrganization%20ID:%20${
              context.user?.organizationId || ''
            }%0AUser:%20${context.user?.email || ''}%0A%0AThank%20you.`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {context.i18n.noFeaturesContactButton}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default NoFeaturesWarning;
