import { FC, useContext, useState, useEffect } from 'react';
import { AppContext } from '../../../hooks/context';
import { TimeTrackingUserSummary } from './time-tracking-user-summary';
import TimeTrackingUserEntries from './time-tracking-user-entries';
import { Box, Paper } from '@mui/material';
import moment, { Moment } from 'moment';
import { DateRange } from '@mui/x-date-pickers-pro';
import { loadTitle, setDocumentTitle } from '../../../utils';

const TimeTrackingSummaryPage: FC = () => {
  const context = useContext(AppContext);

  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [selectedUserName, setSelectedUserName] = useState<string>('');
  const [dateRange, setDateRange] = useState<DateRange<Moment>>([
    moment().startOf('month'),
    moment().endOf('month'),
  ]);

  // Set document title when component mounts or user selection changes
  useEffect(() => {
    if (selectedUserId) {
      setDocumentTitle(
        `${context.i18n.siteTitleAdminTimeTrackingSummary} - ${selectedUserName}`,
      );
    } else {
      setDocumentTitle(context.i18n.siteTitleAdminTimeTrackingSummary);
    }
    return () => loadTitle();
  }, [selectedUserId, selectedUserName, context.i18n]);

  // Show user details view when a user is selected, otherwise show the summary
  return (
    <Box sx={{ p: 2 }}>
      <Paper sx={{ p: 2 }}>
        {selectedUserId ? (
          <TimeTrackingUserEntries
            userId={selectedUserId}
            userName={selectedUserName}
            dateRange={dateRange}
            statusFilter={[]}
            onBackToSummary={() => setSelectedUserId(null)}
            onDateRangeChange={setDateRange}
          />
        ) : (
          <TimeTrackingUserSummary
            dateRange={dateRange}
            onUserSelect={(userId, userName) => {
              setSelectedUserId(userId);
              setSelectedUserName(userName);
            }}
            onDateRangeChange={setDateRange}
          />
        )}
      </Paper>
    </Box>
  );
};

export default TimeTrackingSummaryPage;
