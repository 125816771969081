import { ModuleType } from '@casecare/types';
import { axiosInstance } from '../../utils';

export interface Feature {
  id: string;
  featureType:
    | 'FAMILY'
    | 'RISK'
    | 'GENOGRAMM'
    | 'AVATAR'
    | 'BILLING'
    | 'TIME_TRACKING'
    | 'ECO_MAP'
    | 'SIMPLE_MODE';
  status: 'ACTIVE' | 'INACTIVE' | 'PENDING';
  expirationDate?: string;
  billingProvider: 'MANUAL';
  subscriptionId: string;
}

export interface AboListResponse {
  features: Feature[];
  modules?: {
    familyActive?: boolean;
    riskActive?: boolean;
    genoGrammActive?: boolean;
    avatarActive?: boolean;
    billingActive?: boolean;
    basicActive?: boolean;
  };
  subscription?: {
    id: string;
    status: 'ACTIVE' | 'INACTIVE' | 'PAST_DUE' | 'CANCELED';
    expirationDate?: string;
  };
  hasActiveFeatures: boolean;
}

export default class AboApi {
  static listAbos(token: string | undefined): Promise<AboListResponse> {
    return axiosInstance
      .get('abo/list', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        // Check for all possible response structures
        if (!res.data) {
          console.error('Missing data in response:', res);
          return this.getDefaultResponse();
        }

        // Response structure looks good

        // Check if we have the correct data structure
        let result;

        // Try to find the data in various possible locations
        if (res.data.result) {
          // Original expected format
          result = res.data.result;
        } else if (res.data.data && res.data.data.result) {
          // Nested result format
          result = res.data.data.result;
        } else if (res.data.data) {
          // Direct data format
          result = res.data.data;
        } else {
          // Assume res.data itself is the result
          result = res.data;
        }

        // Verify we have a valid result object
        if (!result || typeof result !== 'object') {
          return this.getDefaultResponse();
        }

        return result;
      })
      .catch(() => {
        return this.getDefaultResponse();
      });
  }

  // Helper method to provide a fallback response
  private static getDefaultResponse(): AboListResponse {
    return {
      features: [],
      modules: { basicActive: true },
      hasActiveFeatures: true,
    };
  }

  static listFeatures(token: string | undefined): Promise<Feature[]> {
    return axiosInstance
      .get('abo/features', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        // Check for different response structures
        if (res.data.result) {
          return res.data.result;
        } else if (res.data.data && res.data.data.result) {
          return res.data.data.result;
        } else if (res.data.data) {
          return res.data.data;
        }
        return [];
      })
      .catch(() => {
        return [];
      });
  }

  static addAbo(token: string | undefined, type: ModuleType): Promise<any> {
    return axiosInstance
      .post(
        'abo/add',
        {
          type,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((res) => {
        // Return the most appropriate response object
        if (res.data.result) {
          return res.data.result;
        } else if (res.data.data) {
          return res.data.data;
        }
        return res.data;
      })
      .catch(() => {
        return { success: false, message: 'Failed to add subscription' };
      });
  }
}
