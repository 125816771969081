import React from 'react';
import ReactDOM from 'react-dom';
import App from './routes';
import AppProvider from './hooks/context';
import { SnackbarProvider } from 'notistack';
import './index.css';
import '@fontsource/roboto';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import { ThemeProvider } from '@mui/material';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { SnackbarUtilsConfigurator } from './utils/snackbar/snackbar-utils';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { theme } from './utils/core/misc-utils';
import { queryClient } from './utils';
import 'moment/locale/de';

import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(
  'c08dbd6b202b3a146820f157473de6a8Tz0xMDg4NzksRT0xNzcyNTgyMzk5MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=',
);

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        adapterLocale={window.navigator.language}
      >
        <ThemeProvider theme={theme}>
          <SnackbarProvider>
            <SnackbarUtilsConfigurator />
            <AppProvider>
              <App />
            </AppProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </LocalizationProvider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
