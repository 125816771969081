import { Grid, Stack, TextField } from '@mui/material';
import { context } from 'msw';
import { FC, useContext, useEffect, useState } from 'react';
import { UserSelect } from '../../client';
import { CreateClientDialogProps } from './create-client-dialog';
import { FamilyDataType } from '@casecare/types';
import { FamilyApi } from '../../../api';
import { AppContext } from '../../../hooks/context';
import { queryClient } from '../../../utils';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';

interface FamilyDialogFieldsProps extends CreateClientDialogProps {
  save: boolean;
}

export const FamilyDialogFields: FC<FamilyDialogFieldsProps> = (props) => {
  const context = useContext(AppContext);

  const [open, setOpen] = useState(props.open);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [street, setStreet] = useState('');
  const [streetError, setStreetError] = useState(false);
  const [zipcode, setZipcode] = useState('');
  const [zipcodeError, setZipcodeError] = useState(false);
  const [city, setCity] = useState('');
  const [cityError, setCityError] = useState(false);
  const [supervisorIds, setSupervisorIds] = useState([context.userId]);
  const [supervisorError, setSupervisorError] = useState(false);
  const [familyNumber, setFamilyNumber] = useState('');
  const [streetNumber, setStreetNumber] = useState('');

  const familyFields = [
    {
      name: context.i18n.clientNumber,
      type: FamilyDataType.FAMILY_NUMBER,
      value: familyNumber,
      setValue: (value: string) => setFamilyNumber(value),
    },
    {
      name: context.i18n.street,
      type: FamilyDataType.STREET,
      value: street,
      setValue: (value: string) => setStreet(value),
    },
    {
      name: context.i18n.streetNumber,
      type: FamilyDataType.STREET_NUMBER,
      value: streetNumber,
      setValue: (value: string) => setStreetNumber(value),
    },
    {
      name: context.i18n.zipcode,
      type: FamilyDataType.ZIPCODE,
      value: zipcode,
      setValue: (value: string) => setZipcode(value),
    },
    {
      name: context.i18n.city,
      type: FamilyDataType.CITY,
      value: city,
      setValue: (value: string) => setCity(value),
      error: cityError,
      disableError: () => setCityError(false),
    },
  ];

  useEffect(() => {
    setStreet('');
    setZipcode('');
    setCity('');
    setName('');
    setFamilyNumber('');
    setStreetNumber('');
  }, [props.open]);

  useEffect(() => {
    if (props.save) {
      onSave();
    }
  }, [props.save]);

  const onSave = async () => {
    setNameError(name === '');
    setSupervisorError(supervisorIds.length === 0);

    if (name !== '' && supervisorIds.length !== 0) {
      const data: Record<FamilyDataType, any> = {
        CITY: city,
        STREET: street,
        ZIPCODE: zipcode,
        SUPERVISOR: supervisorIds,
        STREET_NUMBER: streetNumber,
        FAMILY_NUMBER: familyNumber,
      };

      const body = {
        name,
        data,
      };

      try {
        const res = await FamilyApi.addFamily(context.authToken, body);

        if (res.message) {
          snackbarUtils.success(res.message);
        }

        queryClient.invalidateQueries('families'), props.onClose();

        props.onFinish(res.data, true);
      } catch (e) {
        console.error(e);
        snackbarUtils.error(context.i18n.errorTryAgainLater);
      }
    }
  };

  return (
    <Grid container spacing={2} mt={2}>
      {!context.simpleModeActive && (
        <Grid item xs={6}>
          <UserSelect
            title={context.i18n.supervisor}
            error={supervisorError}
            multiple
            userIds={supervisorIds}
            setId={(id) => {
              setSupervisorIds(id);
              setSupervisorError(false);
            }}
          />
        </Grid>
      )}
      <Grid item xs={6}>
        <TextField
          fullWidth
          required
          color="secondary"
          label={context.i18n.name}
          size="medium"
          type={'text'}
          value={name}
          data-id="family-name-input"
          error={nameError}
          onChange={(e) => {
            setNameError(false);
            setName(e.currentTarget.value);
          }}
        />
      </Grid>
      {familyFields.map((field) => (
        <Grid key={field.type} item xs={6}>
          <TextField
            fullWidth
            color="secondary"
            label={field.name}
            size="medium"
            type={'text'}
            value={field.value}
            error={field.error}
            onChange={(e) => {
              field.disableError && field.disableError();
              field.setValue(e.currentTarget.value);
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};
