import { useContext, useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Box,
  Typography,
  Autocomplete,
  IconButton,
  Paper,
} from '@mui/material';
import { AppContext } from '../../../hooks/context';
import { TimeTrackingApi } from '../../../api';
import { TimeTrackingCategory } from '../../../types/time-tracking-category';
import { TimeTrackingEntry } from '../../../types/time-tracking-entry';
import * as moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { TimerWithStartStopButton } from '../../../components/time-tracking/time-tracking-list/time-tracking-list-toolbar/time-tracking-list-toolbar';
import { TimeTrackingCategoryMethod } from '../../../types/time-tracking-category-method';

const MobileTimeTrackingToolbar = ({
  timeTrackingCategories,
  clientFamilyList,
  onTimerStart,
  onTimerStop,
  currentTimeTrackingEntry,
  setCurrentTimeTrackingEntry,
  currentMonth,
  onMonthChange,
}: {
  timeTrackingCategories: TimeTrackingCategory[];
  clientFamilyList: any[];
  onTimerStart: (entry: TimeTrackingEntry) => void;
  onTimerStop: (entry: TimeTrackingEntry) => void;
  currentTimeTrackingEntry: TimeTrackingEntry | null;
  setCurrentTimeTrackingEntry: (entry: TimeTrackingEntry | null) => void;
  currentMonth?: moment.Moment;
  onMonthChange?: (newMonth: moment.Moment) => void;
}) => {
  const context = useContext(AppContext);
  const [timeTrackingCategorySelected, setTimeTrackingCategorySelected] =
    useState<TimeTrackingCategory | null>(null);
  const [clientFamilySelected, setClientFamilySelected] = useState<any | null>(
    null,
  );
  const [note, setNote] = useState<string>('');
  const [disableStart, setDisableStart] = useState(true);

  useEffect(() => {
    if (timeTrackingCategorySelected == undefined) setDisableStart(true);
    else if (
      timeTrackingCategorySelected.isClientOrFamilyNeeded &&
      clientFamilySelected == null
    )
      setDisableStart(true);
    else setDisableStart(false);
  }, [timeTrackingCategorySelected, clientFamilySelected]);

  useEffect(() => {
    if (currentTimeTrackingEntry) {
      if (currentTimeTrackingEntry.category)
        setTimeTrackingCategorySelected(currentTimeTrackingEntry.category);
      if (
        currentTimeTrackingEntry.clientId ||
        currentTimeTrackingEntry.familyId
      ) {
        const item = clientFamilyList.find(
          (f) =>
            f.id === currentTimeTrackingEntry?.clientId ||
            f.id === currentTimeTrackingEntry?.familyId,
        );
        if (item) setClientFamilySelected(item);
      }
      if (currentTimeTrackingEntry.note) setNote(currentTimeTrackingEntry.note);
    }
  }, [currentTimeTrackingEntry, clientFamilyList]);

  // Information label about the protocol feature
  const showProtocolInfo =
    timeTrackingCategorySelected?.method ===
    TimeTrackingCategoryMethod.PROTOCOL;

  return (
    <Paper elevation={1} sx={{ mb: 2, p: 2 }}>
      <Autocomplete
        disabled={currentTimeTrackingEntry != undefined}
        sx={{ my: 1 }}
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            label={context.i18n.timeTrackingCategory}
            InputProps={{
              ...params.InputProps,
              style: { fontSize: '1rem' },
            }}
            InputLabelProps={{
              style: { fontSize: '1rem' },
            }}
          />
        )}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        options={timeTrackingCategories}
        value={timeTrackingCategorySelected}
        groupBy={(option: any) => option.parent?.name || ''}
        onChange={(e, val) => setTimeTrackingCategorySelected(val)}
      />

      {showProtocolInfo && !currentTimeTrackingEntry && (
        <Box
          sx={{
            backgroundColor: '#e3f2fd',
            borderRadius: 1,
            p: 1,
            mb: 1,
            fontSize: '0.875rem',
            color: 'primary.main',
          }}
        >
          {context.i18n.protocolCategoryInfo ||
            'This category requires a protocol. You will be prompted to create one when you stop the timer.'}
        </Box>
      )}

      {timeTrackingCategorySelected?.isClientOrFamilyNeeded && (
        <Autocomplete
          disabled={currentTimeTrackingEntry != undefined}
          fullWidth
          sx={{ my: 1 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={context.i18n.timeTrackingCategoryIsClientOrFamilyNeeded}
              InputProps={{
                ...params.InputProps,
                style: { fontSize: '1rem' },
              }}
              InputLabelProps={{
                style: { fontSize: '1rem' },
              }}
            />
          )}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={clientFamilySelected}
          onChange={(e, val) => setClientFamilySelected(val)}
          options={clientFamilyList.sort((x) => (x.isFamily ? -1 : 1))}
        />
      )}

      <TextField
        disabled={currentTimeTrackingEntry != undefined}
        value={note}
        onChange={(e) => setNote(e.target.value)}
        sx={{ my: 1 }}
        placeholder={context.i18n.timeTrackingNote}
        fullWidth
        InputProps={{
          style: { fontSize: '1rem' },
        }}
        InputLabelProps={{
          style: { fontSize: '1rem' },
        }}
      />

      <TimerWithStartStopButton
        startDisabled={disableStart}
        timer={
          currentTimeTrackingEntry?.startRealTime
            ? moment(currentTimeTrackingEntry.startRealTime)
            : undefined
        }
        onStart={async (timer) => {
          if (timeTrackingCategorySelected) {
            const sendData = {
              start: timer.utc(),
              categoryId: timeTrackingCategorySelected.id,
              ...(timeTrackingCategorySelected.isClientOrFamilyNeeded &&
                (clientFamilySelected && clientFamilySelected.isFamily
                  ? { familyId: clientFamilySelected.id }
                  : { clientId: clientFamilySelected.id })),
              ...(note && { note }),
            };

            const res = await TimeTrackingApi.createEntry(
              context.authToken,
              sendData,
            );
            if (res.data) {
              setCurrentTimeTrackingEntry(res.data);
              onTimerStart(res.data);
            }
          }
        }}
        onStop={async (timer) => {
          if (currentTimeTrackingEntry && currentTimeTrackingEntry.id) {
            const sendData = { end: timer.utc() };
            const res = await TimeTrackingApi.editEntry(
              context.authToken,
              currentTimeTrackingEntry.id,
              sendData,
            );
            if (res) {
              setCurrentTimeTrackingEntry(null);
              setTimeTrackingCategorySelected(null);
              setClientFamilySelected(null);
              setNote('');
              onTimerStop(res.data);
            }
          }
        }}
      />

      {/* Month selector */}
      {currentMonth && onMonthChange && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 3,
            backgroundColor: '#f5f5f5',
            borderRadius: 1,
            padding: 1,
          }}
        >
          <IconButton
            onClick={() => {
              const prevMonth = currentMonth.clone().subtract(1, 'month');
              onMonthChange(prevMonth);
            }}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </IconButton>

          <Typography variant="subtitle1" fontWeight="bold">
            {currentMonth.format('MMMM YYYY')}
          </Typography>

          <IconButton
            onClick={() => {
              const nextMonth = currentMonth.clone().add(1, 'month');
              if (
                nextMonth.month() <= moment().month() ||
                nextMonth.year() < moment().year()
              ) {
                onMonthChange(nextMonth);
              }
            }}
            disabled={
              currentMonth.month() === moment().month() &&
              currentMonth.year() === moment().year()
            }
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </IconButton>
        </Box>
      )}

      <Box sx={{ width: '100%', textAlign: 'center', mt: 2 }}>
        <Typography variant="body2" color="text.secondary">
          {context.i18n.timeTrackingMobileInstructions ||
            'Tap on an entry to expand it. You can edit or delete entries using the buttons in the expanded view.'}
        </Typography>
      </Box>
    </Paper>
  );
};

export default MobileTimeTrackingToolbar;
