import { FC, useContext, useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Typography,
  Collapse,
  Button,
} from '@mui/material';
import { AppContext } from '../../../hooks/context';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { parseDurationNumber } from '../../../utils/date/date';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faEdit,
  faFilePen,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import moment, { Moment } from 'moment';
import { useQuery } from 'react-query';
import { TimeTrackingApi } from '../../../api';
import { TimeTrackingEntry } from '../../../types/time-tracking-entry';
import TimeTrackingEntryDialog from '../../../components/time-tracking/time-tracking-list/time-tracking-entry-dialog/time-tracking-entry-dialog';
import DeleteTimeTrackingEntryDialog from '../../../components/time-tracking/time-tracking-list/time-tracking-entry-dialog/delete-time-tracking-entry-dialog';
import MobileTimeTrackingToolbar from './mobile-time-tracking-toolbar';
import { MobileNavBar, MobileThemeProvider } from '../../../components/mobile';
import { useNavigate } from 'react-router-dom';
import { TimeTrackingCategory } from '../../../types/time-tracking-category';
import { TimeTrackingStatus } from '../../../types/time-tracking-status';
import { TimeTrackingCategoryMethod } from '../../../types/time-tracking-category-method';
import EditProtocolDialog from '../../../components/dialogs/edit-protocol-dialog/edit-protocol-dialog';

const MobileTimeTrackingList: FC<{
  clientId?: string;
  familyId?: string;
  timeTracking?: string;
}> = (props) => {
  const context = useContext(AppContext);
  const [rows, setRows] = useState<any[]>([]);
  const [expandedCard, setExpandedCard] = useState<number | null>(null);
  const [currentTimeTrackingEntry, setCurrentTimeTrackingEntry] =
    useState<TimeTrackingEntry | null>(null);
  const [timeTrackingCategories, setTimeTrackingCategories] = useState<
    TimeTrackingCategory[]
  >([]);
  const [clientFamilyList, setClientFamilyList] = useState<any[]>([]);
  const [currentMonth, setCurrentMonth] = useState<Moment>(moment());

  // Dialog states
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
  const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [editProtocolDialogOpen, setEditProtocolDialogOpen] =
    useState<boolean>(false);
  const [selectedTimeTrackingEntry, setSelectedTimeTrackingEntry] = useState<
    TimeTrackingEntry | undefined
  >();

  const navigate = useNavigate();
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: [
      'timeTrackingList',
      props.clientId,
      props.familyId,
      currentMonth.format('YYYY-MM'),
    ],
    queryFn: () => {
      const data: any = {
        clientId: props.clientId,
        familyId: props.familyId,
        start: currentMonth.clone().startOf('month'),
        end: currentMonth.clone().endOf('month').isAfter(moment())
          ? moment()
          : currentMonth.clone().endOf('month'),
      };
      return TimeTrackingApi.getEntryList(context.authToken, data);
    },
    enabled: context.authToken !== undefined,
  });

  useEffect(() => {
    if (!isLoading) {
      if (error) {
        console.error(error);
      } else if (data && data.data) {
        setRows(data.data.collection);
        setCurrentTimeTrackingEntry(data.data.startedEntry);
        setTimeTrackingCategories(data.data.categories);
        setClientFamilyList(data.data.clientFamilyList);
      }
    }
  }, [data, error, isLoading]);

  const handleExpandClick = (id: number) => {
    setExpandedCard(expandedCard === id ? null : id);
  };

  const handleEditClick = (entry: TimeTrackingEntry) => {
    setSelectedTimeTrackingEntry(entry);

    if (entry.category?.method === TimeTrackingCategoryMethod.PROTOCOL) {
      // For protocol categories, always use the protocol dialog
      setEditProtocolDialogOpen(true);
    } else {
      // For normal categories, use the standard edit dialog
      setEditDialogOpen(true);
    }
  };

  const handleDeleteClick = (entry: TimeTrackingEntry) => {
    setSelectedTimeTrackingEntry(entry);
    setDeleteDialogOpen(true);
  };

  return (
    <MobileThemeProvider>
      <MobileNavBar
        title={context.i18n.TIME_TRACKING}
        back
        onBack={() => navigate('/mobile/clients', { replace: true })}
        add
        onAdd={() => setCreateDialogOpen(true)}
      />
      <MobileTimeTrackingToolbar
        timeTrackingCategories={timeTrackingCategories}
        clientFamilyList={clientFamilyList}
        onTimerStart={(timeTrackingEntry: TimeTrackingEntry) => {
          refetch();
          setCurrentTimeTrackingEntry(timeTrackingEntry);
        }}
        onTimerStop={(timeTrackingEntry: TimeTrackingEntry) => {
          if (
            timeTrackingEntry.category?.method ===
            TimeTrackingCategoryMethod.PROTOCOL
          ) {
            setSelectedTimeTrackingEntry(timeTrackingEntry);
            setEditProtocolDialogOpen(true);
          }
          refetch();
          setCurrentTimeTrackingEntry(null);
        }}
        currentTimeTrackingEntry={currentTimeTrackingEntry}
        setCurrentTimeTrackingEntry={setCurrentTimeTrackingEntry}
        currentMonth={currentMonth}
        onMonthChange={(newMonth: Moment) => {
          setCurrentMonth(newMonth);
        }}
      />
      {rows.map((row, index) => (
        <Card key={row.id} sx={{ mb: 2 }}>
          <CardContent
            sx={{
              ...(row.status === TimeTrackingStatus.REJECTED
                ? { backgroundColor: '#FFF8F8' }
                : row.status === TimeTrackingStatus.SUBMITTED
                ? { backgroundColor: '#f5f5f5' }
                : row.status === TimeTrackingStatus.COMPLETED
                ? { backgroundColor: '#F1F8F1' }
                : {}),
              ...(row.status !== TimeTrackingStatus.OPEN &&
              row.status !== TimeTrackingStatus.REJECTED
                ? { color: 'gray' }
                : {}),
              ...(row.isMultiDay ? { backgroundColor: '#f5f8ff' } : {}),
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              onClick={() => handleExpandClick(row.id)}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                {row.isMultiDay ? (
                  <>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      {moment.utc(row.start).format('DD.MM.YYYY')} -{' '}
                      {row.endDate || moment.utc(row.end).format('DD.MM.YYYY')}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {row.daysSpanned
                        ? `${row.daysSpanned} ${
                            context.i18n.timeTrackingDays || 'Days'
                          }`
                        : ''}
                    </Typography>
                  </>
                ) : (
                  <Typography variant="h6">
                    {moment(row.start).format('DD.MM.YYYY')}
                  </Typography>
                )}
                <Typography variant="body2">
                  {moment(row.start).format('HH:mm:ss')} -{' '}
                  {!row.end ? 'JETZT' : moment(row.end).format('HH:mm:ss')}
                </Typography>
              </Box>
              <IconButton
                aria-expanded={expandedCard === row.id}
                aria-label="mehr anzeigen"
              >
                <FontAwesomeIcon
                  icon={expandedCard === row.id ? faChevronUp : faChevronDown}
                />
              </IconButton>
            </Box>
            <Collapse in={expandedCard === row.id} timeout="auto" unmountOnExit>
              <Box mt={2}>
                {row.duration || (row.end && row.start) ? (
                  <Typography variant="body2">
                    {context.i18n.timeTrackingDuration}:{' '}
                    {row.isMultiDay && row.durationFormatted
                      ? row.duration
                        ? parseDurationNumber(row.duration, true)
                        : `${row.durationFormatted.replace(',', 'h ')}m 00s`
                      : parseDurationNumber(
                          row.duration ||
                            moment(row.end).diff(moment(row.start)),
                          true,
                        )}
                  </Typography>
                ) : null}
                <Typography variant="body2">
                  {context.i18n.timeTrackingClientCategory}: {row.category.name}
                </Typography>
                <Typography variant="body2">
                  {context.i18n.timeTrackingClientFamily}:{' '}
                  {row.family ? row.family?.name : row.client?.name}
                </Typography>
                <Typography variant="body2">
                  {context.i18n.timeTrackingStatus}:{' '}
                  {context.i18n['timeTrackingStatus_' + row.status]}
                </Typography>

                {/* Show rejection reason for admins (unless COMPLETED) or when REJECTED for everyone */}
                {((context.isAdmin &&
                  row.rejectionReason &&
                  row.status !== 'COMPLETED') ||
                  (row.status === 'REJECTED' && row.rejectionReason)) && (
                  <Box
                    sx={{
                      p: 2,
                      mt: 1.5,
                      mb: 1.5,
                      border: '1px solid',
                      borderColor: 'error.main',
                      borderRadius: 1,
                      bgcolor: '#FFF8F8',
                      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    }}
                    aria-label="Rejection reason"
                  >
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="error.dark"
                      sx={{ mb: 0.75 }}
                    >
                      {context.i18n['rejection-reason-label'] ||
                        'Grund der Ablehnung:'}
                    </Typography>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      sx={{ fontWeight: 'medium' }}
                    >
                      {row.rejectionReason}
                    </Typography>
                  </Box>
                )}

                {row.note && (
                  <Typography variant="body2">
                    {context.i18n.timeTrackingNote}: {row.note}
                  </Typography>
                )}

                {/* Action buttons for edit/delete/protocol */}
                {(row.editPermission || row.deletePermission) && (
                  <Box
                    sx={{
                      mt: 2,
                      pt: 1,
                      borderTop: '1px dotted',
                      borderColor: 'divider',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box>
                      {row.editPermission && (
                        <>
                          {/* Regular Edit button for all entries */}
                          <Button
                            startIcon={<FontAwesomeIcon icon={faEdit} />}
                            variant="outlined"
                            sx={{
                              mr: 1,
                              mb: 1,
                              fontSize: '0.9rem',
                              padding: '6px 16px',
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedTimeTrackingEntry(row);
                              setEditDialogOpen(true);
                            }}
                          >
                            {context.i18n.edit || 'Edit'}
                          </Button>

                          {/* Additional Protocol button for protocol entries */}
                          {row.category.method ===
                            TimeTrackingCategoryMethod.PROTOCOL && (
                            <Button
                              startIcon={<FontAwesomeIcon icon={faFilePen} />}
                              variant="outlined"
                              color="primary"
                              sx={{
                                mr: 1,
                                mb: 1,
                                fontSize: '0.9rem',
                                padding: '6px 16px',
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedTimeTrackingEntry(row);
                                setEditProtocolDialogOpen(true);
                              }}
                            >
                              {context.i18n.protocol || 'Protocol'}
                            </Button>
                          )}
                        </>
                      )}
                    </Box>

                    <Box>
                      {row.deletePermission && (
                        <Button
                          startIcon={<FontAwesomeIcon icon={faTrash} />}
                          variant="outlined"
                          color="error"
                          sx={{
                            mb: 1,
                            fontSize: '0.9rem',
                            padding: '6px 16px',
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteClick(row);
                          }}
                        >
                          {context.i18n.delete || 'Delete'}
                        </Button>
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            </Collapse>
          </CardContent>
        </Card>
      ))}

      {/* Create time tracking entry dialog */}
      <TimeTrackingEntryDialog
        open={createDialogOpen}
        timeTrackingCategories={timeTrackingCategories}
        clientFamilyList={clientFamilyList}
        onClose={() => {
          setCreateDialogOpen(false);
          refetch();
        }}
        onProtocolEdit={(entry) => {
          // Set the selected entry and open the protocol dialog
          setSelectedTimeTrackingEntry(entry);
          setCreateDialogOpen(false);
          setEditProtocolDialogOpen(true);
        }}
        fullScreen={true} // Make dialog full screen on mobile
      />

      {/* Edit time tracking entry dialog */}
      <TimeTrackingEntryDialog
        open={editDialogOpen}
        timeTrackingCategories={timeTrackingCategories}
        clientFamilyList={clientFamilyList}
        timeTrackingEntry={selectedTimeTrackingEntry}
        onClose={() => {
          setEditDialogOpen(false);
          setSelectedTimeTrackingEntry(undefined);
          refetch();
        }}
        fullScreen={true} // Make dialog full screen on mobile
      />

      {/* Delete time tracking entry dialog */}
      <DeleteTimeTrackingEntryDialog
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
          setSelectedTimeTrackingEntry(undefined);
          refetch();
        }}
        timeTrackingEntry={selectedTimeTrackingEntry}
      />

      {/* Protocol editing dialog */}
      <EditProtocolDialog
        clientId={selectedTimeTrackingEntry?.clientId}
        familyId={selectedTimeTrackingEntry?.familyId}
        open={editProtocolDialogOpen}
        protocol={selectedTimeTrackingEntry?.protocol}
        onClose={() => {
          setEditProtocolDialogOpen(false);
          setSelectedTimeTrackingEntry(undefined);
          refetch();
        }}
        showReleaseButton
        onLinkProtocol={async (protocolId) => {
          try {
            if (selectedTimeTrackingEntry?.id) {
              // Update the time tracking entry to link it with the selected protocol
              await TimeTrackingApi.editEntry(
                context.authToken,
                selectedTimeTrackingEntry.id,
                { protocolId },
              );

              // Show success message
              snackbarUtils.success(
                context.i18n.protocolLinkedSuccess ||
                  'Protocol linked successfully',
              );

              // Refetch data and close the dialog
              refetch();
              setSelectedTimeTrackingEntry(undefined);
            }
          } catch (error) {
            console.error('Error linking protocol:', error);
          }
        }}
        startDate={
          selectedTimeTrackingEntry?.start
            ? (() => {
                const start = moment(selectedTimeTrackingEntry.start).startOf(
                  'minute',
                );
                // Round to nearest 15 minutes
                const startMinute = start.minute();
                const remainderStart = startMinute % 15;
                if (remainderStart > 0) {
                  // If closer to next interval, round up, otherwise round down
                  if (remainderStart >= 8) {
                    start.add(15 - remainderStart, 'minutes');
                  } else {
                    start.subtract(remainderStart, 'minutes');
                  }
                }
                return start;
              })()
            : undefined
        }
        endDate={
          selectedTimeTrackingEntry?.end
            ? (() => {
                const end = moment(selectedTimeTrackingEntry.end).startOf(
                  'minute',
                );
                // Round to nearest 15 minutes
                const endMinute = end.minute();
                const remainderEnd = endMinute % 15;
                if (remainderEnd > 0) {
                  // If closer to next interval, round up, otherwise round down
                  if (remainderEnd >= 8) {
                    end.add(15 - remainderEnd, 'minutes');
                  } else {
                    end.subtract(remainderEnd, 'minutes');
                  }
                }

                // Calculate duration and ensure minimum of 15 minutes
                if (selectedTimeTrackingEntry?.start) {
                  const start = moment(selectedTimeTrackingEntry.start).startOf(
                    'minute',
                  );
                  const startMinute = start.minute();
                  const remainderStart = startMinute % 15;
                  if (remainderStart > 0) {
                    if (remainderStart >= 8) {
                      start.add(15 - remainderStart, 'minutes');
                    } else {
                      start.subtract(remainderStart, 'minutes');
                    }
                  }

                  const durationMinutes = end.diff(start, 'minutes');
                  if (durationMinutes < 15) {
                    end.add(15 - durationMinutes, 'minutes');
                  }
                }

                return end;
              })()
            : undefined
        }
        timeTrackingId={selectedTimeTrackingEntry?.id}
        fullScreen={true}
      />
    </MobileThemeProvider>
  );
};

export default MobileTimeTrackingList;
