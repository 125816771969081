import { FC, useContext, useEffect, useState } from 'react';
import {
  GridColDef,
  DataGridPro,
  GridFooter,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Link,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { AppContext } from '../../../hooks/context';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { theme } from '../../../utils';
import moment, { Moment } from 'moment';
import TimeTrackingListFooter from './time-tracking-list-footer/time-tracking-list-footer';
import TimeTrackingListToolbar from './time-tracking-list-toolbar/time-tracking-list-toolbar';
import { useQuery } from 'react-query';
import { TimeTrackingApi } from '../../../api';
import { DateRange } from '@mui/x-date-pickers-pro';
import { parseDurationNumber } from '../../../utils/date/date';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowsToDot,
  faExclamationTriangle,
  faFilePen,
  faPenToSquare,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { TimeTrackingEntry } from '../../../types/time-tracking-entry';
import TimeTrackingEntryDialog from './time-tracking-entry-dialog/time-tracking-entry-dialog';
import DeleteTimeTrackingEntryDialog from './time-tracking-entry-dialog/delete-time-tracking-entry-dialog';
import { TimeTrackingCategory } from '../../../types/time-tracking-category';
import { TimeTrackingStatus } from '../../../types/time-tracking-status';
import StatusTimeTrackingEntriesDialog from './time-tracking-entry-dialog/status-time-tracking-entries-dialog';
import { TimeTrackingCategoryMethod } from '../../../types/time-tracking-category-method';
import { EditProtocolDialog } from '../../dialogs';

const TimeTrackingList: FC<{
  clientId?: string;
  familyId?: string;
  timeTracking?: string;
}> = (props) => {
  // const context = useContext(AppContext);
  // const navigate = useNavigate();
  const [rows, setRows] = useState<any[]>([]);

  const context = useContext(AppContext);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'date', sort: 'desc' },
  ]);
  const [dateFilter, setDateFilter] = useState<DateRange<Moment>>([
    moment().startOf('week'),
    moment().endOf('week'),
  ]);

  const [statusFilter, setStatusFilter] = useState<TimeTrackingStatus[]>([]);
  const [userFilter, setUserFilter] = useState<string>();

  const [rowCount, setRowCount] = useState(0);
  const [overallDuration, setOverallDuration] = useState(0);

  const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false);
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [statusDialogOpen, setStatusDialogOpen] = useState<boolean>(false);
  const [editProtocolDialogOpen, setEditProtocolDialogOpen] =
    useState<boolean>(false);
  const [selectedTimeTrackingEntry, setSelectedTimeTrackingEntry] =
    useState<TimeTrackingEntry>();

  const [timeTrackingCategories, setTimeTrackingCategories] = useState<
    TimeTrackingCategory[]
  >([]);
  const [currentTimeTrackingEntry, setCurrentTimeTrackingEntry] =
    useState<TimeTrackingEntry>();

  const [selectedTimeTrackingEntries, setSelectedTimeTrackingEntries] =
    useState<TimeTrackingEntry[]>([]);

  const [clientFamilyList, setClientFamilyList] = useState<any[]>([]);
  const [userList, setUserList] = useState<any[]>([]);

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: [
      'timeTrackingList',
      paginationModel,
      sortModel,
      dateFilter,
      statusFilter,
      userFilter,
      props.clientId,
      props.familyId,
    ],
    queryFn: () => {
      const data: any = Object.assign({
        pageNum: paginationModel.page,
        pageSize: paginationModel.pageSize,
        sortModel,
        start: moment(dateFilter[0]).utc(),
        end: moment(dateFilter[1]).utc(),
        status: statusFilter,
        userId: userFilter,
        clientId: props.clientId,
        familyId: props.familyId,
      });
      return TimeTrackingApi.getEntryList(context.authToken, data);
    },
    enabled: context.authToken !== undefined,
  });

  useEffect(() => {
    if (!isLoading) {
      if (error) {
        console.error(error);
      } else if (data && data.data) {
        setRows(data.data.collection);
        setRowCount(data.data.rowCount);
        setOverallDuration(data.data.overallDuration);
        setCurrentTimeTrackingEntry(data.data.startedEntry);
        setTimeTrackingCategories(data.data.categories);
        setClientFamilyList(data.data.clientFamilyList);
        setUserList(data.data.userList);
      }
    }
  }, [data, error, isLoading]);

  const tableColumns: Array<GridColDef> = [
    {
      field: 'date',
      headerName: context.i18n.timeTrackingDate,
      flex: 1,
      hideable: false,
      renderCell: (params) => {
        const row = params.row;
        const date = moment.utc(row?.start);
        if (!date.isValid()) return null;

        // If it's a multi-day entry, show start and end dates
        if (row.isMultiDay) {
          return (
            <Box sx={{ lineHeight: 1.2 }}>
              <Typography variant="body2" fontWeight="bold" sx={{ mb: 0.5 }}>
                {date.format('DD.MM.YYYY')}
                {' - '}
                {row.endDate || moment.utc(row?.end).format('DD.MM.YYYY')}
              </Typography>
              <Typography
                variant="caption"
                color="text.secondary"
                display="block"
              >
                {row.daysSpanned
                  ? `${row.daysSpanned} ${context.i18n.timeTrackingDays}`
                  : ''}
              </Typography>
            </Box>
          );
        }

        // Regular single-day entry
        return date.format('DD.MM.YYYY');
      },
    },
    {
      field: 'startEnd',
      headerName: context.i18n.timeTrackingStartEnd,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const row = params.row;
        const start = moment(row.start);
        const end = moment(row.end);

        const timeText =
          (start.isValid() ? start.format('HH:mm:ss') : '') +
          ' - ' +
          (end.isValid() ? end.format('HH:mm:ss') : '');

        // If it's a multi-day entry, show a warning icon with the time
        if (row.isMultiDay) {
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span>{timeText}</span>
              <Tooltip
                title={
                  context.i18n.timeTrackingMultiDayWarning ||
                  'This entry spans multiple days'
                }
              >
                <Box
                  component="span"
                  sx={{
                    ml: 1,
                    color: theme.palette.warning.main,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <FontAwesomeIcon icon={faExclamationTriangle} size="sm" />
                </Box>
              </Tooltip>
            </Box>
          );
        }

        return timeText;
      },
    },
    {
      field: 'duration',
      headerName: context.i18n.timeTrackingDuration,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const row = params.row;
        const value = row.duration;

        // If no duration, return empty
        if (!value || value === 0) return '';

        // Use server-provided formatted duration for multi-day entries if available
        if (row.isMultiDay && row.durationFormatted) {
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                fontWeight="bold"
                color="primary.main"
                variant="body2"
              >
                {row.durationFormatted.replace(',', 'h ')}m
              </Typography>
            </Box>
          );
        }

        // Fallback to client-side formatting
        return parseDurationNumber(value, true);
      },
    },
    {
      field: 'category',
      headerName: context.i18n.timeTrackingClientCategory,
      flex: 1,
      valueFormatter: (value: any) => {
        return value.name;
      },
    },
    {
      field: 'client_family',
      headerName: context.i18n.timeTrackingClientFamily,
      flex: 1,
      valueGetter: (value, row) => {
        if (row.family) return row.family.name;
        else if (row.client) return row.client.name;
        return '';
      },
    },
    {
      field: 'status',
      headerName: context.i18n.timeTrackingStatus,
      flex: 1,
      valueFormatter: (value: any) => {
        return context.i18n['timeTrackingStatus_' + value];
      },
    },
    {
      field: 'note',
      headerName: context.i18n.timeTrackingNote,
      flex: 1,
    },
    {
      field: context.i18n.actions,
      headerName: '',
      flex: 1,
      sortable: false,
      renderHeader: () => (
        <Box>
          <Tooltip title={context.i18n.timeTrackingEntryCreate || 'Add Entry'}>
            <IconButton
              disableRipple
              color="primary"
              onClick={() => setCreateDialogOpen(true)}
            >
              <FontAwesomeIcon icon={faPlus} />
            </IconButton>
          </Tooltip>
          {context.isAdmin && (
            <Tooltip title={context.i18n.timeTrackingEntriesStatus}>
              <IconButton
                disabled={selectedTimeTrackingEntries.length < 1}
                disableRipple
                color="primary"
                onClick={() => setStatusDialogOpen(true)}
              >
                <FontAwesomeIcon icon={faArrowsToDot} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
      renderCell: (params) => {
        return (
          <Box>
            {params.row.editPermission && (
              <>
                <Tooltip title={context.i18n.timeTrackingCategoryEdit}>
                  <IconButton
                    disableRipple
                    color="primary"
                    onClick={() => {
                      setEditDialogOpen(true);
                      setSelectedTimeTrackingEntry(params.row);
                    }}
                  >
                    <FontAwesomeIcon icon={faPenToSquare} />
                  </IconButton>
                </Tooltip>
                {params.row.category.method ==
                  TimeTrackingCategoryMethod.PROTOCOL && (
                  <Tooltip
                    title={context.i18n.timeTrackingCategoryProtocolEdit}
                  >
                    <IconButton
                      disableRipple
                      color="primary"
                      onClick={() => {
                        setSelectedTimeTrackingEntry(params.row);
                        setEditProtocolDialogOpen(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faFilePen} />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
            {params.row.deletePermission && (
              <Tooltip title={context.i18n.timeTrackingCategoryDelete}>
                <IconButton
                  disableRipple
                  sx={{ height: 39 }}
                  color="error"
                  onClick={() => {
                    setSelectedTimeTrackingEntry(params.row);
                    setDeleteDialogOpen(true);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        );
      },
    },
  ];

  return (
    <>
      {/* First part - Toolbar rendered manually */}
      <TimeTrackingListToolbar
        showSubTimer={true}
        filterDateRange={setDateFilter}
        filterUser={userFilter ? setUserFilter : undefined}
        clientFamilyList={clientFamilyList}
        currentTimeTrackingEntry={currentTimeTrackingEntry}
        timeTrackingCategories={timeTrackingCategories}
        userList={userFilter ? userList : undefined}
        clientId={props.clientId}
        familyId={props.familyId}
        selectedTimeTrackingEntryIds={selectedTimeTrackingEntries.map(
          (e) => e.id,
        )}
        clearSelection={(forceRefetch: boolean) => {
          setSelectedTimeTrackingEntries([]);
          if (forceRefetch) refetch();
        }}
        onTimerStart={(timeTrackingEntry: TimeTrackingEntry) => {
          refetch();
          setCurrentTimeTrackingEntry(timeTrackingEntry);
        }}
        onTimerStop={(timeTrackingEntry: TimeTrackingEntry) => {
          if (
            timeTrackingEntry.category?.method ===
            TimeTrackingCategoryMethod.PROTOCOL
          ) {
            setSelectedTimeTrackingEntry(timeTrackingEntry);
            setEditProtocolDialogOpen(true);
          }
          refetch();
        }}
      />

      {/* Table with loading overlay */}
      <Box position="relative" sx={{ px: 1 }}>
        {isLoading && (
          <Box
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
            top={0}
            left={0}
            right={0}
            bottom={0}
            bgcolor="rgba(255, 255, 255, 0.7)"
            zIndex={10}
          >
            <CircularProgress />
          </Box>
        )}

        {rows.length > 0 ? (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<FontAwesomeIcon icon={faPlus} />}
                onClick={() => setCreateDialogOpen(true)}
              >
                {context.i18n.timeTrackingEntryCreate || 'Add Entry'}
              </Button>
            </Box>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        indeterminate={
                          selectedTimeTrackingEntries.length > 0 &&
                          selectedTimeTrackingEntries.length <
                            rows.filter((e) => e.id).length
                        }
                        checked={
                          rows.length > 0 &&
                          selectedTimeTrackingEntries.length ===
                            rows.filter((e) => e.id).length
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            const selectableRowIds = rows
                              .filter(
                                (row) =>
                                  row.editPermission &&
                                  (context.isAdmin ||
                                    row.status === TimeTrackingStatus.OPEN),
                              )
                              .map((row) => row.id);
                            setSelectedTimeTrackingEntries(
                              rows.filter((row) =>
                                selectableRowIds.includes(row.id),
                              ),
                            );
                          } else {
                            setSelectedTimeTrackingEntries([]);
                          }
                        }}
                      />
                    </TableCell>
                    {tableColumns.map((column) => (
                      <TableCell key={column.field}>
                        {column.headerName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{
                        backgroundColor:
                          row.status === TimeTrackingStatus.REJECTED
                            ? '#FFF8F8' // Using consistent light pink for REJECTED status across the app
                            : row.status === TimeTrackingStatus.SUBMITTED
                            ? theme.palette.grey[400]
                            : row.status === TimeTrackingStatus.COMPLETED
                            ? theme.palette.success.light
                            : '#ffffff',
                        opacity:
                          row.status === TimeTrackingStatus.SUBMITTED ||
                          row.status === TimeTrackingStatus.COMPLETED
                            ? 0.5
                            : 1,
                        height: row.isMultiDay ? '76px' : '53px', // Set explicit row height
                        '&:hover': {
                          backgroundColor:
                            row.status === TimeTrackingStatus.REJECTED
                              ? '#FFEEF0' // Slightly darker on hover for rejected rows
                              : row.status === TimeTrackingStatus.SUBMITTED
                              ? theme.palette.grey[400]
                              : row.status === TimeTrackingStatus.COMPLETED
                              ? theme.palette.success.light
                              : theme.palette.action.hover,
                        },
                      }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedTimeTrackingEntries.some(
                            (entry) => entry.id === row.id,
                          )}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedTimeTrackingEntries([
                                ...selectedTimeTrackingEntries,
                                row,
                              ]);
                            } else {
                              setSelectedTimeTrackingEntries(
                                selectedTimeTrackingEntries.filter(
                                  (entry) => entry.id !== row.id,
                                ),
                              );
                            }
                          }}
                          disabled={
                            !(
                              row.editPermission &&
                              (context.isAdmin ||
                                row.status === TimeTrackingStatus.OPEN)
                            )
                          }
                        />
                      </TableCell>

                      {/* Date column */}
                      <TableCell>
                        {row.isMultiDay ? (
                          <Box sx={{ lineHeight: 1.2 }}>
                            <Typography
                              variant="body2"
                              fontWeight="bold"
                              sx={{ mb: 0.5 }}
                            >
                              {moment.utc(row.start).format('DD.MM.YYYY')}
                              {' - '}
                              {row.endDate ||
                                moment.utc(row.end).format('DD.MM.YYYY')}
                            </Typography>
                            <Typography
                              variant="caption"
                              color="text.secondary"
                              display="block"
                            >
                              {row.daysSpanned
                                ? `${row.daysSpanned} ${context.i18n.timeTrackingDays}`
                                : ''}
                            </Typography>
                          </Box>
                        ) : (
                          moment.utc(row.start).format('DD.MM.YYYY')
                        )}
                      </TableCell>

                      {/* Start-End column */}
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <span>
                            {moment(row.start).format('HH:mm:ss')} -{' '}
                            {row.end ? moment(row.end).format('HH:mm:ss') : ''}
                          </span>
                          {row.isMultiDay && (
                            <Tooltip
                              title={
                                context.i18n.timeTrackingMultiDayWarning ||
                                'This entry spans multiple days'
                              }
                            >
                              <Box
                                component="span"
                                sx={{
                                  ml: 1,
                                  color: theme.palette.warning.main,
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faExclamationTriangle}
                                  size="sm"
                                />
                              </Box>
                            </Tooltip>
                          )}
                        </Box>
                      </TableCell>

                      {/* Duration column */}
                      <TableCell>
                        {row.isMultiDay && row.durationFormatted ? (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography
                              fontWeight="bold"
                              color="primary.main"
                              variant="body2"
                            >
                              {row.durationFormatted.replace(',', 'h ')}m
                            </Typography>
                          </Box>
                        ) : row.duration && row.duration > 0 ? (
                          parseDurationNumber(row.duration, true)
                        ) : (
                          ''
                        )}
                      </TableCell>

                      {/* Category column */}
                      <TableCell>{row.category?.name}</TableCell>

                      {/* Client/Family column */}
                      <TableCell>
                        {row.family?.name || row.client?.name || ''}
                      </TableCell>

                      {/* Status column */}
                      <TableCell>
                        <Box>
                          <Typography
                            variant="body2"
                            fontWeight="normal"
                            color="text.primary"
                          >
                            {context.i18n[`timeTrackingStatus_${row.status}`] ||
                              row.status}
                          </Typography>

                          {/* Show rejection reason for admins (unless COMPLETED) or when REJECTED for everyone */}
                          {((context.isAdmin &&
                            row.rejectionReason &&
                            row.status !== 'COMPLETED') ||
                            (row.status === 'REJECTED' &&
                              row.rejectionReason)) && (
                            <Box
                              sx={{
                                p: 2,
                                mt: 0.5,
                                border: '1px solid',
                                borderColor: 'error.main',
                                borderRadius: 1,
                                bgcolor: '#FFF8F8',
                                maxWidth: '250px',
                                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                              }}
                              aria-label="Rejection reason"
                            >
                              <Typography
                                variant="body1"
                                fontWeight="bold"
                                color="error.dark"
                                sx={{ display: 'block', mb: 0.75 }}
                              >
                                {context.i18n['rejection-reason-label'] ||
                                  'Grund der Ablehnung:'}
                              </Typography>
                              <Typography
                                variant="body1"
                                color="text.primary"
                                sx={{
                                  display: 'block',
                                  wordWrap: 'break-word',
                                  fontWeight: 'medium',
                                }}
                              >
                                {row.rejectionReason}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </TableCell>

                      {/* Note column */}
                      <TableCell>{row.note || ''}</TableCell>

                      {/* Actions column */}
                      <TableCell>
                        <Box>
                          {row.editPermission && (
                            <>
                              <Tooltip
                                title={context.i18n.timeTrackingCategoryEdit}
                              >
                                <IconButton
                                  disableRipple
                                  color="primary"
                                  onClick={() => {
                                    setEditDialogOpen(true);
                                    setSelectedTimeTrackingEntry(row);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faPenToSquare} />
                                </IconButton>
                              </Tooltip>
                              {row.category.method ===
                                TimeTrackingCategoryMethod.PROTOCOL && (
                                <Tooltip
                                  title={
                                    context.i18n
                                      .timeTrackingCategoryProtocolEdit
                                  }
                                >
                                  <IconButton
                                    disableRipple
                                    color="primary"
                                    onClick={() => {
                                      setSelectedTimeTrackingEntry(row);
                                      setEditProtocolDialogOpen(true);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faFilePen} />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </>
                          )}
                          {row.deletePermission && (
                            <Tooltip
                              title={context.i18n.timeTrackingCategoryDelete}
                            >
                              <IconButton
                                disableRipple
                                color="error"
                                onClick={() => {
                                  setSelectedTimeTrackingEntry(row);
                                  setDeleteDialogOpen(true);
                                }}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Footer with pagination and total duration */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mt: 2,
              }}
            >
              <Box>
                <Select
                  value={paginationModel.pageSize}
                  onChange={(e) =>
                    setPaginationModel({
                      ...paginationModel,
                      pageSize: Number(e.target.value),
                    })
                  }
                >
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                  <MenuItem value={-1}>
                    {context.i18n.timeTrackingListAll}
                  </MenuItem>
                </Select>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {rowCount > paginationModel.pageSize && (
                  <Box sx={{ mx: 2 }}>
                    <IconButton
                      disabled={paginationModel.page === 0}
                      onClick={() =>
                        setPaginationModel({
                          ...paginationModel,
                          page: paginationModel.page - 1,
                        })
                      }
                    >
                      <ChevronLeftIcon />
                    </IconButton>
                    <span>
                      {paginationModel.page + 1} /{' '}
                      {Math.ceil(rowCount / paginationModel.pageSize)}
                    </span>
                    <IconButton
                      disabled={
                        paginationModel.page >=
                        Math.ceil(rowCount / paginationModel.pageSize) - 1
                      }
                      onClick={() =>
                        setPaginationModel({
                          ...paginationModel,
                          page: paginationModel.page + 1,
                        })
                      }
                    >
                      <ChevronRightIcon />
                    </IconButton>
                  </Box>
                )}

                <TimeTrackingListFooter duration={overallDuration} />
              </Box>
            </Box>
          </>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="200px"
            sx={{ backgroundColor: 'background.paper', borderRadius: 1 }}
          >
            <Typography variant="body1" color="text.secondary">
              {context.i18n.noData ||
                'No time entries found for the selected period'}
            </Typography>
          </Box>
        )}
      </Box>
      <TimeTrackingEntryDialog
        open={createDialogOpen}
        timeTrackingCategories={timeTrackingCategories}
        clientFamilyList={clientFamilyList}
        userId={userFilter}
        onClose={() => {
          setCreateDialogOpen(false);
          refetch();
        }}
        onProtocolEdit={(entry) => {
          // Set the selected entry and open the protocol dialog
          setSelectedTimeTrackingEntry(entry);
          setEditProtocolDialogOpen(true);
        }}
      />
      <TimeTrackingEntryDialog
        open={editDialogOpen}
        timeTrackingCategories={timeTrackingCategories}
        clientFamilyList={clientFamilyList}
        timeTrackingEntry={selectedTimeTrackingEntry}
        onClose={() => {
          setEditDialogOpen(false);
          setSelectedTimeTrackingEntry(undefined);
          refetch();
        }}
      />
      <DeleteTimeTrackingEntryDialog
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
          setSelectedTimeTrackingEntry(undefined);
          refetch();
        }}
        timeTrackingEntry={selectedTimeTrackingEntry}
      />
      <StatusTimeTrackingEntriesDialog
        open={statusDialogOpen}
        onClose={() => {
          setStatusDialogOpen(false);
          setSelectedTimeTrackingEntry(undefined);
          refetch();
        }}
        timeTrackingEntries={selectedTimeTrackingEntries}
      />
      <EditProtocolDialog
        clientId={selectedTimeTrackingEntry?.clientId}
        familyId={selectedTimeTrackingEntry?.familyId}
        open={editProtocolDialogOpen}
        protocol={selectedTimeTrackingEntry?.protocol}
        onClose={() => {
          setEditProtocolDialogOpen(false);
          refetch();
        }}
        showReleaseButton
        onLinkProtocol={async (protocolId) => {
          try {
            if (selectedTimeTrackingEntry?.id) {
              // Update the time tracking entry to link it with the selected protocol
              await TimeTrackingApi.editEntry(
                context.authToken,
                selectedTimeTrackingEntry.id,
                { protocolId },
              );

              // Show success message
              snackbarUtils.success(
                context.i18n.protocolLinkedSuccess ||
                  'Protocol linked successfully',
              );

              // Refetch data
              refetch();
            }
          } catch (error) {
            console.error('Error linking protocol:', error);
            snackbarUtils.error(
              context.i18n.errorTryAgainLater ||
                'An error occurred. Please try again later.',
            );
          }
        }}
        startDate={(() => {
          const start = moment(selectedTimeTrackingEntry?.start).startOf(
            'minute',
          );
          // Round to nearest 15 minutes
          const startMinute = start.minute();
          const remainderStart = startMinute % 15;
          if (remainderStart > 0) {
            // If closer to next interval, round up, otherwise round down
            if (remainderStart >= 8) {
              start.add(15 - remainderStart, 'minutes');
            } else {
              start.subtract(remainderStart, 'minutes');
            }
          }
          return start;
        })()}
        endDate={(() => {
          const end = moment(selectedTimeTrackingEntry?.end).startOf('minute');
          // Round to nearest 15 minutes
          const endMinute = end.minute();
          const remainderEnd = endMinute % 15;
          if (remainderEnd > 0) {
            // If closer to next interval, round up, otherwise round down
            if (remainderEnd >= 8) {
              end.add(15 - remainderEnd, 'minutes');
            } else {
              end.subtract(remainderEnd, 'minutes');
            }
          }

          // Calculate duration and ensure minimum of 15 minutes
          const start = moment(selectedTimeTrackingEntry?.start).startOf(
            'minute',
          );
          const startMinute = start.minute();
          const remainderStart = startMinute % 15;
          if (remainderStart > 0) {
            if (remainderStart >= 8) {
              start.add(15 - remainderStart, 'minutes');
            } else {
              start.subtract(remainderStart, 'minutes');
            }
          }

          const durationMinutes = end.diff(start, 'minutes');
          if (durationMinutes < 15) {
            end.add(15 - durationMinutes, 'minutes');
          }

          return end;
        })()}
        timeTrackingId={selectedTimeTrackingEntry?.id}
        // submitOnSaveEnabled={!billing}
        // onRelease={() => {
        //   if (billing && protocol) {
        //     onReleaseProtocol(protocol.releaseProtocolId);
        //   }
        // }}
        // adminView={billing || context.user?.role !== 'USER'}
        // released={released}
      />
    </>
  );
};

export default TimeTrackingList;
