import { FC, useContext, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { AppContext } from '../hooks/context';

/**
 * Route component that only allows access to CaseCare staff members
 * This is distinct from organization admins or superadmins
 */
const CaseCareAdminRoute: FC = () => {
  const context = useContext(AppContext);
  const location = useLocation();

  // If the user navigates to a non-admin route, redirect them to the admin dashboard
  useEffect(() => {
    // Check if the user is authenticated and is a CaseCare staff member
    if (context.isAuthenticated && context.isCaseCareStaff) {
      // If they try to access a route that isn't under /casecare-admin, redirect them
      if (!location.pathname.startsWith('/casecare-admin')) {
        window.location.href = '/casecare-admin/organization-features';
      }
    }
  }, [location.pathname, context.isAuthenticated, context.isCaseCareStaff]);

  // Only allow CaseCare staff to access these routes
  return context.isCaseCareStaff ? <Outlet /> : <Navigate to="/" />;
};

export default CaseCareAdminRoute;
