import moment, { Moment } from 'moment';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../hooks/context';

interface CountUpTimerProps {
  startTime: number | undefined;
  interval?: number;
}

const CountUpTimer = (props: CountUpTimerProps): JSX.Element => {
  const [diff, setDiff] = useState(0);
  const context = useContext(AppContext);

  const timer = () => {
    let timeDiff = 0;
    if (props.startTime) {
      timeDiff = moment().unix() - props.startTime;
    }
    setDiff(timeDiff);
  };

  useEffect(() => {
    timer();
    const intervalId = setInterval(() => {
      timer();
    }, props.interval ?? 1000);

    //remove interval after component dismiss
    return () => clearInterval(intervalId);
  }, [props.startTime]);

  const duration = moment.duration(props.startTime ? diff : 0, 's');
  const days = Math.floor(duration.asDays());
  const timeString = moment.utc(duration.asMilliseconds()).format('HH:mm:ss');

  return (
    <>
      {days > 1 ? `${days} ${context.i18n.days} ` : ''}
      {timeString}
    </>
  );
};

export default CountUpTimer;
